/* eslint no-param-reassign: 0 */
/* eslint no-unneeded-ternary: 0 */

import APP_CONFIG from '../../appConfig';
import Cache from '../../assets/js/utils/Cache';

const mutations = {
  SET_SFW_MODE(state, paranoiaLevel) {
    state.sfwMode = paranoiaLevel;
  },

  SET_MOVIE_LIST_FILTER_VISIBILITY(state, bool) {
    state.movieListFilterVisible = bool;
  },

  SET_MENU_ALWAYS_VISIBLE(state, bool) {
    state.menuAlwaysVisible = bool;
  },

  SET_PLAYED_SAMPLES(state, payload) {
    // only populate this if the user is not logged in
    if (!state.isLoggedIn) {
      const cache = new Cache('videos');

      // save time of first sample play (for clearing limit, if needed)
      cache.get('sampleStart').then((tData) => {
        const startTime = (tData) ? tData : null;
        if (!startTime) {
          cache.set('sampleStart', Date.now());
        } else if (Date.now() - startTime > APP_CONFIG.site.samplePlayback.expiration) {
          cache.clear();
        }

        // movie ids of samples played
        cache.get('samples').then((sData) => {
          const movies = (sData) || [];
          if (payload && !movies.includes(payload.MovieID)) movies.push(payload.MovieID);
          cache.set('samples', movies);
          state.playedSamples = movies;
        });
      });
    }
  },

  RESET_PLAYED_SAMPLES(state) {
    const cache = new Cache('videos');
    cache.set('samples', []);
    state.playedSamples = [];
  },
};

export default mutations;
