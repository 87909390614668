<i18n>
{
  "en": {},
  "ja": {}
}
</i18n>

<template>
  <notifications
    group="vrack-ntf"
    position="top right"
    width="330"
    style="z-index: 10000; margin-top: 64px; margin-right: 10px;"
    @destroy="(item) => notificationClosed(item)">
    <template slot="body" slot-scope="props">
      <div class="vrack-ntf-template">
        <div :class="props.item.data ? 'inner-right' : ''">
          <div class="action" @click="props.close">
            <svg class="tm-icon"><use xlink:href="#tm-icon-clear"/></svg>
          </div>
          <div class="content">
            <div class="title">{{ props.item.title }}</div>
            <div v-html="props.item.text"></div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
import EventBus from '@/assets/js/utils/EventBus';

export default {
  mounted() {
    // Generic notification message, strings are passed instead of defining i18n strings in this component
    EventBus.$on('notification:event:vrack', (data) => {
      this.$notify({
        group: 'vrack-ntf',
        title: (Object.prototype.hasOwnProperty.call(data, 'title')) ? data.title : null,
        text: (Object.prototype.hasOwnProperty.call(data, 'text')) ? data.text : null,
        duration: (Object.prototype.hasOwnProperty.call(data, 'duration') ? data.duration : 5000),
        data: (Object.prototype.hasOwnProperty.call(data, 'data')) ? data.data : {},
      });
    });
  },

  methods: {
    notificationClosed(props) {
      console.log('vrack notification closed', props);
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/core/_variables';
.vrack-ntf-template {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  &, & > div {
    box-sizing: border-box;
  }
  background: #ececec;
  border-left: 5px solid $color-vrack;

  .inner-left {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    .circle {
      float: left;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 1px 0 0 6px;
    }
  }

  .inner-right {
    width: 100%;
  }

  .action {
    float: right;
    padding: 8px;
    cursor: pointer;
    opacity: 0.2;
    &:hover {
      opacity: 0.7;
    }
  }
  .content {
    padding: 10px;
    .title {
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
