/* eslint no-shadow: 0 */

import Cookies from 'js-cookie';
import actions from './actions';
import mutations from './mutations';

const state = {
  s2sID: Cookies.get('s2s_id'),
  UTMSource: Cookies.get('s2s_utm_source'),
  UTMMedium: Cookies.get('s2s_utm_medium'),
  affiPromo: null,
};

const getters = {
  s2sID: state => state.s2sID,
  UTMSource: state => state.UTMSource,
  UTMMedium: state => state.UTMMedium,
  affiPromo: state => state.affiPromo,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
