const actions = {
  setEventsInit(context, bool) {
    context.commit('SET_EVENTS_INIT', bool);
  },

  setVolume(context, volume) {
    context.commit('SET_VOLUME', volume);
  },
};

export default actions;
