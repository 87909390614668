/**
 * @module js/utils/VRack
 * @description Helper funtions for VRack's JS API
 */

/* eslint max-len: 0 */
/* eslint no-loop-func: 0 */
/* eslint no-shadow: 0 */

import APP_CONFIG from '@/appConfig';
import store from '@/store';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';
import Cache from '@/assets/js/utils/Cache';
import Deferred from '@/assets/js/utils/Deferred';
import EventBus from '@/assets/js/utils/EventBus';
import VRack from '@/assets/js/services/VRack';

const CONSOLE_COLOR = APP_CONFIG.console.log.vrack;
const MOVIE_ID_PREFIX = APP_CONFIG.site.shortName;
const MAX_MOVIES_CACHED = 10; // save 10 moves max

const bfAPI = new BifrostAPI();
const cache = new Cache('vrack');

const addToCache = (key, movieDetails) => {
  // manage max size of cache and delete oldest movie cached
  cache.keys().then((keys) => {
    const movieKeys = keys.filter(item => item.startsWith(MOVIE_ID_PREFIX));

    // build array of movie data objects
    const movies = [];
    let oldestCachedMovie = null;

    // get first movie cached data (for comparing below)
    if (movieKeys.length > 0) {
      cache.get(movieKeys[0]).then((movieData) => {
        oldestCachedMovie = movieData;
      });
    }

    // walk thru cached movies and find the oldest one, if we're over the limit remove it from cache
    for (let j = 0; j < movieKeys.length; j += 1) {
      ((j) => {
        cache.get(movieKeys[j]).then((movieData) => {
          movies.push(movieData);
          if (movies[j].created < oldestCachedMovie.created) oldestCachedMovie = movies[j];
          if (movies.length > MAX_MOVIES_CACHED - 1) cache.remove(oldestCachedMovie.key);
        });
      })(j);
    }
  });

  // now save to cache
  cache.set(`${MOVIE_ID_PREFIX}_${key}`, {
    key: `${MOVIE_ID_PREFIX}_${key}`, // saving this keyval so we can delete the cache entry
    movieDateId: movieDetails.MovieID,
    actresses: movieDetails.Actor,
    title: movieDetails.Title,
    created: Date.now(),
  });
};

/**
 * @function
 * @name addMovie
 * @description
 * @returns {object} status of movie add and error message if status is not 'ok'
 */
const addMovie = (reupMovieId, movieDetails, token) => {
  const defer = new Deferred();
  const VRackSvc = new VRack();

  console.log('%ccalling addMovie API', CONSOLE_COLOR, reupMovieId, token);

  // save movie data to localforage cache keyed by reup movie id (MetaMovieID)
  addToCache(reupMovieId, movieDetails);

  // make request to JS API (no return value; emits data asyncronously)
  VRackSvc.addMovie(reupMovieId, token);

  // wait for event emitter
  EventBus.$on('vrack:event:movie-add', (data) => {
    if (data && data.status === 'ok') {
      defer.resolve({
        status: data.status,
        reason: data.reason,
      });

      // send to analytics
      if (data.ids.length > 0) {
        cache.get(data.ids[0]).then((movieData) => {
          if (movieData) {
            Analytics.sendCustomEvent('v-rack', {
              action: 'Download',
              vrack_movie_id: data.ids[0],
              movie_id: movieData.movieDateId,
              actress_name: movieData.actresses,
              movie_title: movieData.title,
            });
          }
        });
      }
    } else if (data && data.status !== 'ok') {
      console.log('%cmovie-add error', CONSOLE_COLOR, data);
      defer.reject({
        status: data.status,
        reason: data.reason,
      });
      Analytics.sendCustomEvent('v-rack', {
        action: 'Download Error',
        action_location: window.location.href,
      });
    } else {
      // this shouldn't happen D:
      console.log('%cmovie-add yikes', CONSOLE_COLOR);
      defer.reject({
        status: data.status,
        reason: data.reason,
      });
      Analytics.sendCustomEvent('v-rack', {
        action: 'Download Error',
        action_location: window.location.href,
      });
    }
    // dirty cache, we have new limits now
    store.dispatch('vrack/setDirtyCache', true);

    // remove movie data from localforage
    if (data.id) cache.remove(data.id);

    // stop listening to prevent triggers from buttons from other movie pages
    EventBus.$off('vrack:event:movie-add');
  });

  return defer.promise;
};

/**
 * @function
 * @name getLimitsInfo
 * @description
 * @returns {number} number of available downloads
 */
const getLimitsInfo = (ignoreCache = false) => {
  const defer = new Deferred();

  if (!store.getters['vrack/dirtyCache'] && !ignoreCache) {
    // cache is clean, return value from store
    const leftCount = store.getters['vrack/leftCount'];
    const maxCount = store.getters['vrack/maxCount'];
    defer.resolve({ leftCount, maxCount });

    console.log('%cgetLimitsInfo cached', CONSOLE_COLOR, { leftCount, maxCount });
  } else {
    // cache is dirty, or we are ignoring cache call VRack API
    console.log('%cgetLimitsInfo dirty cache, calling API', CONSOLE_COLOR);

    bfAPI.getVRackTokenCount().then((response) => {
      defer.resolve({
        leftCount: response.LeftCount,
        maxCount: response.MaxCount,
      });
      // set store and undirty cache
      store.dispatch('vrack/setLeftCount', response.LeftCount);
      store.dispatch('vrack/setMaxCount', response.MaxCount);
      store.dispatch('vrack/setDirtyCache', false);
    });
  }

  return defer.promise;
};

/**
 * @function
 * @name getMovieInfo
 * @description
 * @returns {object} active movie info (to see if movie has been downloaded already)
 */
const getMovieInfo = (reupMovieId) => {
  const defer = new Deferred();
  const VRackSvc = new VRack();

  console.log('%ccalling getMovieInfo API', CONSOLE_COLOR, reupMovieId);

  // make request to JS API (no return value; emits data asyncronously)
  VRackSvc.getMovieInfo(reupMovieId);

  // wait for event emitter
  EventBus.$on('vrack:event:movie-active-info', (data) => {
    if (data && data.status === 'ok') {
      const movieId = (data.id) ? parseInt(data.id.split('_')[1], 10) : null;
      // only listen for event where the movie id response matches up with the requested movie id
      if (movieId === reupMovieId) {
        defer.resolve({
          movieId,
          hasLoaded: data.hasLoaded,
          status: data.status,
          reason: data.reason,
        });
      }
    } else {
      console.log('%cmovie-active-info error', CONSOLE_COLOR, data);
      defer.reject({
        status: data.status,
        reason: data.reason,
      });
      Analytics.sendCustomEvent('v-rack', {
        action: 'Movie Active Info Error',
        action_location: window.location.href,
      });
    }

    // stop listening to prevent triggers from buttons from other movie pages
    EventBus.$off('vrack:event:movie-active-info');
  });

  return defer.promise;
};

/**
 * @function
 * @name showShelf
 * @description
 * @returns
 */
const showShelf = ({ movieId, referrer }) => {
  const id = (movieId && movieId !== '') ? `${MOVIE_ID_PREFIX}_${movieId}` : null;
  const ref = (referrer && referrer !== '') ? referrer : null;
  console.log('%cshowShelf', CONSOLE_COLOR, id, ref);
  const VRackSvc = new VRack();
  VRackSvc.api.show(id, ref);
};

export default {
  addMovie,
  getLimitsInfo,
  getMovieInfo,
  showShelf,
};
