/* eslint no-unused-vars: 0 */
/* eslint no-param-reassign: 0 */

import Vue from 'vue';

const SFW = {
  bind(el, binding, vnode) {
    // check for 'sfw' query param (0: off)
    const SFW_ENABLED = (vnode.context.$store.getters['prefs/sfwMode']);

    // levels of SFW paranoia:
    // 1: blur elements; unblur on mouseover; don't re-blur on mouseleave
    // 2: blur elements; unblur on mouseover; re-blur on mouseleave
    // 3: blur elements; stay blurred on mouseover
    // 4: replace element with kittens; no mouse events
    const SFW_MODE = vnode.context.$store.getters['prefs/sfwMode'];

    // el might not be present for ssr and only do this stuff if SFW is enabled (no need to F with
    // the DOM if it's not needed)
    if (el && SFW_ENABLED) {
      // set options
      const STICKY_UNBLUR = (SFW_MODE === 1);
      const NO_UNBLUR = (SFW_MODE >= 3);
      const SHOW_KITTENS = (SFW_MODE === 4 && el.tagName === 'IMG');

      const blurElement = () => {
        if (SHOW_KITTENS) {
          el.src = `/img/common/sfw/kitten_${Math.floor(Math.random() * 4) + 1}.jpg`;
        } else {
          el.style.filter = 'blur(2px) brightness(2.5) contrast(10)';
        }
      };

      const unblurElement = () => {
        el.style.filter = '';
      };

      blurElement();

      // event listeners
      el.addEventListener('mouseover', () => {
        if (!NO_UNBLUR && !SHOW_KITTENS) unblurElement();
      });
      el.addEventListener('mouseleave', () => {
        if (!STICKY_UNBLUR && !SHOW_KITTENS) blurElement();
      });
    }
  },
};

export { SFW as default };

// make it available globally
Vue.directive('sfw', SFW);
