/* eslint no-console: 0 */
/* eslint no-undef: 0 */

import Vue from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';
import APP_CONFIG from '@/appConfig';
import store from '@/store';
import Deferred from '../../utils/Deferred';
import EventBus from '../../utils/EventBus';
import BifrostAPI from '../Bifrost/API';
import D2PToolbar from '../D2PToolbar';
import VRack from '../VRack';

export default class User {
  constructor() {
    this.name = store.getters['user/name'];
    this.UID = store.getters['user/UID'];
    this.typeID = store.getters['user/typeID'];
    this.typeStr = store.getters['user/typeStr'];
    this.loginData = store.getters['user/loginData'];

    this.billing = store.getters['user/billing'];
    this.upgradeOptions = store.getters['user/upgradeOptions'];
    this.nickname = store.getters['user/nickname'];
    this.vcBalance = store.getters['user/vcBalance'];
    this.vcCoupon = store.getters['user/vcCoupon'];

    this.init();
    this.bfAPI = new BifrostAPI();
    this.sqaEnabled = APP_CONFIG.sqa.enabled;
  }

  init() {
    // check if this service has been previously initialized. if so, then don't do this
    if (store.getters['user/isInitialized']) return;

    console.log('%cInitializing User service', 'background: #222; color: #ff0', this);
    store.dispatch('user/setInit', true);
    store.dispatch('user/setUpgradeOptions');
    store.dispatch('prefs/setPlayedSamples');
  }

  login(username, passwd) {
    // Send a message to login app for logging in; no reply expected -- the
    // backend responds by sending back 2 non-reply messages: one to create
    // the cookie, and the other with user info that we do stuff with in the
    // loginPostflight function
    this.bfAPI.requestLogin(username, passwd);
  }

  loginPostflight(data) {
    // Called by the the Bifrost login helper when login succeeded. At this
    // point, the login helper is creating the login cookie and also calling
    // this function to set the User properties
    store.dispatch('user/setFlags', data);

    if (store.getters['user/typeStr']) {
      store.dispatch('user/setUpgradeOptions');

      // refresh playlist store (they might have upgraded/downgraded limits at this point)
      store.dispatch('playlist/refreshState');

      // Do things on 1st time login -- loginPostflight gets called on User.refresh() but there are
      // some things that we only need to do on 1st time login. These go here.
      if (!this.loginData) {
        this.loginData = store.getters['user/loginData'];

        // UA
        Vue.analytics.injectGlobalDimension(APP_CONFIG.analytics.customDimensions.userType,
          APP_CONFIG.analytics.userTypeMap[store.getters['user/typeStr']].login,
          false);

        // check SQA cookie and call eligibility API if cookie doesn't exist
        const hasCookie = Cookies.get('reupSQA');

        if (!hasCookie && this.sqaEnabled) {
          const UID = store.getters['user/UID'];
          axios.get(`https://api.d2pass.com/v1/users/${UID}`).then((response) => {
            if (response.data !== null && response.data.data.attributes['sqa-status'] === true) {
              // emit event if user is eligible
              EventBus.$emit('SQA:triggered', true);
            }
          }).catch((error) => {
            console.log('%cerror getting from API', 'background: #222; color: #f00', error);
          });
        }

        // get user's default playlists and save their unique playlist IDs to the store
        this.bfAPI.getPlaylists().then((response) => {
          if (response.status === 200) {
            this.playlists = response.data.data;
            for (let i = 0; i < this.playlists.length; i += 1) {
              if (this.playlists[i].type === 'default') {
                store.dispatch('playlist/setDefaultPlaylistIDs', { playlistName: this.playlists[i].name, playlistID: this.playlists[i].playlist_id });
              }
            }
          }
        });

        // reset playedSample movies. just going extra here because members can't play samples with
        // the current UX, so it's possible they logout just to view them, in which case we clear
        // the sampled movies store -- we know the limit doesn't apply to them because their
        // previous state was that they were logged in
        store.dispatch('prefs/resetPlayedSamples');

        // Refresh D2P Toolbar
        const D2PToolbarSvc = new D2PToolbar();
        D2PToolbarSvc.refresh();

        // Refresh VRack module
        const VRackSvc = new VRack();
        VRackSvc.restart();
      }
    }

    // some things need to know if the logged in process has completed. we can provide this by
    // establishing a promise (in the User constructor) and then resolving it here
    store.dispatch('user/resolveLogin');
  }

  logout() {
    // tell backend we're logging out (no-reply msg)
    this.bfAPI.requestLogout();

    // UA
    Vue.analytics.injectGlobalDimension(APP_CONFIG.analytics.customDimensions.userType,
      APP_CONFIG.analytics.userTypeMap[store.getters['user/typeStr']].logout,
      false);

    // Refresh D2P Toolbar
    const D2PToolbarSvc = new D2PToolbar();
    D2PToolbarSvc.refresh();

    // Refresh VRack module
    const VRackSvc = new VRack();
    VRackSvc.restart();
    store.dispatch('vrack/setDirtyCache', true);

    // reset this *after* GA
    store.dispatch('user/resetState');

    // refresh playlist store (they might have upgraded/downgraded limits at this point)
    store.dispatch('playlist/refreshState');

    // reset websocket URL to default (clears url with session attached)
    store.dispatch('vws/setWebsocketUrl', process.env.VUE_APP_WEBSOCKET_URL);

    // remove cookie
    Cookies.remove('NetiA', { domain: APP_CONFIG.site.domain });

    this.init();
  }

  setBilling(billingData) {
    store.dispatch('user/setBilling', billingData);
    this.billing = store.getters['user/billing'];

    // we know their bank user status at this point, so let's get their upgrade options
    store.dispatch('user/setUpgradeOptions');
    this.upgradeOptions = store.getters['user/upgradeOptions'];
  }

  setNickname(nickData) {
    store.dispatch('user/setNickname', nickData);
    this.nickname = store.getters['user/nickname'];
  }

  setVCPoints(vcData) {
    store.dispatch('user/setVCPoints', vcData);
    this.vcBalance = store.getters['user/vcBalance'];
    this.vcCoupon = store.getters['user/vcCoupon'];
  }

  refresh() {
    // Refresh user object. Normally the backend tells us to refresh the
    // user (by re-sending a "logged in" message), but for some cases (D2P
    // nickname creation), we'll have to do this ourselves.
    const defer = new Deferred();

    this.bfAPI.requestUserInfo().then((data) => {
      this.loginPostflight(data);
      defer.resolve(true); // return this so the caller knows it finished
    });

    return defer.promise;
  }
}
