/* eslint no-console: 0 */

import Bifrost from '..';

export default class FEL {
  constructor() {
    this.appInfo = {
      id: 3430,
      name: 'FEL',
    };

    this.bifrostSvc = new Bifrost();

    // handler for receiving non-reply messages sent from the backend for this app
    this.recvFn = (msg) => {
      switch (msg.MsgID) {
        // DLM doesn't send us any non-reply messages, so we just log it if it does
        default: {
          console.log('Unknown DLM message', msg);
        }
      }
    };

    this.bifrostSvc.registerAppListener(this.appInfo, this.recvFn);
  }
}
