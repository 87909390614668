const actions = {
  refreshState(context) {
    // called on login + logout: check user store and enable (or disable) the playlist feature and
    // adjust their limits accordingly
    const userStore = context.rootState.user;

    // enable playlist features for site members only
    if (userStore.isSiteMember) {
      context.commit('ENABLE_PLAYLIST_FEATURE');
      context.commit('ENABLE_CUSTOM_PLAYLISTS');

      // public playlists on dev only
      if (userStore.name.endsWith('@d2ptests.d2p')
        && process.env.VUE_APP_PLAYLIST_ENV === 'development') {
        context.commit('ENABLE_PUBLIC_PLAYLISTS');
      }

      // set limits according to user type (vip, svip, etc)
      context.commit('SET_LIMITS', userStore.typeID);
    } else {
      // non-members: disable everything playlist-releated and reset limits
      context.commit('DISABLE_PLAYLIST_FEATURE');
      context.commit('DISABLE_CUSTOM_PLAYLISTS');
      context.commit('DISABLE_PUBLIC_PLAYLISTS');
      context.commit('RESET_LIMITS');
      context.commit('RESET_DEFAULT_PLAYLIST_IDS');
    }
  },

  setDefaultPlaylistIDs(context, payload) {
    // called from User service post-flight
    context.commit('SET_DEFAULT_PLAYLIST_IDS', payload);
  },
};

export default actions;
