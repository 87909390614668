import Vue from 'vue';
import Vuex from 'vuex';
import badmin from './badmin/store';
import bifrost from './bifrost/store';
import customLists from './customLists/store';
import d2ptoolbar from './d2ptoolbar/store';
import dml from './dml/store';
import playlist from './playlist/store';
import prefs from './prefs/store';
import purchaseCodes from './purchaseCodes/store';
import user from './user/store';
import videoPlayer from './videoPlayer/store';
import vrack from './vrack/store';
import vws from './vws/store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    badmin,
    bifrost,
    customLists,
    d2ptoolbar,
    dml,
    playlist,
    prefs,
    purchaseCodes,
    user,
    videoPlayer,
    vrack,
    vws,
  },
});

export default store;
