/* eslint max-len: 0 */

export default {
  // app config
  app: {
    version: process.env.VUE_APP_VERSION,
  },

  // site config
  site: {
    domain: '1pondo.tv',
    shortName: '1pondo',
    siteId: 2470,
    d2pSiteId: 20000567,
    title: {
      ja: ' | 一本道',
      en: ' | 1pondo.tv - Largest Premium Uncensored Adult Web Site',
    },
    headerHeight: 64,
    engUrls: {
      'r.1pondo.tv': 'https://ren.1pondo.tv/',
      'www.1pondo.tv': 'https://en.1pondo.tv/',
      'stg.1pondo.tv': 'https://stg-en.1pondo.tv/',
      'dev.1pondo.tv': 'https://dev-en.1pondo.tv/',
    },

    // start date of when english thumbnails were available (2017-02-01)
    enThumbsStartEpoch: 1485964800, // feb 1 2017 8am in seconds

    // start date when both uncensored and censored sample videos were encoded (previously only
    // censored sample movies were available)
    uncensoredSampleStartEpoch: 1706745600000, // jan 31 2024 4pm in milliseconds

    // r3828: sample playback limit for guests (not logged in)
    samplePlayback: {
      limit: 7,
      expiration: 2 * 24 * 60 * 60 * 1000, // 2 days in milliseconds
      upsellURLs: {
        ja: 'https://secure.d2pass.com/signup_acc/shooteracc?site_id=20000567',
        en: 'https://secure.d2pass.com/signup_acc/shooteracc?site_id=20000567&lang=en',
      },
    },

    // r3897 hide release dates for movies older than 6 months old
    releaseDateHiddenAge: 180, // days

    // app progressbar
    progressBarOptions: {
      color: '#f92460',
      failedColor: 'black',
      height: '3px',
    },
  },

  // purchase configs
  purchase: {
    // override if defined per hostname
    hostnames: {
      'stg.1pondo.tv': { protocol: 'https', hostname: 'secure.d2pass.com.stg.d2p' },
      'stg-en.1pondo.tv': { protocol: 'https', hostname: 'secure.d2pass.com.stg.d2p' },
    },
  },

  // google analytics constants
  analytics: {
    trackingIDs: {
      default: 'UA-41272988-4',
      hostnames: {
        // regex hostname match: tracking ID
        'dev.1pondo.tv': 'UA-41272988-9',
        'dev.en.1pondo.tv': 'UA-41272988-9',
        'dev-en.1pondo.tv': 'UA-41272988-9',
        'stg.1pondo.tv': 'UA-41272988-9',
        'stg.en.1pondo.tv': 'UA-41272988-9',
        'stg-en.1pondo.tv': 'UA-41272988-9',
        'www.1pondo.tv': 'UA-41272988-4',
        'en.1pondo.tv': 'UA-41272988-4',
        'r.1pondo.tv': 'UA-41272988-4',
        'ren.1pondo.tv': 'UA-41272988-4',
      },
    },
    customDimensions: {
      userType: 1,
      landerType: 2,
      connectionType: 3,
      language: 4,
      userBits: 5,
    },
    userTypeMap: {
      NonMember: { login: 'NonMember', logout: 'NonMember_LO' },
      Streaming: { login: 'Streaming', logout: 'Streaming_LO' },
      StreamingMember: { login: 'StreamingMember', logout: 'StreamingMember_LO' },
      Member: { login: 'Member', logout: 'Member_LO' },
      StreamingVIP: { login: 'StreamingVIP', logout: 'StreamingVIP_LO' },
      VIP: { login: 'VIP', logout: 'VIP_LO' },
      StreamingSVIP: { login: 'StreamingSuperVIP', logout: 'StreamingSuperVIP_LO' },
      SVIP: { login: 'SuperVIP', logout: 'SuperVIP_LO' },
      StreamingAnnual: { login: 'StreamingAnnual', logout: 'StreamingAnnual_LO' },
      AYCS: { login: 'AYCS', logout: 'AYCS_LO' },
      DTI: { login: 'DTI', logout: 'DTI_LO' },
    },
  },

  // banner admin
  badmin: {
    // prod > en prod + dev maps
    // these are now set in the webpack configs (build/webpack*.conf.js), so we don't expose our
    // personal virt names in the webpack bundle for production
    subdomainMap: {
      www: process.env.VUE_APP_BADMIN_MAP.split('||'),
    },
  },

  dml: {
    enabled: JSON.parse(process.env.VUE_APP_DML_ENABLED), // if false, use DDL
  },

  sqa: {
    enabled: JSON.parse(process.env.VUE_APP_SQA_ENABLED),
  },

  // locale
  i18n: {
    defaultLocale: 'ja',
    hostnames: {
      // regex hostname match: language
      'en.1pondo.tv': 'en',
      'dev.en.1pondo.tv': 'en',
      'dev-en.1pondo.tv': 'en',
      'stg.en.1pondo.tv': 'en',
      'stg-en.1pondo.tv': 'en',
    },
  },

  // user config constants
  user: {
    // mapping for integer types to named user types
    typeMap: {
      0: 'NonMember',
      20: 'Streaming', // old streaming plan
      39: 'StreamingMember',
      40: 'Member',
      59: 'StreamingVIP',
      60: 'VIP',
      79: 'StreamingSVIP',
      80: 'SVIP',
      99: 'StreamingAnnual',
      200: 'AYCS',
    },

    propMap: {
      0: {
        type: 'NonMember',
        class: 'NonMember',
        tier: 'NonMember',
      },
      20: {
        type: 'Streaming',
        class: 'Streaming',
        tier: 'Member',
      },
      39: {
        type: 'StreamingMember',
        class: 'Streaming',
        tier: 'Member',
      },
      40: {
        type: 'Member',
        class: 'Download',
        tier: 'Member',
      },
      59: {
        type: 'StreamingVIP',
        class: 'Streaming',
        tier: 'VIP',
      },
      60: {
        type: 'VIP',
        class: 'Download',
        tier: 'VIP',
      },
      79: {
        type: 'StreamingSVIP',
        class: 'Streaming',
        tier: 'SVIP',
      },
      80: {
        type: 'SVIP',
        class: 'Download',
        tier: 'SVIP',
      },
      99: {
        type: 'StreamingAnnual',
        class: 'Streaming',
        tier: 'Annual',
      },
      200: {
        type: 'AYCS',
        class: 'Streaming',
        tier: 'AYCS',
      },
    },

    // get user flag bits (comments copy-pasta'd from backend source)
    flagBits: {
      Recurring: 1, // Recurring user
      Annual: 2, // Annual user, for display only. Permission is DownA
      Expire: 4, // Has expired tag
      DTI: 8, // DTI user
      StreamP: 16, // Partial streaming, all files but "No streaming"
      StreamF: 32, // Full streaming, ignore the "No streaming" flag
      DownN: 64, // Ability to download "Normal" movies
      DownV: 128, // Ability to download "VIP" movies
      DownS: 256, // Ability to download "SVIP" movies
      DownA: 512, // Ability to download "Annual" movies
      Fast: 1024, // User paid for faster downloads
      Limited: 2048, // User reached Daily Download Limit
      SQA: 4096, // User is SQA flagged
    },

    // hack-ish way of determining if a user is a trial user by comparing
    // start and end dates. trial is actually 2 hours long, but we add a fudge
    // factor in case join doesn't set the times exactly or near 2 hours long
    trialHoursThreshold: 24.5,
  },

  // movie download menu
  downloadMenu: {
    // use nginx video delivery? (uses Mercury movie key instead of DL's)
    useMercKey: false,

    // servers
    DML: {
      ja: {
        android: 'dl23',
        highspeed: 'dl21ac',
        default: 'dl21',
      },
      en: {
        android: 'dl23en',
        highspeed: 'dl21acen',
        default: 'dl21en',
      },
    },
    DDL: {
      android: 'dl13',
      highspeed: 'dl11ac',
      default: 'dl12',
    },

    // rewrite rules for filenames
    rewrite: {
      siteStr: '1pon',
      movieIdKey: 'MovieID',
    },

    // set to 'true' if the webserver sends the movie file with content-type "attachment"
    leftClickDownload: true,

    // list of UA browser names that support HTML5 "download attribute" (this config value is only
    // checked if the above config "leftClickDownload" is set to "false"
    html5Download: ['Chrome'],
  },

  // category constants (add to categories we get from backend-created json)
  categories: {
    50000: {
      category_id: 50000,
      name: {
        ja: 'VIP',
        en: 'VIP',
      },
    },
    50001: {
      category_id: 50001,
      name: {
        ja: '超VIP',
        en: 'SuperVIP',
      },
    },
    50002: {
      category_id: 50002,
      name: {
        ja: 'ストリーミング対応',
        en: 'Streaming',
      },
    },
    50003: {
      category_id: 50003,
      name: {
        ja: '年間動画',
        en: 'Annual',
      },
    },
    60000: {
      category_id: 60000,
      name: {
        ja: '1080p',
        en: '1080p',
      },
    },
    60001: {
      category_id: 60001,
      name: {
        ja: '60fps',
        en: '60fps',
      },
    },
    60002: {
      category_id: 60002,
      name: {
        ja: '720p',
        en: '720p',
      },
    },
  },

  // d2p toolbar
  d2pToolbar: {
    // master switch
    enabled: true,

    // script url
    url: process.env.VUE_APP_D2_HEADER_URL,

    // in pixels
    headerHeight: 46,

    // hostnames that will get the toolbar (not english sites)
    hostnames: {
      'r.1pondo.tv': true,
      'www.1pondo.tv': true,
      'stg.1pondo.tv': true,
      'dev.1pondo.tv': true,
    },

    // div id created by D2P Toolbar
    divID: 'd2p-groupheader',

    // iframe id created by D2P Toolbar
    iframeID: 'ga_toolbar',

    // style name. we do a string match for a unique string that we know only belongs to D2P Toolbar
    styleName: 'd2p-groupheader',

    // css can be customized by passing an optional path when initializing the toolbar
    // customCss: '/css/d2p_reset.css'
  },

  vrack: {
    // script url
    url: process.env.VUE_APP_VRACK_LOADER_URL,

    // options (deprecated feb 9 2024)
    options: {
      'data-autorun': 'false',
      'data-theme': 'dark',
      'data-x-neti-domain': 'www.heydouga.com',
    },
    // startup options
    startupOptions: {
      theme: 'dark',
      xNetiDomain: 'www.heydouga.com',
      xNetiPath: '/d2ptb',
    },

    // hostnames that will get the toolbar (not english sites)
    hostnames: {
      'www.1pondo.tv': true,
      'en.1pondo.tv': true,
      'stg.1pondo.tv': true,
      'dev.1pondo.tv': true,
    },

    // div class created by VRack
    className: 'v-app',
  },

  // header
  appHeader: {
    fixed: true,
    elemID: 'page',
    fixedClass: 'page-fixed',
  },

  // webdev stuff: console log color definitions
  console: {
    log: {
      vrack: 'background: #00ade7; color: #fff',
    },
  },
};
