const actions = {
  setInit(context) {
    context.commit('SET_INIT');
  },

  setBanners(context, payload) {
    context.commit('SET_BANNERS', payload);
  },
};

export default actions;
