<i18n>
{
  "en": {
    "vc_points_issued": "",
    "d2_recurring_coupon_available": "",
    "d2_amenity_points_available": ""
  },
  "ja": {
    "vc_points_issued": "【毎日発行】今すぐ使える、DXLIVEの無料チャットポイントが発行されました。<br/><br/><a href=\"https://www.dxlive.com/member/thankyou?utm_source=1pondo.tv&utm_medium=notification&utm_campaign=dxthankyoupoint\" target=\"_blank\">確認はこちら »</a>",
    "d2_recurring_coupon_available": "メンバーシップの継続時にご利用可能な割引クーポンがあります。<br/><br/><a href=\"https://www.d2pass.com/edit/promocode/recurring?NetiFL=1\" target=\"_blank\">詳細をみる »</a>",
    "d2_amenity_points_available": "【継続特典】<br/><a href=\"https://www.dxlive.com/member?utm_source=1pondo.tv&utm_medium=notification&utm_campaign=recurring_amenity_dxpoint\" target=\"_blank\">DXLIVE</a>無料ポイントプレゼント！<br/>発行手続きを行ってご利用頂けます。<br/><a href=\"https://www.d2pass.com/edit/amenity/?NetiFL=1&utm_source=1pondo.tv&utm_medium=notification&utm_campaign=recurring_amenity_dxpoint\" target=\"_blank\">無料ポイントの発行はこちら »"
  }
}
</i18n>

<template>
  <notifications
    group="events"
    position="top right"
    width="330"
    style="z-index: 10000; margin-top: 64px; margin-right: 10px;"
    @destroy="(item) => notificationClosed(item)">
    <template slot="body" slot-scope="props">
      <div class="events-template">
        <div :class="props.item.data? 'inner-left' : ''">
          <div v-if="props.item.data && props.item.data.icon" class="circle" :style="`background: ${props.item.data.iconBackground}`">
            <font-awesome-icon
              :icon="props.item.data.icon"
              :style="props.item.data.iconStyle"
              :transform="props.item.data.iconTransform"
              :spin="props.item.data.iconSpin"
              :pulse="props.item.data.iconPulse" />
          </div>
        </div>
        <div :class="props.item.data? 'inner-right' : ''">
          <div class="action" @click="props.close">
            <svg class="tm-icon"><use xlink:href="#tm-icon-clear"/></svg>
          </div>
          <div class="content">
            <div class="title">{{ props.item.title }}</div>
            <div v-html="props.item.text"></div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint vue/no-parsing-error: 0 */
import Cookies from 'js-cookie';
import EventBus from '@/assets/js/utils/EventBus';
import APP_CONFIG from '@/appConfig';

export default {
  mounted() {
    // VChat coupon issued
    EventBus.$on('notification:event:vchat:apiCouponIssue:success', () => {
      if (this.locale === 'ja') {
        // r3228: only show for ja site
        console.log('%cnotification:event:vchat:apiCouponIssue:success', 'color:#f0f');
        this.$notify({
          group: 'events',
          type: 'vc_points_issued',
          text: this.$t('vc_points_issued'),
          data: {
            icon: ['fas', 'bell'],
            iconBackground: '#ff3c00',
            iconStyle: { color: '#fff' },
            iconTransform: 'right-1', // most other fa icons are centered, but bell needs a nudge
          },
          duration: -1,
        });
      }
    });

    // D2 recurring coupon issued
    EventBus.$on('notification:event:d2:apiRecurringCouponIssue:success', () => {
      console.log('%cnotification:event:d2:apiRecurringCouponIssue:success', 'color:#f0f');
      // check if cookie exists; if not, show notification
      const hasD2RecurringCookie = Cookies.get('noticeD2Recurring');

      if (!hasD2RecurringCookie) {
        this.$notify({
          group: 'events',
          type: 'd2_recurring_coupon_available',
          text: this.$t('d2_recurring_coupon_available'),
          duration: -1,
        });
      }
    });

    // D2 amenity points available
    EventBus.$on('notification:event:d2:apiAmenityAvailable:true', () => {
      console.log('%cnotification:event:d2:apiAmenityAvailable:true', 'color:#f0f');
      // check if cookie exists; if not, show notification
      const hasD2AmenityCookie = Cookies.get('noticeD2Amenity');

      if (!hasD2AmenityCookie) {
        this.$notify({
          group: 'events',
          type: 'd2_amenity_points_available',
          text: this.$t('d2_amenity_points_available'),
          duration: -1,
        });
      }
    });

    // Generic notification message, strings are passed instead of defining i18n strings in this component
    EventBus.$on('notification:event:generic', (data) => {
      this.$notify({
        group: 'events',
        title: (Object.prototype.hasOwnProperty.call(data, 'title')) ? data.title : null,
        text: (Object.prototype.hasOwnProperty.call(data, 'text')) ? data.text : null,
        duration: (Object.prototype.hasOwnProperty.call(data, 'duration') ? data.duration : 5000),
        data: (Object.prototype.hasOwnProperty.call(data, 'data')) ? data.data : {},
      });
    });
  },

  methods: {
    notificationClosed(props) {
      // special case for d2 recurring coupons; set cookie when notification is closed
      if (props.type === 'd2_recurring_coupon_available') {
        Cookies.set('noticeD2Recurring', 1, { expires: 3, domain: APP_CONFIG.site.domain });
      }
      if (props.type === 'd2_amenity_points_available') {
        Cookies.set('noticeD2Amenity', 1, { expires: 3, domain: APP_CONFIG.site.domain });
      }
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
.events-template {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  &, & > div {
    box-sizing: border-box;
  }
  background: #ececec;
  border-left: 5px solid #f92460;

  .inner-left {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    .circle {
      float: left;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 1px 0 0 6px;
    }
  }

  .inner-right {
    width: 100%;
  }

  .action {
    float: right;
    padding: 8px;
    cursor: pointer;
    opacity: 0.2;
    &:hover {
      opacity: 0.7;
    }
  }
  .content {
    padding: 10px;
    .title {
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
