/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const initialState = {
  initialized: false,
  customLists: {},
};

const state = Object.assign({}, initialState);

const getters = {
  isInitialized: state => state.initialized,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
export { initialState };
