/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-continue: 0 */

import 'url-polyfill'; // need for IE11
import orderBy from 'lodash/orderBy';
import APP_CONFIG from '@/appConfig';
import store from '@/store';
import Deferred from '../../utils/Deferred';
import BifrostAPI from '../Bifrost/API';

export default class Badmin {
  constructor(locale, userTypeID) {
    this.locale = locale;
    this.userTypeID = userTypeID;

    // get subdomain
    const hostnameParts = window.location.hostname.split('.');
    hostnameParts.splice(-2, 2);
    this.subdomain = hostnameParts.join('.');

    // get subdomain dev maps
    this.subdomainMaps = (Object.prototype.hasOwnProperty.call(APP_CONFIG, 'badmin') && Object.prototype.hasOwnProperty.call(APP_CONFIG.badmin, 'subdomainMap')) ? APP_CONFIG.badmin.subdomainMap : null;

    this.bfAPI = new BifrostAPI();
    this.init();
  }

  init() {
    // check if this service has been previously initialized. if so, then don't do this
    if (store.getters['badmin/isInitialized']) return;

    console.log('%cInitializing Badmin service', 'background: #222; color: #ff0', this);

    // prefetch badmin JSON
    this.loadBadminJSON();
    store.dispatch('badmin/setInit', true);
  }

  loadBadminJSON() {
    const deferred = new Deferred();

    this.bfAPI.getBadminBanners().then((response) => {
      deferred.resolve(response);
    });

    return deferred.promise;
  }

  getBanners(bannerLoc) {
    const deferred = new Deferred();

    this.loadBadminJSON().then((bannerData) => {
      let banners = (bannerData && Object.prototype.hasOwnProperty.call(bannerData, bannerLoc)) ? bannerData[bannerLoc] : null;
      const numBanners = (banners) ? banners.length : 0;
      const bannerMap = {};

      // sort by display order
      if (banners) banners = orderBy(banners, 'display_order', 'asc');

      // adding extra data
      for (let i = 0; i < numBanners; i += 1) {
        // banner is set to specific user types: user type has to match banner's user type settings
        if (banners[i].user_type_exclusive && this.userTypeID !== banners[i].user_type_id) continue;

        // banner is not set to specific user types: user type has to match or be greater than banner's user type settings
        if (!banners[i].user_type_exclusive && this.userTypeID < banners[i].user_type_id) continue;

        // app language setting has to match banner's language setting
        if (Object.prototype.hasOwnProperty.call(banners[i], 'language_code') && banners[i].language_code !== this.locale) continue;

        // app subdomain setting has to match banner's subdomain (if specified)
        // default in db is null, so interpret this as the "main" site (www)
        const checkSubdomain = (banners[i].subdomain) ? banners[i].subdomain : 'www';
        if (Object.prototype.hasOwnProperty.call(banners[i], 'subdomain') && checkSubdomain !== this.subdomain) {
          // no exact subdomain match? check subdomain maps too
          let hasMatch = false;
          if (this.subdomainMaps && Object.prototype.hasOwnProperty.call(this.subdomainMaps, checkSubdomain) && this.subdomainMaps[checkSubdomain].length > 0) {
            for (let j = 0; j < this.subdomainMaps[checkSubdomain].length; j += 1) {
              const mappedSubdomainItem = this.subdomainMaps[checkSubdomain][j];
              if (mappedSubdomainItem === this.subdomain) {
                hasMatch = true;
                break;
              }
            }
          }
          if (!hasMatch) continue;
        }

        // break down full path to image
        banners[i].file_path_arr = banners[i].file_path.split('/');

        // make image path depending on environment
        banners[i].file_path_arr.splice(0, 1);
        banners[i].img_path = banners[i].file_path_arr.join('/');

        // extract filename if we need it
        // eslint-disable-next-line prefer-destructuring
        banners[i].file_name = banners[i].file_path_arr.slice(-1)[0];

        // get location if link is to the site (so we can use <router-link to=""> instead of <a href="">)
        if (Object.prototype.hasOwnProperty.call(banners[i], 'url') && banners[i].url !== null) {
          const bannerUrlObj = new URL(banners[i].url);
          if (bannerUrlObj.origin.match(APP_CONFIG.site.domain)) banners[i].pathname = bannerUrlObj.pathname + bannerUrlObj.search + bannerUrlObj.hash;
        }

        if (!Object.prototype.hasOwnProperty.call(bannerMap, banners[i].group_name)) bannerMap[banners[i].group_name] = [];
        bannerMap[banners[i].group_name].push(banners[i]);
      }

      deferred.resolve(bannerMap);
    });

    return deferred.promise;
  }
}
