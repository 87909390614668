import APP_CONFIG from '../../appConfig';
import Deferred from '../../assets/js/utils/Deferred';

export default {
  initialized: false,

  name: null,
  UID: null,
  typeID: 0,
  typeStr: APP_CONFIG.user.propMap[0].type,
  class: APP_CONFIG.user.propMap[0].class,
  tier: APP_CONFIG.user.propMap[0].tier,
  billing: null,
  nickname: null, // from D2P: user nickname
  vcBalance: 0, // from VChat: points balance
  vcCoupon: 0, // from VChat: coupon balance
  sessionId: null, // from WS reconnect url

  // flags
  isRecurring: false, // from login
  isAnnual: false, // from login
  isFast: false, // from login
  isLimited: false, // from login
  isBank: false, // from D2P
  isTrial: false, // from D2P (hack)
  isLoggedIn: false, // simple flag to just make life easy
  isSiteMember: false, // simple flag to just make life easy
  isStreaming: false, // simple flag to just make life easy
  isDownload: false, // simple flag to just make life easy
  isAnon: true, // from D2P: users can have nicknames, but can opt-out of showing them on reviews
  isClassicDL: false, // from login: flag for "download removal" project (DLIT-885)

  // default behavior for guest/non-site member users; gets set in the mutation after login
  isNewXPEligible: (process.env.VUE_APP_NEW_XP_ENABLED === 'true'),

  upgradeOptions: {}, // will be populated for upgrade options for an easy lookup

  loginData: null, // semaphore
  deferLogin: new Deferred(),
  waitForLogin: () => this.deferLogin.promise,
};
