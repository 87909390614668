/* eslint no-undef: 0 */

export default class CORS {
  constructor(url) {
    this.url = url;
  }

  // Make a CORS request. Currently we just "fire and forget" so if we need to
  // do something with the response, expand this or make a new function. Some
  // insight into CORS: http://www.html5rocks.com/en/tutorials/cors/
  createRequest(method) {
    let xhr = new XMLHttpRequest();

    if ('withCredentials' in xhr) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open(method, this.url, true);
      xhr.withCredentials = true;
      xhr.onload = () => console.log(`%cCORSRequest ${method} sent`, 'background:#C41E3A; color:#fff', this.url);
      xhr.onerror = () => console.log(`%cCORSRequest ${method} error`, 'background:#C41E3A; color:#fff');
      xhr.send();
    } else if (typeof XDomainRequest !== 'undefined') {
      // XDomainRequest for IE.
      xhr = new XDomainRequest();
      xhr.open(method, this.url);
      xhr.onload = () => console.log(`%cCORSRequest ${method} posted`, 'background:#C41E3A; color:#fff', this.url);
      xhr.onerror = () => console.log(`%cCORSRequest ${method} error`, 'background:#C41E3A; color:#fff');
      xhr.send();
    }
  }
}
