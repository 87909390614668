/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  // safe for work mode
  sfwMode: 0,

  // (1p) movie list filter
  movieListFilterVisible: false,

  // (1p) for larger displays, we always show the nav menu (default: false)
  menuAlwaysVisible: false,

  // list of played sample movie IDs as a guest (non-logged-in)
  playedSamples: [],
};

const getters = {
  sfwMode: state => state.sfwMode,
  isMovieListFilterVisible: state => state.movieListFilterVisible,
  menuAlwaysVisible: state => state.menuAlwaysVisible,
  playedSamples: state => state.playedSamples,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
