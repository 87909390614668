const actions = {
  setWebsocketUrl(context, value) {
    context.commit('SET_WEBSOCKET_URL', value);
  },

  setClientName(context, value) {
    context.commit('SET_CLIENT_NAME', value);
  },

  setReadyState(context, value) {
    context.commit('SET_READY_STATE', value);
  },

  setTrackingId(context, value) {
    context.commit('SET_TRACKING_ID', value);
  },
};

export default actions;
