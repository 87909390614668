/* eslint no-console: 0 */

import localforage from 'localforage';

export default class Cache {
  constructor(instance) {
    this.instanceName = instance;
    this.instance = localforage.createInstance({ name: instance });
  }

  clear() {
    console.log(`localforage (${this.instanceName}): clearing cache`);
    this.instance.clear();
  }

  set(key, value) {
    console.log(`localforage (${this.instanceName}): setting key ${key}`, value);
    return this.instance.setItem(key, value); // returns promise from localforage
  }

  get(key) {
    console.log(`localforage (${this.instanceName}): getting key ${key}`);
    return this.instance.getItem(key); // returns promise from localforage
  }

  remove(key) {
    console.log(`localforage (${this.instanceName}): removing key ${key}`);
    return this.instance.removeItem(key); // returns promise from localforage
  }

  keys() {
    console.log(`localforage (${this.instanceName}): getting keys`);
    return this.instance.keys(); // returns promise from localforage
  }
}
