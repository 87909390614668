const formatSeconds = (seconds) => {
  const date = new Date(null);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 8);
};

const formatSecondsSEO = (seconds) => {
  const H = parseInt(seconds / 3600, 10) % 24;
  const M = parseInt(seconds / 60, 10) % 60;
  const S = seconds % 60;
  const time = `T${H}H${M}S${S}`;
  return time;
};

export default { formatSeconds, formatSecondsSEO };
