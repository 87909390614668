/* eslint no-restricted-syntax: 0 */
/* eslint no-unused-vars: 0 */

import Vue from 'vue';

// manually-installed 3rd party deps
import 'airbnb-js-shims/target/es2015'; // shims for IE11
import VueAnalytics from 'vue-ua';
import VueI18n from 'vue-i18n';
import VModal from 'vue-js-modal';
import VueImg from 'v-img';
import Notifications from 'vue-notification';
import vueSmoothScroll from 'vue-smooth-scroll';
import VueProgressBar from 'vue-progressbar';
import VueClipboard from 'vue-clipboard2';
import VueMobileDetection from 'vue-mobile-detection';
import pagination from 'vuejs-uib-pagination';
import VueObserveVisibility from 'vue-observe-visibility';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faCog,
  faLock,
  faLockOpen,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// added by vue-cli
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

// reup deps + configs
import APP_CONFIG from './appConfig';
import VueWebsocket from './assets/js/plugins/VueWebsocket';
import AppDLM from './assets/js/services/Bifrost/listeners/DLM';
import AppLogin from './assets/js/services/Bifrost/listeners/Login';
import AppD2P from './assets/js/services/Bifrost/listeners/D2P';
import AppSelf from './assets/js/services/Bifrost/listeners/Self';
import AppSigner from './assets/js/services/Bifrost/listeners/Signer';
import AppSurvey from './assets/js/services/Bifrost/listeners/Survey';
import AppFEL from './assets/js/services/Bifrost/listeners/FEL';
import AppProxy from './assets/js/services/Bifrost/listeners/Proxy';
import AppVRack from './assets/js/services/Bifrost/listeners/VRack';
import User from './assets/js/services/User';
import DML from './assets/js/services/DML';
import D2PToolbar from './assets/js/services/D2PToolbar';
import VRack from './assets/js/services/VRack';

// custom directives
import SFW from './directives/SafeForWork';

// disable annoying 'you are running view in development mode' in devtools
Vue.config.productionTip = false;

// init services (one time on app-load, so we're doing it here in main.js)
const DLMApp = new AppDLM();
const LoginApp = new AppLogin();
const D2PApp = new AppD2P();
const SelfApp = new AppSelf();
const SignerApp = new AppSigner();
const SurveyApp = new AppSurvey();
const FELApp = new AppFEL();
const ProxyApp = new AppProxy();
const VRackApp = new AppVRack();
const UserSvc = new User();
const DMLSvc = new DML();
const D2PToolbarSvc = new D2PToolbar();
const VRackSvc = new VRack();

// set google analytics tracking id based on hostname
let trackingId = APP_CONFIG.analytics.trackingIDs.default;
store.dispatch('vws/setTrackingId', trackingId);
if (Object.prototype.hasOwnProperty.call(APP_CONFIG, 'analytics')) {
  for (const hostname in APP_CONFIG.analytics.trackingIDs.hostnames) {
    if (Object.prototype.hasOwnProperty.call(APP_CONFIG.analytics.trackingIDs.hostnames, hostname)
      && window.location.hostname.match(hostname)) {
      // found matching config, use that
      trackingId = APP_CONFIG.analytics.trackingIDs.hostnames[hostname];
      store.dispatch('vws/setTrackingId', trackingId);
      break;
    }
  }
}

// set app language
let locale = APP_CONFIG.i18n.defaultLocale;
if (Object.prototype.hasOwnProperty.call(APP_CONFIG, 'i18n')) {
  Object.keys(APP_CONFIG.i18n.hostnames).forEach((hostname) => {
    if (window.location.hostname.match(hostname)) {
      locale = APP_CONFIG.i18n.hostnames[hostname];
    }
  });
}

// load deps
Vue.use(VueWebsocket, { url: process.env.VUE_APP_WEBSOCKET_URL });
Vue.use(VueI18n);
Vue.use(VModal);
Vue.use(VueImg);
Vue.use(Notifications);
Vue.use(vueSmoothScroll);
Vue.use(VueProgressBar, APP_CONFIG.site.progressBarOptions);
Vue.use(pagination);
Vue.use(VueClipboard);
Vue.use(VueMobileDetection);
Vue.use(VueObserveVisibility);
Vue.use(VueAnalytics, {
  trackingId: store.getters['vws/gaTrackingId'],
  appName: APP_CONFIG.site.domain,
  appVersion: process.env.VUE_APP_VERSION,
  debug: process.env.VUE_APP_ANALYTICS_DEBUG,
  globalDimensions: [
    { dimension: 1, value: 'Guest' },
    { dimension: 2, value: 'Lander_Unseen' },
    { dimension: 3, value: 'NC' },
    { dimension: 4, value: 'ja' },
  ],
});

// new instances to make available to all vue components
const i18n = new VueI18n({ locale });

// register i18n in vuex store
store.$i18n = i18n;

// only load fonts we need from Font Awesome collection
library.add(faBell, faCog, faLock, faLockOpen, faSave);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  router,
  store,
  i18n,
  components: { App },
  render: h => h(App),
}).$mount('#app');
