/**
 * @module Core/Providers/Neti
 * @description Helper that provides common functions for flows related to the neti team
 * @example
 * // returns the movie detail object with additional movie properties
  import NetiHelper from '@/assets/js/utils/neti';
  const deviceInfo = NetiHelper.getDeviceInfo();
 */

/* eslint max-len: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint no-multi-assign: 0 */

import UAParser from 'ua-parser-js';
import store from '@/store';

/**
 * @function
 * @name getDeviceInfo
 * @description for DLIT-709: logs device info (via FEL) to the backend which will then be parsed and sent to Neti team for Risk
 * @returns {object} browser, device, os, screen information
 */
const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  // FEL can't uri encode deep nested objects, so create a flattened object
  const deviceInfo = {
    browser_name: result.browser.name,
    browser_version: result.browser.version,
    device_based: (result.device.model || result.os.name),
    device_type: result.device.vendor ? 'Mobile' : 'Desktop',
    os_name: result.os.name,
    os_version: result.os.version,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
  };

  console.log('%c neti helper: getDeviceInfo', 'color:#f0f', deviceInfo);

  return deviceInfo;
};

/**
 * @function
 * @name siftPageView
 * @description for DLIT-874: send page view events to sift
 */
const siftPageView = () => {
  const UID = (store.getters['user/UID']) ? `D01U${store.getters['user/UID']}` : ''; // prefixing UID with D01U because PSS already does this
  const username = store.getters['user/name'] || '';
  const sessionId = store.getters['user/sessionId'] || '';

  const isProd = (process.env.NODE_ENV === 'production');
  const isTestAccount = (username.endsWith('@d2ptests.d2p'));

  // only send for prod/non-test accounts (or dev/stg envs)
  if ((isProd && !isTestAccount) || (!isProd)) {
    const _sift = window._sift = window._sift || [];
    _sift.push(['_setUserId', UID]);
    _sift.push(['_setSessionId', sessionId]);
    _sift.push(['_trackPageview']);
  }
};

export default { getDeviceInfo, siftPageView };
