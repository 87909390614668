/* eslint-disable no-use-before-define */
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint wrap-iife: 0 */
/* eslint object-curly-newline: 0 */

/*
  we load the vrack loader dynamically and need to wait for their `ready` event before we can do things, so the flow
  goes like this:
    - Vrack service first loaded in this app's main.js
    - loader() runs which dynamically loads vrack's loader, which then runs the readyCheck recursive loop
    - readyCheck() waits for vrack to become ready, then starts init()
    - init() then makes the vrack API methods available in the service and sets up event handlers/listeners

  vrack store is used so we can keep track of the states:
    - `started` so we only try to start loader.js one time (in main.js)
    - `isReady` so we know when we can start the init process
    - `initialized` so we only set the APIs and event handlers/listeners once
*/
import APP_CONFIG from '@/appConfig';
import store from '@/store';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import EventBus from '@/assets/js/utils/EventBus';
import Analytics from '@/assets/js/utils/Analytics';

const bfAPI = new BifrostAPI();
const CONSOLE_COLOR = APP_CONFIG.console.log.vrack;
const MOVIE_ID_PREFIX = APP_CONFIG.site.shortName;
const STARTUP_OPTIONS = APP_CONFIG.vrack.startupOptions;

export default class VRack {
  constructor() {
    store.dispatch('vrack/checkEnabled');
    this.enabled = store.getters['vrack/enabled'];

    this.maxTries = 1000; // number tries looking for vrack before giving up
    this.numTries = 0;

    this.api = {};

    this.readyCheck = () => {
      if (Object.prototype.hasOwnProperty.call(window, 'vrack')) {
        window.vrack.startup(STARTUP_OPTIONS);
        const disposeReadyListener = window.vrack.on('ready', () => {
          console.log('%cVRack ready!', CONSOLE_COLOR, window.vrack);
          setTimeout(() => {
            // adding a short delay here because API isn't available immediately after the 'ready' event is emitted
            this.init();
          }, 250);
          disposeReadyListener();
        });
      } else if (this.numTries < this.maxTries) {
        setTimeout(this.readyCheck.bind(), 250);
        this.numTries += 1;
      } else {
        console.log('%cunable to load vrack loader.js, giving up :(', CONSOLE_COLOR);
        Analytics.sendCustomEvent('v-rack', {
          action: 'Failed to load',
        });
      }
    };

    // set listeners (to be disposed when restarting or when vrack shuts down)
    this.disposeHideListener = null;
    this.disposeShowListener = null;
    this.disposeShutdownListener = null;
    this.disposeMovieActiveInfoListener = null;
    this.disposeMovieAddListener = null;
    this.disposeMovieRemoveListener = null;
    this.disposeThemeChangeListener = null;
    this.disposeLanguageChangeListener = null;
    this.disposeAuthRequestListener = null;
    this.disposeVideoPlayListener = null;
    this.disposeVideoErrorListener = null;

    if (store.getters['vrack/isReady']) {
      this.init();
    } else {
      this.loader();
    }
  }

  loader() {
    // hostname-based config exists?
    let hostMatched = false;
    if (Object.prototype.hasOwnProperty.call(APP_CONFIG.vrack, 'hostnames')) {
      for (const hostConfig in APP_CONFIG.vrack.hostnames) {
        if (window.location.hostname.match(hostConfig) && APP_CONFIG.vrack.hostnames[hostConfig]) {
          hostMatched = true;
          break;
        }
      }
    }

    const vrackEnabled = this.enabled && hostMatched;
    if (vrackEnabled && !store.getters['vrack/started']) {
      console.log('%cInitializing VRack service', 'background: #222; color: #ff0');
      (() => {
        const vrackLoader = document.createElement('script');
        vrackLoader.type = 'text/javascript';
        vrackLoader.defer = 'defer';
        vrackLoader.src = APP_CONFIG.vrack.url;
        if (Object.prototype.hasOwnProperty.call(APP_CONFIG.vrack, 'options')) {
          for (const option in APP_CONFIG.vrack.options) {
            if (APP_CONFIG.vrack.options[option]) vrackLoader.setAttribute(option, APP_CONFIG.vrack.options[option]);
          }
        }
        const s = document.getElementsByTagName('script')[0];
        console.log('%cVRack inserting loader.js script into document', CONSOLE_COLOR);
        s.parentNode.insertBefore(vrackLoader, s);
      })();

      store.dispatch('vrack/setStarted', true);

      this.readyCheck();
    }
  }

  init() {
    // this method is called when VRack is ready
    console.log('%cVRack init()', CONSOLE_COLOR);

    // destroy listeners if they have already been set
    this.disposeListeners();

    // make vrack API methods available (ex `VrackSvc.api.show()`)
    for (const prop in window.vrack) {
      if (typeof window.vrack[prop] === 'function' && !Object.prototype.hasOwnProperty.call(this.api, prop)) {
        this.api[prop] = window.vrack[prop];
      }
    }

    if (!store.getters['vrack/isReady']) {
      console.log('%cVRack API available', CONSOLE_COLOR, this.api);
      store.dispatch('vrack/setReady', true);
    }

    if (!store.getters['vrack/initialized']) {
      // setup event handlers
      const onShow = ({ referrer }) => {
        console.log('%cVRack Event: show', CONSOLE_COLOR, { referrer });
        EventBus.$emit('vrack:event:show', { referrer });

        // we want to igore this if it happens as part of the "movie add" flow; vrack opens the
        // shelf after a movie has been successfully added and subsequently fires a 'show' event. this
        // is fine since we set a separate ga4 event when a movie is successfully added
        if (referrer !== 'movie-add') {
          const referrers = {
            'user-menu': 'Account Info',
            'save-button': 'Saved To VRack Button',
          };
          Analytics.sendCustomEvent('v-rack', {
            action: 'Open V-Rack',
            org_site: APP_CONFIG.site.shortName,
            action_location: (Object.prototype.hasOwnProperty.call(referrers, referrer)) ? referrers[referrer] : 'Widget Button',
          });
        }
      };
      const onHide = () => {
        console.log('%cVRack Event: hide', CONSOLE_COLOR);
        EventBus.$emit('vrack:event:hide');
      };
      const onShutdown = () => {
        console.log('%cVRack Event: shutdown', CONSOLE_COLOR);
        store.dispatch('vrack/setReady', false);
        EventBus.$emit('vrack:event:shutdown');
      };
      const onMovieActiveInfo = ({ id, hasLoaded, status, reason }) => {
        console.log('%cVRack Event: movie-active-info', CONSOLE_COLOR, { id, hasLoaded, status, reason });

        EventBus.$emit('vrack:event:movie-active-info', { id, hasLoaded, status, reason });
      };
      const onMovieAdd = ({ ids, status, reason }) => {
        console.log('%cVRack Event: movie-add', CONSOLE_COLOR, { ids, status, reason });

        // movie has been added to vrack; dirty cache so we can request updated limits info
        store.dispatch('vrack/setDirtyCache', true);

        EventBus.$emit('vrack:event:movie-add', { ids, status, reason });
      };
      const onMovieRemove = ({ id, status, reason }) => {
        console.log('%cVRack Event: movie-remove', CONSOLE_COLOR, { id, status, reason });
        EventBus.$emit('vrack:event:movie-remove', { id, status, reason });
      };
      const onThemeChange = ({ palette }) => {
        console.log('%cVRack Event: theme-change', CONSOLE_COLOR, { palette });
        EventBus.$emit('vrack:event:theme-change', { palette });
      };
      const onLanguageChange = ({ code }) => {
        console.log('%cVRack Event: language-change', CONSOLE_COLOR, { code });
        EventBus.$emit('vrack:event:language-change', { code });
      };
      const onAuthRequest = ({ request }) => {
        console.log('%cVRack Event: auth-request', CONSOLE_COLOR, { request });
        this.api.hide();
        EventBus.$emit('vrack:event:auth-request', { request });
      };
      const onVideoPlay = ({ movieId, actressName, movieTitle }) => {
        console.log('%cVRack Event: video-play-start', CONSOLE_COLOR, { movieId, actressName, movieTitle });

        // parse data for analytics (movie_site, movie_id)
        const parts = movieId.split('_');
        if (parts.length === 2) {
          const siteName = parts[0];
          const parsedMovieId = parseInt(parts[1], 10);
          const capitalizedSiteName = siteName.charAt(0).toUpperCase() + siteName.slice(1);

          if (siteName === MOVIE_ID_PREFIX) {
            // get movie date id and send to GA
            bfAPI.getMovieDateID(parsedMovieId).then((response) => {
              Analytics.sendCustomEvent('v-rack', {
                action: 'Play Movie',
                vrack_movie_id: movieId,
                movie_site: capitalizedSiteName,
                movie_id: response.MovieDateID,
                actress_name: actressName,
                movie_title: movieTitle,
              });
            });
          } else {
            // send to GA without movie_id
            Analytics.sendCustomEvent('v-rack', {
              action: 'Play Movie',
              vrack_movie_id: movieId,
              movie_site: capitalizedSiteName,
              actress_name: actressName,
              movie_title: movieTitle,
            });
          }
        }

        EventBus.$emit('vrack:event:video-play-start', { movieId, actressName, movieTitle });
      };
      const onVideoError = () => {
        console.log('%cVRack Event: video-error', CONSOLE_COLOR);
        EventBus.$emit('vrack:event:video-error');

        Analytics.sendCustomEvent('v-rack', {
          action: 'Movie Player Failed',
        });
      };

      // set up event listeners
      this.disposeShowListener = window.vrack.on('show', onShow);
      this.disposeHideListener = window.vrack.on('hide', onHide);
      this.disposeShutdownListener = window.vrack.on('shutdown', onShutdown);
      this.disposeMovieActiveInfoListener = window.vrack.on('movie-active-info', onMovieActiveInfo);
      this.disposeMovieAddListener = window.vrack.on('movie-add', onMovieAdd);
      this.disposeMovieRemoveListener = window.vrack.on('movie-remove', onMovieRemove);
      this.disposeThemeChangeListener = window.vrack.on('theme-change', onThemeChange);
      this.disposeLanguageChangeListener = window.vrack.on('language-change', onLanguageChange);
      this.disposeAuthRequestListener = window.vrack.on('auth-request', onAuthRequest);
      this.disposeVideoPlayListener = window.vrack.on('video-play-start', onVideoPlay);
      this.disposeVideoErrorListener = window.vrack.on('video-error', onVideoError);

      console.log('%cVRack events listeners and handlers set', CONSOLE_COLOR);

      store.dispatch('vrack/setInit', true);
    }
  }

  disposeListeners() {
    if (this.disposeShowListener) this.disposeShowListener();
    if (this.disposeHideListener) this.disposeHideListener();
    if (this.disposeShutdownListener) this.disposeShutdownListener();
    if (this.disposeMovieActiveInfoListener) this.disposeMovieActiveInfoListener();
    if (this.disposeMovieAddListener) this.disposeMovieAddListener();
    if (this.disposeMovieRemoveListener) this.disposeMovieRemoveListener();
    if (this.disposeThemeChangeListener) this.disposeThemeChangeListener();
    if (this.disposeLanguageChangeListener) this.disposeLanguageChangeListener();
    if (this.disposeAuthRequestListener) this.disposeAuthRequestListener();
    if (this.disposeVideoPlayListener) this.disposeVideoPlayListener();
    if (this.disposeVideoErrorListener) this.disposeVideoErrorListener();
  }

  restart() {
    console.log('%cVRack restarting...', CONSOLE_COLOR);
    if (Object.prototype.hasOwnProperty.call(this.api, 'restart')) {
      this.api.restart(STARTUP_OPTIONS);
      const disposeReadyListener = window.vrack.on('ready', () => {
        console.log('%cVRack ready after restarting!', CONSOLE_COLOR, window.vrack);
        setTimeout(() => {
          // adding a short delay here because API isn't available immediately after the 'ready' event is emitted
          this.init();
        }, 250);
        disposeReadyListener();
      });
    }
  }

  // JS API wrappers
  getMovieInfo(movieId) {
    this.api.getMovieInfo(`${MOVIE_ID_PREFIX}_${movieId}`);
  }

  addMovie(movieId, token) {
    const movieDetails = {
      movieId: `${MOVIE_ID_PREFIX}_${movieId}`,
      token,
    };
    console.log('%cVRack JS API: addMovie', CONSOLE_COLOR, movieDetails);
    this.api.addMovie(movieDetails, true); // enable 'saving' animation
  }
}
