/* eslint no-param-reassign: 0 */

const mutations = {
  SET_INIT(state) {
    state.initialized = true;
  },

  SET_BANNERS(state, payload) {
    state.banners = payload;
  },
};

export default mutations;
