/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  // pre-release: true if email is @d2ptests.d2p, post-release: true if user is a site member
  // this value will be used on the component side to show/hide playlist features
  enabled: false,

  // flags for future playlist features
  custom_enabled: false,
  public_enabled: false,

  // localized strings for default playlists
  translationMap: {
    liked: {
      ja: 'お気に入り',
      en: 'Liked',
    },
    watch_later: {
      ja: '後で見る',
      en: 'Watch Later',
    },
  },

  // number of custom playlists allowed to be created
  custom_limit: 0,

  // user's unique playlist IDs for the default playlists
  defaultPlaylistIDs: {
    liked: null,
    watch_later: null,
  },
};

const getters = {
  isEnabled: state => state.enabled,
  isCustomListsEnabled: state => state.custom_enabled,
  isPublicListsEnabled: state => state.public_enabled,
  playlists: state => state.playlists,
  translationMap: state => state.translationMap,
  custom_limit: state => state.custom_limit,
  defaultPlaylistIDs: state => state.defaultPlaylistIDs,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
