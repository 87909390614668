<i18n>
{
  "en": {
    "close_notification": "CLOSE"
  },
  "ja": {
    "close_notification": "閉じる"
  }
}
</i18n>

<template>
  <notifications group="trial" position="bottom center" class="vue-notification-bottom-center" width="100%">
    <template slot="body" slot-scope="props">
      <div v-if="closeable" class="camman-close" @click="props.close">
        <div class="camman-close-icon"></div>
      </div>
      <div v-html="props.item.text" :style="{backgroundColor: bannerBackgroundColor}"></div>
    </template>
  </notifications>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* vue/no-parsing-error: 0 */

const BANNERS = {
  en: {
    link: 'https://wm.1pondo.tv/upgrade/en/',
    img: '/img/banners/r3633-cm-banner-solid.png',
    backgroundColor: '#2098e4',
    closeable: false,
  },
};

export default {
  data() {
    return {
      bannerShown: false,
      closeable: true,
      bannerImg: '',
      bannerLink: '',
      bannerBackgroundColor: '',
    };
  },
  created() {
    if (this.isBannerEligible()) this.showBanner();
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userIsTrial() {
      return this.$store.getters['user/isTrial'];
    },
  },
  watch: {
    userIsTrial(newVal, oldVal) {
      if (newVal !== oldVal && newVal && this.isBannerEligible()) {
        this.showBanner();
      } else {
        this.hideBanner();
      }
    },
  },
  methods: {
    isBannerEligible() {
      if (this.userIsTrial && BANNERS[this.locale] && !this.bannerShown) {
        this.bannerImg = BANNERS[this.locale].img;
        this.bannerLink = BANNERS[this.locale].link;
        this.closeable = BANNERS[this.locale].closeable;
        this.bannerBackgroundColor = BANNERS[this.locale].backgroundColor;
        return true;
      }
      return false;
    },
    showBanner() {
      this.$notify({
        group: 'trial',
        type: 'trial-banner',
        text: `
                <a href="${BANNERS[this.locale].link}" class="camman-content" target="_blank" rel="noopener">
                  <img src="${BANNERS[this.locale].img}" class="camman-image" id="trial-banner">
                </a>
              `,
        duration: -1,
      });
      this.bannerShown = true;
    },
    hideBanner() {
      this.$notify({
        group: 'trial',
        clean: true,
      });
      this.bannerShown = false;
    },
  },
};
</script>

<style lang="scss">
.camman-container {
  position: relative;
}

.camman-content {
  display: grid;
  place-items: center;
}

.camman-close {
  cursor: pointer;
  display: grid;
  height: 44px;
  place-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
}

.camman-close-icon {
  background: #e4e4e4;
  box-shadow: rgba(0, 0, 0, .1) 0px 4px 6px -1px, rgba(0, 0, 0, .06) 0px 2px 4px -1px;
  border-radius: 4px;
  height: 28px;
  position: relative;
  width: 28px;

  &::before,
  &::after {
    content: "";
    background: #585858;
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 16px;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.camman-image {
  max-width: 100%;
}
</style>
