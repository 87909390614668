/* eslint max-len: 0 */

import store from '@/store';

const TEST_ACCOUNTS = [
  '@crlausa.com',
  '@d2p.com',
  '@d2pass.com',
  '@d2passport.com',
  '@d2passtest.com',
  '@d2ptest.d2p',
  '@d2ptests.d2p',
  '@hypermediasystems.com',
  '@kstests.d2p',
  '@pac-pictures.com',
  '@qa.aff',
  '@wrsapix.com',
];

/**
 * @function
 * @name dataLayerPush
 * @description alias for pushing dataLayer params but with a reset to clear out previous params
 */
const dataLayerPush = (params) => {
  // first we reset dataLayer because values persist since this is a single-page-app
  // ref: https://developers.google.com/tag-platform/devguides/datalayer#reset
  window.dataLayer.push(function reset() { this.reset(); });
  window.dataLayer.push(params);

  console.log('%cAnalytics dataLayer.push', 'color:#fff; background-color: rgb(234, 134, 0)', params);
};

/**
 * @function
 * @name getUserInfo
 * @description get user info for fields that will be needed for some GTM params
 */
const getUserInfo = () => {
  const UID = (store.getters['user/UID']) ? store.getters['user/UID'] : '';
  const username = store.getters['user/name'];
  const isSiteMember = store.getters['user/isSiteMember'];
  const isAnnual = store.getters['user/isAnnual'];
  const userTypeStr = store.getters['user/typeStr'];
  const billing = store.getters['user/billing'];

  const userInfo = {
    user_type: (isSiteMember) ? 'Member' : 'Guest',
  };

  if (UID) {
    if (isSiteMember) userInfo.user_id = UID;
    userInfo.d2p_id = UID;

    // membership_type (Reg | VIP | SVIP | Str-leg | Str-30d | Str-360d)
    const legacyStreaming = ['Streaming', 'StreamingMember', 'StreamingVIP', 'StreamingSVIP', 'StreamingAnnual'];
    if (userTypeStr === 'NonMember') {
      userInfo.membership_type = 'Non-Member';
    } else if (userTypeStr === 'Member') {
      userInfo.membership_type = 'Reg';
    } else if (userTypeStr === 'VIP') {
      userInfo.membership_type = 'VIP';
    } else if (userTypeStr === 'SVIP') {
      userInfo.membership_type = (isAnnual) ? 'Annual' : 'SVIP';
    } else if (legacyStreaming.includes(userTypeStr)) {
      userInfo.membership_type = 'Str-leg';
    } else if (userTypeStr === 'AYCS' && billing.PackageDuration === 30) {
      userInfo.membership_type = 'Str-30d';
    } else if (userTypeStr === 'AYCS' && billing.PackageDuration === 360) {
      userInfo.membership_type = 'Str-360d';
    }

    // test_type (ref http://redmine.dl/issues/3789)
    userInfo.test_status = 'Real User';
    TEST_ACCOUNTS.forEach((testDomain) => {
      if (userInfo.test_status !== 'Test' && username.endsWith(testDomain)) userInfo.test_status = 'Test';
    });
  }

  return userInfo;
};

/**
 * @function
 * @name sendCustomEvent
 * @description
 */
const sendCustomEvent = (eventName, keyvals) => {
  const params = {
    event: eventName,
    ...keyvals,
  };

  dataLayerPush(params);
};

/**
 * @function
 * @name sendLoginEvent
 * @description
 */
const sendLoginEvent = () => {
  const userInfo = getUserInfo();

  const params = {
    event: 'login',
    ...userInfo,
  };

  if (Object.prototype.hasOwnProperty.call(userInfo, 'membership_type')) {
    // not needed per https://docs.google.com/spreadsheets/d/1m-cjEJO9-XwqC-PDO7gyXJnCseS7hp6srvTBQEjBpeA/edit#gid=1166529450
    delete params.user_type;
  }

  dataLayerPush(params);
};

/**
 * @function
 * @name sendPageView
 * @description for r3383/DLIT-929: send manual page view events to GA4
 */
const sendPageView = () => {
  const userInfo = getUserInfo();

  const params = {
    event: 'page_load',
    ...userInfo,
  };

  dataLayerPush(params);
};

export default { sendCustomEvent, sendLoginEvent, sendPageView };
