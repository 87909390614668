<i18n>
{
  "en": {
    "pr_header": "PR"
  },
  "ja": {
    "pr_header": "PR"
  }
}
</i18n>

<template>
<div>
  <div class="nav__title">{{ $t('pr_header') }}</div>
  <div v-for="(banners, groupName) in badminBannerGroups" :key="groupName" class="d-flex flex-wrap align-items-center justify-content-between">
    <div v-for="banner in banners" :key="banner.display_order" @click="clickTrack('Nav Menu', 'PR', banner.google_analytics_label);">
      <span v-if="!banner.pathname || banner.url_target === true">
        <a
          :href="banner.url"
          :data-site="banner.google_analytics_label"
          class="nav__banner"
          target="_blank"
          rel="noopener">
          <img class="nav__banner-image" :src="`/dyn/dla/images/${banner.img_path}`">
        </a>
      </span>
      <span v-if="banner.pathname">
        <router-link
          :to="banner.pathname"
          :data-site="banner.google_analytics_label"
          class="nav__banner">
          <img class="nav__banner-image" :src="`/dyn/dla/images/${banner.img_path}`">
        </router-link>
      </span>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import Badmin from '@/assets/js/services/Badmin';

export default {
  data() {
    return {
      badminBannerGroups: {},
    };
  },
  beforeCreate() {
    const badminSvc = new Badmin(this.$i18n.locale, this.$store.getters['user/typeID']);
    badminSvc.getBanners('pr').then((banners) => {
      this.badminBannerGroups = banners;
    });
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    userTypeID(newVal, oldVal) {
      if (newVal !== oldVal) {
        const badminSvc = new Badmin(this.$i18n.locale, this.$store.getters['user/typeID']);
        badminSvc.getBanners('pr').then((banners) => {
          this.badminBannerGroups = banners;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
