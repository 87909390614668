/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint prefer-destructuring: 0 */

import includes from 'lodash/includes';
import UAParser from 'ua-parser-js';
import store from '@/store';
import PlayerCfg from './config';
import BifrostAPI from '../Bifrost/API';
import MovieHelper from '../../utils/movie';
import Deferred from '../../utils/Deferred';

const IS_PPV_SITE = false;
const VIP_CLASSES = ['StreamingVIP', 'VIP', 'StreamingSVIP', 'SVIP', 'DTI'];
const SVIP_CLASSES = ['StreamingSVIP', 'SVIP', 'DTI'];

export default class Player {
  constructor(name, movieData, userData) {
    this.name = `video-${name}`;
    this.movieDetails = movieData;
    this.movieId = movieData.MovieID;
    this.movieKey = null;
    this.movieOffset = 0;
    this.movieSources = [];
    this.moviePaths = {
      sample: IS_PPV_SITE ? PlayerCfg.ppvSampleVideo : PlayerCfg.sampleVideo,
      full: IS_PPV_SITE ? PlayerCfg.ppvFullVideo : PlayerCfg.memberVideo,
    };
    this.currentMovie = null; // used by the html5 player; current movie src for the player
    this.autoPlay = false;
    this.fullPlay = {
      enabled: false,
      selected: (userData.isSiteMember && !IS_PPV_SITE),
    };
    this.quality = {
      image: 'Ultra',
      video: PlayerCfg.defaultQuality,
    };
    this.poster = null;
    this.useMercKey = PlayerCfg.useMercKey;
    this.streamType = 'default';
    this.events = {};
    this.properties = {};
    this.states = {};
    this.quirks = {};
    this.initialized = false;
    this.initPlayerFn = null;

    this.getMovieProps(this.movieDetails, userData).then(() => { this.initPlayerFn(); });
  }

  initPlayer(fn) {
    this.initPlayerFn = fn;

    if (store.getters['videoPlayer/eventsInitialized']) return;

    // set vuex store so we don't re-initialize events the next time a player is initialized
    store.dispatch('videoPlayer/setEventsInit', true);
  }

  canPlayFullMovie(user) {
    const MOVIE_ID = this.movieDetails.MetaMovieID;
    const NEW_EXPERIENCE_ELIGIBLE = store.getters['user/isNewXPEligible'];

    const defer = new Deferred();

    // movie blocks for trial users
    const movieBlocks = Object.prototype.hasOwnProperty.call(PlayerCfg, 'MovieBlocks') ? PlayerCfg.MovieBlocks : null;

    let canPlay = false;

    if (user.isSiteMember || IS_PPV_SITE) {
      // tell backend to send us PPV keys; set by the "is-ppv" attribute on the template
      const bfAPI = new BifrostAPI();

      bfAPI.ReqMovieKeys(MOVIE_ID, IS_PPV_SITE).then((response) => {
        this.movieKey = (this.useMercKey) ? response.KeyMercury : response.KeyLegacy;
        this.movieOffset = response.Offset;

        // new experience = all-access streaming; don't check tier permissions
        if (NEW_EXPERIENCE_ELIGIBLE && this.movieKey !== '') canPlay = true;

        // only do checks if the movie key is non-empty
        if (!NEW_EXPERIENCE_ELIGIBLE && this.movieKey !== '') {
          this.movieOffset = response.Offset;

          // check if CanStream flag is set and the user is streaming tier or higher
          // jun 2 2021: the line below was previously the last check in the sequence of checks, but
          // now moved to the top, in order to fix an issue where lower-tier members were attempting
          // to load member movies instead of sample movies when playing higher-tier movies. this
          // change came about due to a recent change in the backend where movie keys were being
          // sent even if the user didn't qualify (playback wouldn't work since they'd get hung up
          // on the movie auth side) and this line being at the bottom was causing this new issue to
          // happen. previously, this line was okay being the last check because the movie key was
          // only sent for qualified users so this entire block was never run

          // 1p: has 1 streaming tier, can only play regular tier movies
          // 10m: has streaming-only tiers (vip, svip, annual)
          // paco: has 1 streaming tier, but can play all tier movies
          // (src: https://giganik.slack.com/archives/G6KNK473J/p1610040044181900)
          if (this.movieDetails.CanStream && Object.prototype.hasOwnProperty.call(user, 'typeID') && user.typeID >= 20) canPlay = true;

          // check to see if the user has access to dl/vip/svip etc
          switch (this.movieDetails.Type) {
            case 0: {
              canPlay = true;
              break;
            }
            case 1: {
              canPlay = (VIP_CLASSES.includes(user.typeStr));
              break;
            }
            case 2: {
              canPlay = (SVIP_CLASSES.includes(user.typeStr));
              break;
            }
            default: {
              break;
            }
          }

          // check if conditions match (annual, recurring)
          if (this.movieDetails.Conditions && includes(this.movieDetails.Conditions, 4)) {
            canPlay = (user.isRecurring);
          }
          if (this.movieDetails.Conditions && includes(this.movieDetails.Conditions, 5)) {
            canPlay = (user.isAnnual);
          }

          if (this.movieDetails.Conditions && (includes(this.movieDetails.Conditions, 2) || includes(this.movieDetails.Conditions, 3))) {
            canPlay = false;
          }
        }

        // hacky method of blocking specific movies for trial users
        if (user.isTrial && Object.prototype.hasOwnProperty.call(movieBlocks, 'trial') && includes(movieBlocks.trial, this.movieId)) canPlay = false;

        defer.resolve(canPlay);
      });
    } else {
      this.movieKey = null;
      this.movieOffset = 0;

      defer.resolve(canPlay);
    }

    return defer.promise;
  }

  getMovieURL(movieURL, movieFilename) {
    let movieUrl = movieURL;
    let deliveryType;
    let mimeType;

    // 1. get URL + mimeType from config and .replace() the url as needed
    if (this.fullPlay.enabled && this.fullPlay.selected) {
      deliveryType = this.moviePaths.full[this.streamType].deliveryType;
      mimeType = this.moviePaths.full[this.streamType].mimeType;

      // full movie
      if (this.moviePaths.full[this.streamType].deliveryType === 'progressive') {
        // progressive download stream: replace URL if we have a rule for it
        movieUrl = this.moviePaths.full[this.streamType].url;
        movieUrl = movieUrl.replace(/\{MOVIE_ID\}/g, this.movieDetails[this.moviePaths.full[this.streamType].movieIdKey]);
        movieUrl = movieUrl.replace(/\{MOVIE_FILE\}/g, movieFilename);
      } else if (this.moviePaths.full[this.streamType].deliveryType === 'hls') {
        // hls stream
        movieUrl = this.moviePaths.full[this.streamType].url;
        movieUrl = movieUrl.replace(/\{MOVIE_ID\}/g, this.movieDetails[this.moviePaths.full[this.streamType].movieIdKey]);
        movieUrl += `?${this.moviePaths.full[this.streamType].movieKeyParam}=${this.movieKey}&d=.m3u8`;
      }
    } else {
      deliveryType = this.moviePaths.sample[this.streamType].deliveryType;
      mimeType = this.moviePaths.sample[this.streamType].mimeType;

      // sample movie
      if (this.moviePaths.sample[this.streamType].deliveryType === 'progressive') {
        // progressive downnload stream: replace URL if we have a rule for it
        movieUrl = this.moviePaths.sample[this.streamType].url;
        movieUrl = movieUrl.replace(/\{MOVIE_ID\}/g, this.movieDetails[this.moviePaths.sample[this.streamType].movieIdKey]);
        movieUrl = movieUrl.replace(/\{MOVIE_FILE\}/g, movieFilename);
      } else if (this.moviePaths.full[this.streamType].deliveryType === 'hls') {
        // hls stream
        movieUrl = this.moviePaths.sample[this.streamType].url;
        movieUrl = movieUrl.replace(/\{MOVIE_ID\}/g, this.movieDetails[this.moviePaths.sample[this.streamType].movieIdKey]);
      }
    }

    // 2. adjust URL as needed for various video delivery types
    if (deliveryType === 'hls') {
      // hls: add params so the m3u8 manifest only includes the specified streams (needed for jw7)
      switch (movieFilename) {
        case '240p.mp4': {
          movieUrl += '&only240p=1';
          break;
        }
        case '360p.mp4': {
          movieUrl += '&only360p=1';
          break;
        }
        case '480p.mp4': {
          movieUrl += '&only480p=1';
          break;
        }
        case '720p.mp4': {
          movieUrl += '&only720p=1';
          break;
        }
        case '1080p.mp4': {
          movieUrl += '&only1080p=1';
          break;
        }
        default: {
          break;
        }
      }
    } else if (deliveryType === 'progressive') {
      // progressive download: add movie key and other stuff to the end of the url
      if (this.fullPlay.enabled && this.fullPlay.selected) {
        movieUrl += `?${this.moviePaths.full[this.streamType].movieKeyParam}=${this.movieKey}&d=.mp4`;
      }
    }

    // 3. full movie: add movie offset if we have it and the feature is enabled in config
    if (this.fullPlay.enabled && this.fullPlay.selected && Object.prototype.hasOwnProperty.call(PlayerCfg, 'resumePlayback') && PlayerCfg.resumePlayback) {
      movieUrl += `#t=${this.movieOffset}`;
    }

    return {
      url: movieUrl,
      mimeType,
      deliveryType,
    };
  }

  getMovieSources(isFullMovie) {
    const defer = new Deferred();

    let hasDefaultQuality = false;
    const movieFiles = (isFullMovie) ? 'MemberFiles' : 'SampleFiles';

    // set stream to play (named stream rule from config)
    this.streamType = (Object.prototype.hasOwnProperty.call(PlayerCfg, 'streamType')) ? PlayerCfg.streamType : 'default';

    // modify stream to play; do we have UA-specific rules? (mobile devices)
    const parser = new UAParser();
    const UA = parser.getResult();
    if (Object.prototype.hasOwnProperty.call(PlayerCfg, 'streamTypeUA') && Object.prototype.hasOwnProperty.call(PlayerCfg.streamTypeUA, UA.os.name)) {
      // use UA-defined streams
      this.streamType = PlayerCfg.streamTypeUA[UA.os.name];
    }

    // stream type is set, what kind of video delivery is it? (hls, progressive download, etc)
    const deliveryType = (isFullMovie) ? this.moviePaths.full[this.streamType].deliveryType : this.moviePaths.sample[this.streamType].deliveryType;

    // build list of movie sources (different qualities)
    const movieSources = this.movieDetails[movieFiles].map((source) => {
      const movieSource = this.getMovieURL(source.URL, source.FileName);
      const sourceURL = movieSource.url;
      const mimeType = movieSource.mimeType;

      // label for jwplayer (strip file extension)
      const label = source.FileName.replace(/\.[^/.]+$/, '');
      if (!hasDefaultQuality && label === this.quality.video) hasDefaultQuality = true;

      return {
        file: sourceURL,
        type: mimeType,
        label,
      };
    });

    // add 'Auto' quality for jw7 html5 + HLS streams (jw6 flash + HLS adds an 'Auto' option by default; jw7 doesn't)
    // if ( $attrs.hasOwnProperty('jwPlayer7') && deliveryType == 'hls' ) {
    if (deliveryType === 'hls') {
      const movieSource = this.getMovieURL(this.movieDetails[movieFiles][0].URL, 'Auto');

      movieSources.push({
        file: movieSource.url,
        type: movieSource.mimeType,
        label: 'Auto',
        default: true,
      });
    }

    // check if default resolution is available; if not, fallback to lower available one
    if (!hasDefaultQuality) this.quality.video = movieSources[movieSources.length - 1].label;

    defer.resolve(movieSources);

    return defer.promise;
  }

  getMovieProps(movieDetails, user) {
    const defer = new Deferred();

    // expand movie details to make life easier on the template side (we need conditions)
    this.movieDetails = MovieHelper.expandDetails(movieDetails);

    this.canPlayFullMovie(user).then((result) => {
      this.fullPlay.enabled = result;

      // need this so that a non-monthly member user who has an eligible PPV movie can play a full movie
      if (IS_PPV_SITE) this.fullPlay.selected = result;

      this.getMovieSources(this.fullPlay.enabled).then((sources) => {
        this.movieSources = sources;

        // if we need to pass anything into initPlayer(), do it here
        defer.resolve({});
      });
    });

    return defer.promise;
  }
}
