/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint wrap-iife: 0 */

import APP_CONFIG from '@/appConfig';
import store from '@/store';

export default class D2PToolbar {
  constructor() {
    console.log('%cInitializing D2P Toolbar service', 'background: #222; color: #ff0', this);

    this.enabled = APP_CONFIG.d2pToolbar.enabled;

    this.interval = null;
    this.maxTries = 100; // number of intervals to try looking for the toolbar before giving up
    this.numTries = 0;

    this.init();
  }

  init() {
    // stop refresh() interval loop if it's running
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    // hostname-based config exists?
    let hostMatched = false;
    if (Object.prototype.hasOwnProperty.call(APP_CONFIG.d2pToolbar, 'hostnames')) {
      for (const hostConfig in APP_CONFIG.d2pToolbar.hostnames) {
        if (window.location.hostname.match(hostConfig) && APP_CONFIG.d2pToolbar.hostnames[hostConfig]) {
          hostMatched = true;
          break;
        }
      }
    }
    const toolbarEnabled = this.enabled && hostMatched;

    if (toolbarEnabled && !store.getters['d2ptoolbar/initialized']) {
      window._d2ptbq = (Object.prototype.hasOwnProperty.call(APP_CONFIG.d2pToolbar, 'customCss')) ? { urlCss: APP_CONFIG.d2pToolbar.customCss } : (window._d2ptbq || {});

      (function loader() {
        const d2ptb = document.createElement('script');
        d2ptb.type = 'text/javascript';
        d2ptb.async = true;
        d2ptb.src = APP_CONFIG.d2pToolbar.url;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(d2ptb, s);
      })();

      store.dispatch('d2ptoolbar/setInit', true);
    }
  }

  refresh() {
    this.interval = setInterval(() => {
      // periodically check if d2p toolbar div exists every 100ms (timer gets killed on init())
      const elemTB = document.getElementById(APP_CONFIG.d2pToolbar.divID);
      if (elemTB) {
        // destroy toolbar div
        elemTB.parentNode.removeChild(elemTB);

        // destroy hidden iframe
        const elemIframe = document.getElementById(APP_CONFIG.d2pToolbar.iframeID);
        if (elemIframe) elemIframe.parentNode.removeChild(elemIframe);

        // destroy toolbar <style> node
        const styleNodes = document.getElementsByTagName('style');
        const regex = new RegExp(APP_CONFIG.d2pToolbar.styleName);
        for (let i = 0; i < styleNodes.length; i += 1) {
          if (styleNodes[i].textContent.match(regex)) {
            styleNodes[i].parentNode.removeChild(styleNodes[i]);
            break;
          }
        }

        // re-initialize toolbar
        store.dispatch('d2ptoolbar/setInit', false);
        this.init();
      } else {
        this.numTries += 1;
        if (this.numTries >= this.maxTries) {
          // give up on looking for the toolbar; stop the interval loop
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    }, 100);
  }
}
