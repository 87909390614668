import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/actor/',
      name: 'actor',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "actor" */ './views/Actor.vue'),
    },
    {
      path: '/actress/',
      name: 'actress',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "actress" */ './views/Actress.vue'),
    },
    {
      path: '/agreement/',
      name: 'agreement',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "agreement" */ './views/Agreement.vue'),
    },
    {
      path: '/cancel/',
      name: 'cancel',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "cancel" */ './views/Cancel.vue'),
    },
    {
      path: '/category/',
      name: 'category',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "category" */ './views/Category.vue'),
    },
    {
      path: '/contact/',
      name: 'contact',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
    },
    {
      path: '/curated/',
      name: 'curated',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "curated" */ './views/Curated.vue'),
    },
    {
      path: '/ddl/',
      name: 'ddl',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ddl" */ './views/Ddl.vue'),
    },
    {
      path: '/downloadhistory/',
      name: 'downloadhistory',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "downloadhistory" */ './views/DownloadHistory.vue'),
    },
    {
      path: '/dml/',
      name: 'dml',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dml" */ './views/Dml.vue'),
    },
    {
      path: '/faq/',
      name: 'faq',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue'),
    },
    {
      path: '/freesample/',
      name: 'freesample',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "freesample" */ './views/FreeSample.vue'),
    },
    {
      path: '/help/',
      name: 'Help',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "help" */ './views/Help.vue'),
    },
    {
      path: '/highspeed/',
      name: 'highspeed',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "highspeed" */ './views/HighSpeed.vue'),
    },
    {
      path: '/human-rights-policies/',
      name: 'humanrightspolicies',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "humanrightspolicies" */ './views/HumanRightsPolicies.vue'),
    },
    {
      path: '/join/',
      name: 'join',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "join" */ './views/Join.vue'),
    },
    {
      path: '/limited/',
      name: 'limited',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "limited" */ './views/Limited.vue'),
    },
    {
      path: '/list/',
      name: 'movie-list',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "movieList" */ './views/MovieList.vue'),
    },
    {
      path: '/movies/:movieId/',
      name: 'movie-detail',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "movieDetail" */ './views/MovieDetail.vue'),
    },
    {
      path: '/newmovie/',
      name: 'new-movie',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "newmovie" */ './views/NewMovie.vue'),
    },
    {
      path: '/privacy/',
      name: 'privacy',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue'),
    },
    {
      path: '/playlists/',
      name: 'playlist-list',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "playlist" */ './views/Playlist.vue'),
    },
    {
      path: '/playlist/',
      name: 'playlist-detail',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "playlistDetail" */ './views/PlaylistDetail.vue'),
    },
    {
      path: '/ranking/',
      name: 'ranking',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ranking" */ './views/Ranking.vue'),
    },
    {
      path: '/search/',
      name: 'movie-search',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "movieList" */ './views/MovieList.vue'),
    },
    {
      path: '/series/',
      name: 'series',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "series" */ './views/Series.vue'),
    },
    {
      path: '/sitemap/',
      name: 'sitemap',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "sitemap" */ './views/Sitemap.vue'),
    },
    {
      path: '/2257/',
      name: '2257',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "2257" */ './views/2257.vue'),
    },
    {
      path: '/upgrade/',
      name: 'upgrade',
      pathToRegexpOptions: { strict: true }, // force trailing slash
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "upgrade" */ './views/Upgrade.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ selector: to.hash });
        } else if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 250);
    });
  },
});
