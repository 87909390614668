/* eslint no-console: 0 */

import store from '@/store';

export default class User {
  constructor() {
    this.init();
  }

  // TODO we might not need this service. could end up just being that we call the store actions to
  // initialize and refresh, which would be the only methods we would need here

  init() {
    // check if this service has been previously initialized. if so, then don't do this
    if (store.getters['dml/isInitialized']) return;

    console.log('%cInitializing DML service', 'background: #222; color: #ff0', this);
    store.dispatch('dml/setInit', true);
  }
}
