/* eslint no-console: 0 */

import Bifrost from '..';

export default class Survey {
  constructor() {
    this.appInfo = {
      id: 867,
      name: 'Survey',
    };

    this.bifrostSvc = new Bifrost();

    // handler for receiving non-reply messages sent from the backend for this app
    this.recvFn = (msg) => {
      switch (msg.MsgID) {
        // backend says there's a survey happening so raise the banner(s)
        case (3): {
          // Broadcast.send('enableSurvey', msg.Data);
          break;
        }

        default: {
          // unknown
          console.log('Unknown Survey message', msg);
        }
      }
    };

    this.bifrostSvc.registerAppListener(this.appInfo, this.recvFn);
  }
}
