/* eslint no-param-reassign: 0 */
import Cookies from 'js-cookie';
import APP_CONFIG from '../../appConfig';

const mutations = {
  SET_S2S(state, val) {
    state.s2sID = val;

    // spec is to save it for 31+ days, since Aff cookies are set for 30 days
    // ref: http://redmine.ops/issues/8562
    Cookies.set('s2s_id', val, { expires: 31, domain: APP_CONFIG.site.domain });
  },

  SET_UTM_SOURCE(state, val) {
    state.UTMSource = val;
    Cookies.set('s2s_utm_source', val, { expires: 31, domain: APP_CONFIG.site.domain });
  },

  SET_UTM_MEDIUM(state, val) {
    state.UTMMedium = val;
    Cookies.set('s2s_utm_medium', val, { expires: 31, domain: APP_CONFIG.site.domain });
  },

  SET_AFFI_PROMO(state, val) {
    state.affiPromo = val;
  },
};

export default mutations;
