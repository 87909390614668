/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  // Events initialized?
  eventsInitialized: false,

  volume: 100,
};

const getters = {
  eventsInitialized: state => state.eventsInitialized,
  volume: state => state.volume,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
