/* eslint no-console: 0 */
/* eslint class-methods-use-this: 0 */

const playerType = new Map();

export default class PlayerFactory {
  static register(name, implementation) {
    playerType.set(name, implementation);
    return PlayerFactory;
  }

  static create(name) {
    const createVideo = playerType.get(name);
    return createVideo();
  }

  register(name, implementation) {
    PlayerFactory.register(name, implementation);
    return this;
  }

  create(name) { return PlayerFactory.create(name); }
}
