/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

import INITIAL_STATE from './initialState';

const state = Object.assign({}, INITIAL_STATE);

const getters = {
  isInitialized: state => state.initialized,

  name: state => state.name,
  UID: state => state.UID,
  typeID: state => state.typeID,
  typeStr: state => state.typeStr,
  class: state => state.class,
  tier: state => state.tier,
  billing: state => state.billing,
  nickname: state => state.nickname,
  vcBalance: state => state.vcBalance,
  vcCoupon: state => state.vcCoupon,
  sessionId: state => state.sessionId,

  isRecurring: state => state.isRecurring,
  isAnnual: state => state.isAnnual,
  isFast: state => state.isFast,
  isLimited: state => state.isLimited,
  isBank: state => state.isBank,
  isTrial: state => state.isTrial,
  isLoggedIn: state => state.isLoggedIn,
  isSiteMember: state => state.isSiteMember,
  isStreaming: state => state.isStreaming,
  isDownload: state => state.isDownload,
  isAnon: state => state.isAnon,
  isClassicDL: state => state.isClassicDL,
  isNewXPEligible: state => state.isNewXPEligible,

  upgradeOptions: state => state.upgradeOptions,

  loginData: state => state.loginData,
  deferLogin: state => state.deferLogin,
  waitForLogin: state => state.waitForLogin,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
