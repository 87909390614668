/* eslint no-param-reassign: 0 */

import APP_CONFIG from '../../appConfig';

const mutations = {
  SET_INIT(state, bool) {
    state.initialized = bool;

    // d2 header is initialized, which means it's enabled; let's get the height and store it
    state.headerHeight = APP_CONFIG.d2pToolbar.headerHeight;
  },
};

export default mutations;
