<i18n>
{
  "en": {
    "id_userid": "User ID ",
    "rgt_email_address":"(Registered Email Address)",
    "pwd": "Password",
    "pw_retrieval": "Forgot password?",
    "login": "Login",
    "failed_login": "Incorect User Name or Password",
    "first_time_use": "Is this your first time using the site? ",
    "to_join": "Join"
  },
  "ja": {
    "id_userid": "ユーザーID ",
    "rgt_email_address":"（登録Eメールアドレス）",
    "pwd": "パスワード",
    "pw_retrieval": "パスワードを忘れた方はこちら",
    "login": "ログイン",
    "failed_login": "D2PassID、又はパスワードが違います",
    "first_time_use": "はじめてのご利用ですか？",
    "to_join": "入会案内はこちら"
  }
}
</i18n>

<template>
  <modal class="dl-modal dl-modal--login" name="ui-modal-login" :draggable="false" @before-open="beforeOpen" @before-close="beforeClose">
    <div class="login">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closeLogin()"></span>
      </div>
      <div class="login-section--brand">
        <img class="login-logo" src="/img/common/logo-1pondo-lg.svg" alt="">
      </div>
      <form name="login-form" class="login-section--form">
        <div class="login-form-title">{{ $t('id_userid') }}<span>{{ $t('rgt_email_address') }}</span></div>
        <input type="text" v-model="inputUser" id="loginUser" name="login" placeholder="" class="login-form-input">
        <div class="login-form-title">{{ $t('pwd') }}</div>
        <input type="password" v-model="inputPass" id="loginPass" name="password" @click="resetError()" @keypress.enter="login()" class="login-form-input">
        <div class="login-pw-retrieval">
          <a href="https://pw.allbrightinformation.com/" target="_blank" rel="noopener">{{ $t('pw_retrieval') }}</a>
        </div>
      </form>
      <div class="login-section--cta">
        <button :disabled="isDisabled" class="login-button button-fill button-default" @click="login()">{{ $t('login') }}</button>
        <div v-if="hasError" class="error">{{ $t('failed_login') }}</div>
      </div>
      <div class="login-signup">{{ $t('first_time_use') }} <router-link to="/join/">{{ $t('to_join') }} &raquo;</router-link></div>
    </div>
  </modal>
</template>

<script>
/* eslint max-len: 0 */
import User from '@/assets/js/services/User';
import EventBus from '@/assets/js/utils/EventBus';
import NetiHelper from '@/assets/js/utils/neti';
import Analytics from '@/assets/js/utils/Analytics';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  data() {
    return {
      inputUser: '',
      inputPass: '',
      hasError: false,
    };
  },
  methods: {
    login() {
      if (this.inputUser !== '' && this.inputPass !== '') {
        const userSvc = new User();
        userSvc.login(this.inputUserTrimmed, this.inputPass);
      }
    },
    resetError() {
      this.hasError = false;
    },
    closeLogin() {
      this.$modal.hide('ui-modal-login');

      // clear inputs so they don't stay populated after opening the login menu after logging out
      this.inputUser = '';
      this.inputPass = '';
    },
    beforeOpen() {
      // need to use the event bus here because there's no reply to listen to after submitting login
      EventBus.$on('login:success', () => {
        this.closeLogin();

        // on login success: send device info to backend for Neti
        // obscure valid login info so we're not logging it anywhere
        const deviceInfo = NetiHelper.getDeviceInfo();
        deviceInfo.username = '-';
        deviceInfo.password = '-';
        const bfAPI = new BifrostAPI();
        bfAPI.logToNeti('LOGIN_SUCCESS_DEVICE_INFO', deviceInfo);

        // send login custom event to GA4 (http://redmine.dl/issues/3383)
        setTimeout(() => { Analytics.sendLoginEvent(); }, 1500);
      });
      EventBus.$on('login:fail', () => {
        this.hasError = true;

        // on login fail: send device info to backend for Neti
        // for Risk, we're also attaching the user inputs they used for login
        const deviceInfo = NetiHelper.getDeviceInfo();
        deviceInfo.username = this.inputUserTrimmed;
        deviceInfo.password = this.inputPass;
        const bfAPI = new BifrostAPI();
        bfAPI.logToNeti('LOGIN_FAIL_DEVICE_INFO', deviceInfo);
      });
    },
    beforeClose() {
      // stop listening to login messages
      EventBus.$off('login:success');
      EventBus.$off('login:fail');
    },
    validInput(input) {
      // check to see if at least one character
      const re = /[A-Z]/i;
      return re.test(input);
    },
  },
  computed: {
    isDisabled() {
      return (!this.validInput(this.inputUser) || !this.validInput(this.inputPass));
    },
    inputUserTrimmed() {
      // remove whitespace from beginning and end of strings
      return this.inputUser.trim();
    },
  },
  watch: {
    // watch route change and close the modal when it changes
    // note: not using arrow functions here because I need to bind to 'this' and it's just easier
    // to just name the function to get around this limitation
    '$route.path': function watchRoutePath() {
      this.closeLogin();
    },
  },
};
</script>

<style lang="scss">
// for some reason needed to remove 'scope' from this style block and add this override class
// maybe after going to sass, this won't be needed? 🤷‍♂️
.v--modal-override {
  background-color: #000;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  padding: 0;
}
</style>
