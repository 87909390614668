<i18n>
{
  "en": {
    "btn_login": "Login",
    "btn_join": "Sign Up"
  },
  "ja": {
    "btn_login": "ログイン",
    "btn_join": "入会案内"
  }
}
</i18n>

<template>
<header>
<div class="header">
  <div class="header-brand">
    <label class="header-toggle" for="nav-toggle" @click="toggleNavMenu()">
      <span class="icon-menu"></span>
    </label>
    <div class="header-logo" @click="clickTrack('Header','Home');">
      <router-link to="/"><img src="/img/common/logo-1pondo.svg" alt=""></router-link>
    </div>
  </div>
  <div class="header-account--guest" v-show="!isLoggedIn">
    <button class="header-login button-fill button-default button-small" @click="showLogin();clickTrack('Header','Login');">{{ $t('btn_login') }}</button>
    <router-link class="header-join button button-ghost-fill button-default button-small" to="/join/" v-show="!isSiteMember" @click="clickTrack('Header','Guide Join');">{{ $t('btn_join') }}</router-link>
  </div>

  <div class="header-account--member" v-show="isLoggedIn">
    <div class="header-member" @click="toggleUserMenu();clickTrack('Header', 'Profile');"><svg class="tm-icon"><use xlink:href="#tm-icon-person"/></svg></div>
    <user-menu />
  </div>
</div>
</header>
</template>


<script>
/* eslint max-len: 0 */
/* eslint prefer-destructuring: 0 */

import User from '@/assets/js/services/User';
import userMenu from '@/components/_shell/menu/user/index.vue';
import EventBus from '@/assets/js/utils/EventBus';
import APP_CONFIG from '@/appConfig';

// minimum width required for always-visible search input to be visible by default
const SEARCH_MIN_WIDTH = 640;

export default {
  components: {
    'user-menu': userMenu,
  },
  data() {
    return {
      // submenu dropdowns
      dropdowns: {
        category: false,
        overflow: false,
      },
      // search
      searchQuery: '',
      searchHeaderVisible: false,
      searchAlwaysVisible: false,
      composing: false,
    };
  },
  mounted() {
    // set search input initial state
    this.setSearchVisibilityState();
    this.$nextTick(() => {
      window.addEventListener('resize', this.setSearchVisibilityState);
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    username() {
      return this.$store.getters['user/name'];
    },
    nickname() {
      return this.$store.getters['user/nickname'];
    },
    userType() {
      return this.$store.getters['user/typeStr'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    isAnnual() {
      return this.$store.getters['user/isAnnual'];
    },
  },
  methods: {
    headerFixer() {
      const page = document.getElementById(APP_CONFIG.appHeader.elemID);
      // fix header once the d2p toolbar has scrolled out of view 😬
      if (window.pageYOffset >= APP_CONFIG.d2pToolbar.headerHeight) {
        page.classList.add(APP_CONFIG.appHeader.fixedClass);
      } else {
        page.classList.remove(APP_CONFIG.appHeader.fixedClass);
      }
    },
    submitSearch(event) {
      // for Japanese IME if keycode is 229 it means "composing"
      if (event.keyCode === 229) {
        this.composing = true;
      }
      // only fire when NOT composing
      if (this.searchQuery !== '' || !this.composing || event.keyCode !== 229) {
        // reset state
        const searchQuery = this.searchQuery; // copy before we clear it
        this.searchQuery = ''; // now clear it

        // now go
        this.$router.push({ name: 'movie-search', query: { s: searchQuery } });
      }
    },
    showLogin() {
      this.$modal.show('ui-modal-login');
    },
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
    logout() {
      const userSvc = new User();
      userSvc.logout();
    },
    toggleNavMenu() {
      EventBus.$emit('navMenu:toggle', true);
    },
    toggleUserMenu() {
      EventBus.$emit('userMenu:toggle', true);
    },
    toggleDropdown(menuItem) {
      this.dropdowns[menuItem] = !this.dropdowns[menuItem];
      this.closeAllDropdowns(menuItem);
    },
    closeAllDropdowns(menuItem) {
      Object.keys(this.dropdowns).forEach((key) => {
        if (!menuItem || (menuItem && key !== menuItem)) {
          this.dropdowns[key] = false;
        }
      });
    },
    setSearchVisibilityState() {
      // we want the search input to always be visible when the display is wide enough
      this.searchAlwaysVisible = (window.innerWidth >= SEARCH_MIN_WIDTH);
    },
  },
  beforeMount() {
    if (APP_CONFIG.appHeader.fixed) window.addEventListener('scroll', this.headerFixer);
  },
  beforeDestroy() {
    if (APP_CONFIG.appHeader.fixed) window.removeEventListener('scroll', this.headerFixer);
    window.removeEventListener('resize', this.setSearchVisibilityState);
  },
  watch: {
    $route() {
      // watch route change and close all open submenus
      this.closeAllDropdowns();

      // watch route change and close the full-width search input
      if (!this.searchAlwaysVisible) this.searchHeaderVisible = false;
    },
  },
};
</script>

<style lang="scss">

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 400px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
