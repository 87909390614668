/* eslint no-console: 0 */
/* eslint max-len: 0 */

import Vue from 'vue';
import EventBus from '@/assets/js/utils/EventBus';
import APP_CONFIG from '@/appConfig';
import store from '@/store';
import Bifrost from '..';
import CORS from '../../../utils/CORS';

export default class Self {
  constructor() {
    this.appInfo = {
      id: 0,
      name: 'Self',
    };

    this.bifrostSvc = new Bifrost();

    // handler for receiving non-reply messages sent from the backend for this app
    this.recvFn = (msg) => {
      switch (msg.MsgID) {
        // Heartbeat request from backend. We need to send a pong back to the server
        case 0: {
          console.log('Ping? Pong!');
          this.bifrostSvc.send({
            AppID: this.appInfo.id,
            MsgID: 0,
            Data: msg.Data,
            noReply: true,
          });
          break;
        }

        // Received backend connection info
        case (266): {
          console.log('Received connection type info', msg.Data.Con);
          // set the GA custom dimension with the connection type
          if (msg.Data.Con) {
            Vue.analytics.injectGlobalDimension(APP_CONFIG.analytics.customDimensions.connectionType, msg.Data.Con, false);
          }
          break;
        }

        // we received a connectUrl on backend; save it to store
        case (467): {
          console.log('Received reconnect URL', msg.Data.WS);
          store.dispatch('vws/setWebsocketUrl', msg.Data.WS);

          // save session ID in User store
          const wsUrlParse = msg.Data.WS.split('/');
          const sessionID = wsUrlParse[4];
          console.log('WS session ID', sessionID);

          if (store.getters['user/sessionId']) {
            // 1st time session received? emit that it was set
            EventBus.$emit('sessionID:set', sessionID);
          }

          store.dispatch('user/setSessionId', sessionID);
          EventBus.$emit('sessionID:received', sessionID);
          break;
        }

        // received POST URL -- make AJAX call
        case (2255): {
          console.log('Got a AJAX URL to call', msg.Data.URL);
          const CORSSvc = new CORS(msg.Data.URL);
          CORSSvc.createRequest('POST');

          // save session ID in User store. the use case when this happens is
          // if you're logged in, log out, and log back in again. the above
          // 467 message isn't sent, but 2255 is
          const wsUrlParse = msg.Data.URL.split('/');
          const sessionID = wsUrlParse[5];
          console.log('WS session ID', sessionID);

          if (store.getters['user/sessionId']) {
            // 1st time session received? emit that it was set
            EventBus.$emit('sessionID:set', sessionID);
          }
          store.dispatch('user/setSessionId', sessionID);
          EventBus.$emit('sessionID:received', sessionID);
          break;
        }

        // received SQA message -- emit eventbus message for the SQA modal
        case (2311): {
          console.log('Received SQA message', msg.Data);
          break;
        }

        // unknown message
        default: {
          console.log(`Unknown ${this.appInfo.name} message`, msg);
        }
      }
    };

    this.bifrostSvc.registerAppListener(this.appInfo, this.recvFn);
  }
}
