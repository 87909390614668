/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-bitwise: 0 */
/* eslint no-mixed-operators: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint max-len: 0 */
/* eslint no-unneeded-ternary: 0 */

import INITIAL_STATE from './initialState';
import APP_CONFIG from '../../appConfig';

const mutations = {
  SET_INIT(state) {
    state.initialized = true;
  },

  SET_FLAGS(state, payload) {
    // set User object properties from the flags
    state.typeStr = (payload.Flags & APP_CONFIG.user.flagBits.DTI) ? 'DTI' : null;
    state.isRecurring = (payload.Flags & APP_CONFIG.user.flagBits.Recurring) ? true : false;
    state.isAnnual = (payload.Flags & APP_CONFIG.user.flagBits.Annual) ? true : false;
    state.isFast = (payload.Flags & APP_CONFIG.user.flagBits.Fast) ? true : false;
    state.isLimited = (payload.Flags & APP_CONFIG.user.flagBits.Limited) ? true : false;

    if (APP_CONFIG.user.propMap[payload.Type].type) {
      state.typeID = payload.Type;
      state.typeStr = APP_CONFIG.user.propMap[payload.Type].type;
      state.class = APP_CONFIG.user.propMap[payload.Type].class;
      state.tier = APP_CONFIG.user.propMap[payload.Type].tier;
      state.isStreaming = (APP_CONFIG.user.propMap[payload.Type].class === 'Streaming');
      state.isDownload = (APP_CONFIG.user.propMap[payload.Type].class === 'Download');

      // new flags added for 'DL elimination' project
      // 2022-12-22 r3527 users who upgrade from DL to annual streaming end up in a weird state
      // where they look like a DL user (no "new experience") but have an "AYCS" tier, due to
      // neti tags lingering 6 months after the original DL's expiring date. so we can check for
      // their AYCS tier and give them the new experience too
      state.isClassicDL = (Object.prototype.hasOwnProperty.call(payload, 'IsClassicDL') && payload.IsClassicDL === true);
      state.isNewXPEligible = ((process.env.VUE_APP_NEW_XP_ENABLED === 'true') && !state.isClassicDL) || payload.Type === 200;
    }

    if (state.typeStr) {
      state.name = payload.User;
      state.UID = payload.UID;
      state.isLoggedIn = true;

      // making this available to unclutter the templates + js, now that
      // you can be logged in, but still a "guest" (aka non-member). so
      // we need to switch over logic where we check for "User.isLoggedIn"
      // since we can't guarantee that this means the user is a member
      state.isSiteMember = (state.isLoggedIn && state.typeID > 0);
      state.isAnnual = (state.isLoggedIn && state.typeID <= 20) ? false : state.isAnnual;

      // so we know we've logged in before
      if (!state.loginData) state.loginData = payload;
    }
  },

  RESOLVE_LOGIN(state) {
    state.deferLogin.resolve(true);
  },

  SET_UPGRADE_OPTIONS(state) {
    // as of REUP 10m, the properties set by this block aren't being used. these were needed a
    // long time ago back when 1pondo had upgrade links right in the user menu, which have been
    // removed since the vuejs refactor.
    // TODO update this logic for streaming packages when needed and time is on my side
    for (const i in APP_CONFIG.user.typeMap) {
      if (Object.prototype.hasOwnProperty.call(APP_CONFIG.user.typeMap, i)) {
        if (!state.isLoggedIn) {
          // guests can upgrade to everything :)
          state.upgradeOptions[APP_CONFIG.user.typeMap[i]] = true;
        } else if (state.isTrial) {
          // for this bit of logic, trial users are the same level as
          // streaming users (type 20)
          state.upgradeOptions[APP_CONFIG.user.typeMap[i]] = (i > 20);
        } else if (state.isBank) {
          // bank join users can't upgrade
          state.upgradeOptions[APP_CONFIG.user.typeMap[i]] = false;
        } else if (state.isAnnual) {
          // annual users don't need upgrade
          state.upgradeOptions[APP_CONFIG.user.typeMap[i]] = false;
        } else {
          // everyone else can only upgrade to higher-tier memberships
          state.upgradeOptions[APP_CONFIG.user.typeMap[i]] = (state.typeID < i);
        }
      }
    }
  },

  SET_BILLING(state, payload) {
    if (payload.EndDate) {
      const date = new Date(payload.EndDate * 1000);

      payload.EndDateParsed = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      };
    }

    // set billing-related User properties
    state.billing = payload;
    state.isBank = payload.IsBank;
    state.isRecurring = payload.IsRecurring;

    // trial users if their Start and End dates fall below the threshold
    if (state.userType !== 'DTI' && state.userType !== 'NonMember' && APP_CONFIG.user.trialHoursThreshold) {
      state.isTrial = (state.billing.EndDate - state.billing.StartDate < APP_CONFIG.user.trialHoursThreshold * 3600);
    }
  },

  SET_NICKNAME(state, payload) {
    state.nickname = payload.Name;
    state.isAnon = payload.Anon;
  },

  SET_VC_POINTS(state, payload) {
    if (Object.prototype.hasOwnProperty.call(payload, 'Balance')) state.vcBalance = payload.Balance;
    if (Object.prototype.hasOwnProperty.call(payload, 'Coupon')) state.vcCoupon = payload.Coupon;
  },

  SET_SESSION_ID(state, value) {
    state.sessionId = value;
  },

  RESET_STATE(state) {
    for (const prop in state) {
      if (Object.prototype.hasOwnProperty.call(state, prop)) state[prop] = INITIAL_STATE[prop];
    }
  },
};

export default mutations;
