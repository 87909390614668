/* eslint no-console: 0 */
/* eslint class-methods-use-this: 0 */

import UAParser from 'ua-parser-js';
import Cookies from 'js-cookie';
import Bifrost from '..';
import User from '../../User';
import EventBus from '../../../utils/EventBus';
import APP_CONFIG from '@/appConfig';

export default class Self {
  constructor() {
    this.appInfo = {
      id: 10,
      name: 'Login',
    };

    this.bifrostSvc = new Bifrost();

    // handler for receiving non-reply messages sent from the backend for this app
    this.recvFn = (msg) => {
      switch (msg.MsgID) {
        // received message to set cookie
        case (3): {
          this.setCookie(msg.Data);
          break;
        }

        // received message that login was successful
        case (6): {
          if (msg.Cookie !== '') this.setCookie(msg.Data);

          // update User
          const userSvc = new User();
          userSvc.loginPostflight(msg.Data);
          EventBus.$emit('login:success', msg.Data);
          break;
        }

        // received message that login failed
        case (8): {
          EventBus.$emit('login:fail', msg.Data);
          break;
        }

        // unknown message
        default: {
          console.log(`Unknown ${this.appInfo.name} message`, msg);
        }
      }
    };

    this.sendCallback = () => { };

    this.bifrostSvc.registerAppListener(this.appInfo, this.recvFn);
  }

  setCookie(data) {
    const Expires = parseInt(data.Expires, 10);
    const exptime = new Date();
    exptime.setTime(new Date().getTime() + Expires);

    const options = {
      expires: exptime,
      domain: APP_CONFIG.site.domain,
    };

    const parser = new UAParser();
    const ua = parser.getResult();

    // several older browsers aren't aware of `SameSite=None` and treat it as `SameSite=Strict`
    // which is the opposite of what's intended; so going the safe route and _only_ setting SameSite
    // for browsers that support it correctly, which as of now is Chrome 80
    // ref: https://www.chromium.org/updates/same-site/incompatible-clients
    // ref: https://bugs.webkit.org/show_bug.cgi?id=198181
    let USE_SAMESITE = false;
    if (ua.browser.name === 'Chrome' && parseInt(ua.browser.major, 10) >= 80) USE_SAMESITE = true;
    if (USE_SAMESITE) {
      options.sameSite = 'none';
      options.secure = true;
    }

    Cookies.set('NetiA', data.Cookie, options);
  }

  deleteCookie() {
    Cookies.remove('NetiA', { domain: APP_CONFIG.site.domain });
  }
}
