/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  initialized: false, // checked by the DML service so it only initializes once

  canDownload: true,
  slotsUsed: 0,
  slotsFree: 0,

  failsafe: false,
};

const getters = {
  isInitialized: state => state.initialized,

  canDownload: state => state.canDownload,
  slotsUsed: state => state.slotsUsed,
  slotsFree: state => state.slotsFree,

  failsafe: state => state.failsafe,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
