<i18n>
{
  "en": {
    "browser_title": "Home"
  },
  "ja": {
    "browser_title": "トップページ"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />

    <hero-image />

    <div class="contents">
      <section class="section divider" v-if="staffPicksEnabled && hasStaffPicks">
        <staff-picks
          thumb-type="jacket"
          custom-list-id="1607972271683-ae309034-a4c4"
          @staffPicksEval="staffPicksEval" />
      </section>

      <section class="section divider">
        <movie-list :num-movies="newMovieListSize" />
      </section>
      <section class="section divider" v-if="eventsEnabled && hasEvents">
        <event-list @eventsEval="eventsEval" />
      </section>

      <section class="section divider" v-if="recommendedCategories && hasCategories">
        <recommended-categories
          custom-list-id="1591829428134-29d5f5b8-d6c6"
          :movies-per-tab="recommendedCategoriesMovieListSize"
          @categoriesEval="categoriesEval" />
      </section>

      <section class="section divider" v-if="recommendedActresses && hasActresses">
        <actresses-list
          custom-list-id="1591829700541-1b994fcc-7c1a"
          @actressesEval="actressesEval" />
      </section>

      <span v-if="recommendedSites">
        <section class="section">
          <site-movie-list site-name="carib" :num-movies="recommendedSitesMovieListSize" :movie-offset="recommendedSitesOffset" />
        </section>
        <section class="section">
          <site-movie-list site-name="10m" :num-movies="recommendedSitesMovieListSize" :movie-offset="recommendedSitesOffset" />
        </section>
        <section class="section">
          <site-movie-list site-name="paco" :num-movies="recommendedSitesMovieListSize" :movie-offset="recommendedSitesOffset" />
        </section>
      </span>
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */

// @ is an alias to /src
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';

import SiteConfig from '@/assets/js/utils/SiteConfig';
import lazyLoadComponent from '@/assets/js/utils/lazyLoadComponent';
import SkeletonBox from '@/components/_shell/SkeletonBox.vue';

import HeroImage from '@/components/home/heroImage.vue';
import MovieList from '@/components/home/movieList.vue';

const skeletonOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: '100%',
      height: '20em',
    },
  },
};

export default {
  name: 'Home',

  components: {
    'vue-headful': vueHeadful,
    'hero-image': HeroImage,
    'movie-list': MovieList,
    'event-list': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/eventList.vue'),
    }),
    'actresses-list': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/actressesList.vue'),
    }),
    'recommended-categories': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/recommendedCategories.vue'),
    }),
    'site-movie-list': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/siteMovieList.vue'),
    }),
    'staff-picks': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/staffPicks.vue'),
    }),
  },
  data() {
    return {
      config: {},
      hasEvents: true,
      hasCategories: true,
      hasActresses: true,
      hasStaffPicks: true,
      dynamicTitle: '',
    };
  },

  async created() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    // get site config (settings from admin)
    this.config = await SiteConfig.getConfig('home');
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    // set default site configs below, in case the app fails to fetch the config json file
    newMovieListSize() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'newMovieListSize') ? this.config.newMovieListSize : 12);
    },
    eventsEnabled() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'eventsEnabled') ? this.config.eventsEnabled : true);
    },
    staffPicksEnabled() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'staffPicksEnabled') ? this.config.staffPicksEnabled : true);
    },
    recommendedCategories() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedCategories') ? this.config.recommendedCategories : true);
    },
    recommendedCategoriesMovieListSize() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedCategoriesMovieListSize') ? this.config.recommendedCategoriesMovieListSize : 6);
    },
    recommendedActresses() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedActresses') ? this.config.recommendedActresses : true);
    },
    recommendedSites() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedSites') ? this.config.recommendedSites : true);
    },
    recommendedSitesMovieListSize() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedSitesMovieListSize') ? this.config.recommendedSitesMovieListSize : 20);
    },
    recommendedSitesOffset() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedSitesOffset') ? this.config.recommendedSitesOffset : 0);
    },
  },

  methods: {
    eventsEval(value) {
      this.hasEvents = value;
    },
    categoriesEval(value) {
      this.hasCategories = value;
    },
    actressesEval(value) {
      this.hasActresses = value;
    },
    staffPicksEval(value) {
      this.hasStaffPicks = value;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_home.scss';
</style>
