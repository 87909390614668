const actions = {
  checkEnabled(context) {
    context.commit('SET_ENABLED', true);
  },
  setStarted(context, bool) {
    context.commit('SET_STARTED', bool);
  },
  setInit(context, bool) {
    context.commit('SET_INIT', bool);
  },
  setReady(context, bool) {
    context.commit('SET_READY', bool);
  },
  setLeftCount(context, val) {
    context.commit('SET_LEFT_COUNT', val);
  },
  setMaxCount(context, val) {
    context.commit('SET_MAX_COUNT', val);
  },
  setToken(context, val) {
    context.commit('SET_TOKEN', val);
  },
  setDirtyCache(context, bool) {
    context.commit('SET_DIRTY_CACHE', bool);
  },
};

export default actions;
