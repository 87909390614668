const actions = {
  setInit(context) {
    context.commit('SET_INIT');
  },

  // the app shouldn't set any DML vuex store values -- these values should only be set by data from
  // the API; so there's only 1 action here, and that's to get DML data (refresh)
  refreshStatus(context, movieID) {
    context.commit('REFRESH_STATUS', movieID);
  },
};

export default actions;
