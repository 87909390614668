/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  initialized: false,

  // Registered backend app handlers
  registeredApps: {},

  // The RequestID we communicate with
  requestID: 1,

  // Current PageID (gets incremented whenever the user goes to a different route)
  pageID: 0,

  // All pending websocket requests (keyed by request id)
  requests: {},

  // Map of { md5'd request : [request IDs] }
  pendingCacheMap: {},

  // All pending non-reply requests (keyed by cache key, because all non-reply request ids are 0)
  nonReplyRequests: {},

  // Venus RequestID
  venusReqID: 1,

  // All pending Venus requests (keyed by venus request id)
  venusRequests: {},

  // Map of { md5'd request : [request IDs] }
  venusPendingCacheMap: {},

  // Seed (for random lists, sent by the backend and saved on our side)
  randomSeed: null,

  // for spinner (increments on backend requests and fetching JSONs)
  waitingCount: 0,
};

const getters = {
  isInitialized: state => state.initialized,
  registeredApps: state => state.registeredApps,
  requestID: state => state.requestID,
  pageID: state => state.pageID,
  requests: state => state.requests,
  pendingCacheMap: state => state.pendingCacheMap,
  nonReplyRequests: state => state.nonReplyRequests,
  venusReqID: state => state.venusReqID,
  venusRequests: state => state.venusRequests,
  venusPendingCacheMap: state => state.venusPendingCacheMap,
  randomSeed: state => state.randomSeed,
  waitingCount: state => state.waitingCount,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
