/* eslint no-console: 0 */

import Bifrost from '..';
import User from '../../User';
import EventBus from '../../../utils/EventBus';
import APP_CONFIG from '@/appConfig';

export default class Self {
  constructor() {
    this.appInfo = {
      id: 30,
      name: 'D2P',
    };

    this.bifrostSvc = new Bifrost();

    // handler for receiving non-reply messages sent from the backend for this app
    this.recvFn = (msg) => {
      switch (msg.MsgID) {
        case (1): {
          // update User
          const userSvc = new User();
          if (Object.prototype.hasOwnProperty.call(msg.Data, 'Billing')) userSvc.setBilling(msg.Data.Billing);
          if (Object.prototype.hasOwnProperty.call(msg.Data, 'Nick')) userSvc.setNickname(msg.Data.Nick);
          if (Object.prototype.hasOwnProperty.call(msg.Data, 'VChat')) userSvc.setVCPoints(msg.Data.VChat);

          // show vchat coupon notification if the user has one (check vc api `code` value for 0)
          if (Object.prototype.hasOwnProperty.call(msg.Data, 'VChatIssueCoupon')) {
            // checking if the result isn't cached on the backend, so we can suppress cached
            // successful notifications and only show it the 1st time
            if (msg.Data.VChatIssueCoupon.Code === 0
              && msg.Data.VChatIssueCoupon.Cached === false
              && window.location.hostname.match(APP_CONFIG.site.domain)) {
              EventBus.$emit('notification:event:vchat:apiCouponIssue:success', true);
            }
          }

          // r2704: show d2 recurring coupon notification if the user has one
          if (Object.prototype.hasOwnProperty.call(msg.Data, 'RecurringCoupon')) {
            if (Object.prototype.hasOwnProperty.call(msg.Data.RecurringCoupon, 'Code')
              && Object.prototype.hasOwnProperty.call(msg.Data.RecurringCoupon, 'DiscountAmount')
              && msg.Data.RecurringCoupon.Code
              && msg.Data.RecurringCoupon.DiscountAmount
              && window.location.hostname.match(APP_CONFIG.site.domain)) {
              EventBus.$emit('notification:event:d2:apiRecurringCouponIssue:success', true);
            }
          }

          // r2953: show d2 amenity points notification if the user has some available
          if (Object.prototype.hasOwnProperty.call(msg.Data, 'Amenity')) {
            if (msg.Data.Amenity.Status === 1
              && msg.Data.Amenity.HasAmenity === true
              && window.location.hostname.match(APP_CONFIG.site.domain)) {
              EventBus.$emit('notification:event:d2:apiAmenityAvailable:true', true);
            }
          }

          break;
        }

        // unknown message
        default: {
          console.log(`Unknown ${this.appInfo.name} message`, msg);
        }
      }
    };

    this.bifrostSvc.registerAppListener(this.appInfo, this.recvFn);
  }
}
