/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  // initialized
  initialized: false,

  // d2 header height; we know the height, but can be disabled per subdomain (english), so first we
  // check if d2 header is enabled, then set the height (from appConfig) here
  headerHeight: 0,
};

const getters = {
  initialized: state => state.initialized,
  headerHeight: state => state.headerHeight,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
