/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-undef: 0 */

import Vue from 'vue';
import store from '@/store';
import EventBus from '../../utils/EventBus';
import Analytics from '../../utils/Analytics';
import PlayerCfg from './config';
import Player from './Player';

export default class VideoJS extends Player {
  constructor(playerElement, movieData, userData, locale, posterEnEpochStart) {
    super(`videojs-${movieData.MovieID}`, movieData, userData);
    super.initPlayer(this.videojsSetup);
    this.player = null;
    this.playerElement = playerElement;
    this.offsetMarker = 0;
    this.canPlay = false;
    this.locale = locale;
    this.checkDate = posterEnEpochStart; // optional check for setting movie poster
  }

  // set videojs sources (poster + videos)
  setupSources() {
    // set poster
    let moviePosterLocale = 'ja';
    if (this.locale !== 'ja' && this.checkDate) {
      // if locale is non-japanese, check date and only use "english" thumbs if after a specific date
      const releaseEpoch = Date.parse(this.movieDetails.Release) / 1000;
      if (releaseEpoch >= Number(this.checkDate)) moviePosterLocale = 'en';
    }
    // for older movie poster, str.jpg is not available, use d_b.jpg instead
    let poster = (this.movieDetails.ThumbHigh.indexOf('str') >= 0) ? PlayerCfg.moviePoster[moviePosterLocale].imgUrl : PlayerCfg.moviePoster[moviePosterLocale].imgUrl.replace('str', 'd_b');
    poster = poster.replace(/\{MOVIE_ID\}/g, this.movieDetails[PlayerCfg.moviePoster[moviePosterLocale].movieIdKey]);
    this.player.poster(poster);

    // set movie sources
    this.movieSources.reverse();
    const movieSources = [];
    for (let i = 0; i < this.movieSources.length; i += 1) {
      movieSources.push({
        src: this.movieSources[i].file,
        type: 'application/x-mpegURL',
        label: this.movieSources[i].label,
      });
    }
    this.player.src(movieSources);
  }

  setupEvents() {
    const thisPlayer = this;

    this.player.on('playing', () => {
      // sales wanted different play events, so we check the 'fullplay' player prop
      if (thisPlayer.fullPlay.enabled) {
        Vue.analytics.trackEvent('Video', 'Play Member Movie', this.movieDetails.MovieID);
      } else {
        Vue.analytics.trackEvent('Video', 'Play Sample Movie', this.movieDetails.MovieID);

        // so we can limit sample playbacks
        store.dispatch('prefs/setPlayedSamples', { MovieID: this.movieDetails.MovieID });
      }
    });

    this.player.on('canplay', () => {
      // setting a prop so we can check for quality levels changing
      thisPlayer.canPlay = true;
    });

    this.player.on('loadedmetadata', () => {
      // detect user selecting a different quality by checking for "loadedmetadata" event, but also
      // need to check the "canPlay" property (set in the 'canplay' event handler) because
      // "loadedmeta" also happens on initial videoplayer load
      if (thisPlayer.canPlay) Vue.analytics.trackEvent('Video', 'Video Quality Button');
    });

    this.player.on('error', () => {
      Vue.analytics.trackEvent('Video', 'Error', null, { nonInteraction: true });
    });

    EventBus.$on('videoplayer:event:reqPlayerTime', () => {
      EventBus.$emit('videoplayer:event:timestampReply', {
        movieId: this.movieDetails.MovieID,
        currentTime: this.player.currentTime(),
      });
    });

    EventBus.$on('videoplayer:event:setPlayerTime', (seconds) => {
      this.player.currentTime(seconds);
    });

    // GA4 events
    const movieType = (thisPlayer.fullPlay.enabled) ? 'Member Movie' : 'Sample Movie';
    let percentPlayed = 0;
    let percentage = ''; // ${percentPlayed}% (string data used for custom event param)
    let pctParam = '';
    let isMovieStarted = false;

    // event: movie_start
    // 'play': triggered whenever a play event happens; indicates that playback has started or resumed
    this.player.on('play', () => {
      const currentTime = Math.round(this.player.currentTime());
      percentPlayed = parseInt(currentTime / this.movieDetails.Duration * 100, 10);
      percentage = `${percentPlayed}%`;

      // only send event on the first time play - due to the use of chapters, video can be started at any point,
      // set 'isMovieStarted' to true when 'play' event is triggered the first time
      // if video starts with currentTime = 0, 'pct_0' param is included
      if (currentTime === 0 && !isMovieStarted) {
        if (movieType === 'Member Movie') {
          Analytics.sendCustomEvent('movie_start', {
            movie_duration: this.movieDetails.Duration,
            movie_percent: '0%',
            movie_title: this.movieDetails.Title,
            movie_id: this.movieDetails.MovieID,
            actress_name: this.movieDetails.Actor,
            movie_type: movieType,
            pct_0: 1,
          });
        } else if (movieType === 'Sample Movie') {
          Analytics.sendCustomEvent('movie_start', {
            movie_title: this.movieDetails.Title,
            movie_id: this.movieDetails.MovieID,
            actress_name: this.movieDetails.Actor,
            movie_type: movieType,
            pct_0: 1,
          });
        }
      } else if (!isMovieStarted) {
        if (movieType === 'Member Movie') {
          Analytics.sendCustomEvent('movie_start', {
            movie_duration: this.movieDetails.Duration,
            movie_percent: percentage,
            movie_title: this.movieDetails.Title,
            movie_id: this.movieDetails.MovieID,
            actress_name: this.movieDetails.Actor,
            movie_type: movieType,
          });
        } else if (movieType === 'Sample Movie') {
          Analytics.sendCustomEvent('movie_start', {
            movie_title: this.movieDetails.Title,
            movie_id: this.movieDetails.MovieID,
            actress_name: this.movieDetails.Actor,
            movie_type: movieType,
          });
        }
      }
      isMovieStarted = true;
    });

    // event: movie_complete
    // 'ended': fired when the end of the media resource is reached (currentTime == duration)
    this.player.on('ended', () => {
      const currentTime = Math.round(this.player.currentTime());
      percentPlayed = parseInt(currentTime / this.movieDetails.Duration * 100, 10);

      if (isMovieStarted) {
        // member: send 'movie_complete' event, reset 'isMovieStarted' flag
        // sample: reset 'isMovieStarted' flag only (needed for replay)
        if (movieType === 'Member Movie') {
          Analytics.sendCustomEvent('movie_complete', {
            movie_duration: this.movieDetails.Duration,
            movie_percent: '100%',
            movie_title: this.movieDetails.Title,
            movie_id: this.movieDetails.MovieID,
            actress_name: this.movieDetails.Actor,
            movie_type: movieType,
            pct_100: 1,
          });
        }
        // reset back to false (end of video sequence, 'movie_start' event can be sent again when replay)
        isMovieStarted = false;
      }
    });

    if (thisPlayer.fullPlay.enabled) {
      const percentMarkers = [90, 75, 50, 25, 10]; // percentage markers
      const tsMarkers = []; // converted timestamp markers
      let playedMarker = ''; // the most recent triggered marker

      for (let i = 0; i < percentMarkers.length; i += 1) {
        const ts = Math.ceil(this.movieDetails.Duration * percentMarkers[i] / 100);
        tsMarkers.push(ts);
      }

      // event: movie_progress
      // 'timeupate': fires when the current playback position has changed
      this.player.on('timeupdate', () => {
        let canSendEvent = false;
        const currentTime = Math.round(this.player.currentTime());
        percentPlayed = parseInt(currentTime / this.movieDetails.Duration * 100, 10);
        percentage = `${percentPlayed}%`;
        pctParam = `pct_${percentPlayed}`;

        // if 'currentTime' does not match the saved 'playedMarker', reset 'playedMarker'
        // so that the same timestamp can be triggered again if user goes back and replay the same point
        if (currentTime !== playedMarker) {
          canSendEvent = true;
          playedMarker = '';
        }

        if (tsMarkers.includes(currentTime) && canSendEvent) {
          Analytics.sendCustomEvent('movie_progress', {
            movie_duration: this.movieDetails.Duration,
            movie_percent: percentage,
            movie_title: this.movieDetails.Title,
            movie_id: this.movieDetails.MovieID,
            actress_name: this.movieDetails.Actor,
            movie_type: movieType,
            [pctParam]: 1,
          });

          playedMarker = currentTime;
        }
      });
    }
  }

  // set player options and initialize
  videojsSetup() {
    const options = {
      controls: true,
      autoplay: false,
      preload: 'auto',
      width: '100%',
      height: '100%',
      fluid: true,
      playbackRates: [0.5, 1, 1.5, 2],
      controlBar: {
        playToggle: true,
        progressControl: true,
        volumePanel: true,
        qualitySelector: true,
        fullscreenToggle: true,
      },
      plugins: {
        persistvolume: {
          namespace: 'reup-vue',
        },
      },
    };

    // making a constant here because 'this' isn't what you think it is in the playerReady() function
    const MOVIE_ID = this.movieId;
    const FULL_PLAY_ENABLED = (this.fullPlay.enabled);

    // initialize videojs player
    this.player = videojs(this.playerElement, options, function playerReady() {
      setTimeout(() => {
        // load vtt thumbnail plugin if playing member movie
        // 2022-04-18: adding a delay here which seems to make the timeslider work without having to
        // do the hacky loading of `this.playerInstanceFactory(this.movieDetail)` twice in the
        // videoPlayer.vue component which has the bad side-effect of the videoplayer events for
        // analytics being triggered twice for a single user interaction (ex clicking on play)
        if (FULL_PLAY_ENABLED) {
          this.thumbnails({
            width: 162,
            height: 90,
            basePath: `/assets/ts/${MOVIE_ID}/`,
          });
        }

        this.seekButtons({
          forward: (FULL_PLAY_ENABLED) ? 30 : 10,
          back: (FULL_PLAY_ENABLED) ? 10 : 5,
        });
      }, 50); // 0 is enough to work, but totally removing setTimeout won't work. so leaving at 50
    });

    this.setupSources();
    this.setupEvents();

    console.log(`%cinitPlayer videoJs ${(this.fullPlay.enabled) ? 'Member' : 'Sample'}`, 'background-color:#60c; color:#fff', this.player);
  }

  // this is called by the videoplayer components beforeDestroy() method
  remove() {
    this.player.dispose();
  }
}
