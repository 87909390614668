/* eslint no-param-reassign: 0 */
/* eslint max-len: 0 */

const mutations = {
  ENABLE_PLAYLIST_FEATURE(state) {
    state.enabled = true;
  },

  ENABLE_CUSTOM_PLAYLISTS(state) {
    state.custom_enabled = true;
  },

  ENABLE_PUBLIC_PLAYLISTS(state) {
    state.public_enabled = true;
  },

  DISABLE_PLAYLIST_FEATURE(state) {
    state.enabled = false;
  },

  DISABLE_CUSTOM_PLAYLISTS(state) {
    state.custom_enabled = false;
  },

  DISABLE_PUBLIC_PLAYLISTS(state) {
    state.public_enabled = false;
  },

  SET_LIMITS(state, userTypeID) {
    // we can do different playlists limits per tier, but decided to keep it simple and give
    // everyone the same limit.but leaving the logic here in case
    const SVIP_MAX = 20;
    const VIP_MAX = 20;
    const REGULAR_MAX = 20;
    const STREAMING_MAX = 20;
    if (userTypeID >= 79) {
      // SVIP
      state.custom_limit = SVIP_MAX;
    } else if (userTypeID >= 59) {
      // VIP
      state.custom_limit = VIP_MAX;
    } else if (userTypeID >= 20) {
      // Regular
      state.custom_limit = REGULAR_MAX;
    } else if (userTypeID > 0) {
      // Streaming
      state.custom_limit = STREAMING_MAX;
    } else {
      // Non-member
      state.custom_limit = 0;
    }
  },

  RESET_LIMITS(state) {
    state.custom_limit = 0;
  },

  SET_DEFAULT_PLAYLIST_IDS(state, payload) {
    if (Object.prototype.hasOwnProperty.call(state.defaultPlaylistIDs, payload.playlistName)) {
      state.defaultPlaylistIDs[payload.playlistName] = payload.playlistID;
    }
  },

  RESET_DEFAULT_PLAYLIST_IDS(state) {
    Object.keys(state.defaultPlaylistIDs).forEach((name) => {
      state.defaultPlaylistIDs[name] = null;
    });
  },
};

export default mutations;
