<i18n>
{
  "en": {
    "site_guide": "Site Guide",
    "home": "Home",
    "popular_movie_ranking": "Popular Movie Ranking",
    "free_sample_movies": "Free Sample Movies",
    "sign_up": "Sign Up",
    "upgrade": "Upgrade",
    "access_ranking": "Access Ranking",
    "adult_affiliate": "Adult Affiliate",
    "movies": "Movies",
    "all_movies_list": "All Movies",
    "categories": "Categories",
    "series": "Series",
    "vip_movies": "VIP Movies",
    "svip_movies": "SVIP Movies",
    "annual_movies": "Annual Movies",
    "help": "Help",
    "faq": "FAQ",
    "contact": "Contact",
    "site_map": "Sitemap",
    "content_removal": "Content Removal",
    "agreement": "Agreement",
    "privacy": "Privacy",
    "recommended_sites": "Recommended Sites",
    "site_carib": "Caribbeancom",
    "site_caribpr": "Caribbeancom Premium",
    "site_dxlive": "DXLIVE",
    "site_paco": "Pacopacomama",
    "site_10m": "10musume",
    "site_heyzo": "HEYZO"
  },
  "ja": {
    "site_guide": "サイトガイド",
    "home": "ホーム",
    "popular_movie_ranking": "人気ランキング",
    "free_sample_movies": "無料動画",
    "sign_up": "入会案内",
    "upgrade": "アップグレード案内",
    "access_ranking": "アクセスランキング",
    "adult_affiliate": "アダルトアフィリエイト",
    "movies": "動画",
    "all_movies_list": "全動画リスト",
    "categories": "カテゴリー",
    "series": "シリーズ",
    "vip_movies": "VIP動画",
    "svip_movies": "超VIP動画",
    "annual_movies": "年間動画",
    "help": "ヘルプ",
    "faq": "FAQ / よくある質問",
    "contact": "お問い合わせ",
    "site_map": "サイトマップ",
    "content_removal": "削除依頼",
    "agreement": "利用規約",
    "privacy": "プライバシー",
    "recommended_sites": "おすすめサイト",
    "site_carib": "カリビアンコム",
    "site_caribpr": "カリビアンコムプレミアム",
    "site_dxlive": "DXLIVE",
    "site_paco": "パコパコママ",
    "site_10m": "天然むすめ",
    "site_heyzo": "HEYZO"
  }
}
</i18n>

<template>
<footer>
<div class="contents">
  <div class="section footer-main">
    <div class="footer__menu">
      <ul>
        <li class="menu-title">{{ $t('site_guide') }}</li>
        <li @click="clickTrack('Footer','Home');"><router-link to="/">{{ $t('home') }} </router-link></li>
        <li @click="clickTrack('Footer','Ranking');"><router-link to="/ranking/">{{ $t('popular_movie_ranking') }} </router-link></li>
        <li @click="clickTrack('Footer','Free Sample');"><router-link to="/freesample/">{{ $t('free_sample_movies') }} </router-link></li>
        <li v-if="!isSiteMember" @click="clickTrack('Footer','Guide Join');"><router-link to="/join/">{{ $t('sign_up') }} </router-link></li>
        <li v-if="!newXPEligible" @click="clickTrack('Footer','Guide Upgrade');"> <router-link to="/upgrade/">{{ $t('upgrade') }} </router-link></li>
        <li @click="clickTrack('Footer','Access Ranking');" v-if="locale == 'ja'"><a href="http://www.adultmango.com/cgi-bin/ranklink.cgi?id=1pondo" target="_blank" rel="noopener">{{ $t('access_ranking') }} <i class="iconf-ex-link"></i></a></li>
        <li @click="clickTrack('Footer','Adult Affiliate');" v-if="locale == 'ja'"><a href="https://bit.ly/3LWrC6p" target="_blank" rel="noopener">{{ $t('adult_affiliate') }} <i class="iconf-ex-link"></i></a></li>
        <li @click="clickTrack('Footer','English');" v-if="locale == 'ja'"><a href="https://en.1pondo.tv">English</a></li>
        <li @click="clickTrack('Footer','Japanese');" v-if="locale == 'en'"><a href="https://www.1pondo.tv">日本語</a></li>
      </ul>
    </div>
    <div class="footer__menu">
      <ul>
        <li class="menu-title">{{ $t('movies') }}</li>
        <li @click="clickTrack('Footer','All List');"><router-link to="/list/?page=1&o=newest">{{ $t('all_movies_list') }} </router-link></li>
        <li @click="clickTrack('Footer','Category');"><router-link to="/category/">{{ $t('categories') }} </router-link></li>
        <li @click="clickTrack('Footer','Series');"><router-link to="/series/">{{ $t('series') }} </router-link></li>
        <li v-if="!newXPEligible" @click="clickTrack('Footer','VIP Movies');"><router-link to="/searh/?mt=1">{{ $t('vip_movies') }} </router-link></li>
        <li v-if="!newXPEligible" @click="clickTrack('Footer','SVIP Movies');"><router-link to="/search/?mt=2">{{ $t('svip_movies') }} </router-link></li>
        <li v-if="!newXPEligible" @click="clickTrack('Footer','Annual Movies');"><router-link to="/search/?mc=5">{{ $t('annual_movies') }} </router-link></li>
      </ul>
    </div>
    <div class="footer__menu">
      <ul>
        <li class="menu-title">{{ $t('help') }}</li>
        <li @click="clickTrack('Footer','FAQ');"><router-link to="/faq/">{{ $t('faq') }} </router-link></li>
        <li @click="clickTrack('Footer','Contact');"><router-link to="/contact/">{{ $t('contact') }} </router-link></li>
        <li @click="clickTrack('Footer','Sitemap');"><router-link to="/sitemap/">{{ $t('site_map') }} </router-link></li>
        <li @click="clickTrack('Footer','Content Removal');"><a href="https://service.allbrightinformation.com/contents/inquiry/" target="_blank" rel="noopener">{{ $t('content_removal') }}</a></li>
      </ul>
    </div>
    <div class="footer__menu">
      <ul>
        <li class="menu-title">{{ $t('recommended_sites') }}</li>
        <li @click="clickTrack('Footer','Caribbeancom');" ><a href="https://bit.ly/3YNngGL" target="_blank" rel="noopener">{{ $t('site_carib') }} <i class="iconf-ex-link"></i></a></li>
        <li @click="clickTrack('Footer','Caribbeancompr');"><a href="https://bit.ly/3YuE7h7" target="_blank" rel="noopener">{{ $t('site_caribpr') }} <i class="iconf-ex-link"></i></a></li>
        <li @click="clickTrack('Footer','DXLive');"><a href="https://bit.ly/3Sy9V0U" target="_blank" rel="noopener">{{ $t('site_dxlive') }} <i class="iconf-ex-link"></i></a></li>
        <li @click="clickTrack('Footer','10musume');"><a href="https://bit.ly/4dLkRk7" target="_blank" rel="noopener">{{ $t('site_10m') }} <i class="iconf-ex-link"></i></a></li>
        <li @click="clickTrack('Footer','Heyzo');"><a href="https://bit.ly/4dcOn27" target="_blank" rel="noopener">{{ $t('site_heyzo') }} <i class="iconf-ex-link"></i></a></li>
        <li @click="clickTrack('Footer','Pacopacomama');"><a href="https://bit.ly/4d7grDU" target="_blank" rel="noopener">{{ $t('site_paco') }} <i class="iconf-ex-link"></i></a></li>
      </ul>
    </div>
  </div>
  <div class="footer-sub">
    <ul class="footer__copyright">
      <li>&copy; {{ currentYear }} www.1pondo.tv</li>
      <li>v{{ appVersion }}</li>
      <li><router-link to="/2257/">18 USC § 2257 Record-Keeping Requirements</router-link></li>
      <li><router-link to="/human-rights-policies/">Human Rights Policies</router-link></li>
    </ul>
    <ul class="footer__extra">
      <li><router-link to="/agreement/">{{ $t('agreement') }} </router-link></li>
      <li><router-link to="/privacy/">{{ $t('privacy') }} </router-link></li>
    </ul>
  </div>
</div>
</footer>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-console: 0 */
import APP_CONFIG from '@/appConfig';

export default {
  created() {
    // non-reactive consts
    this.currentYear = (new Date()).getFullYear();
    this.appVersion = APP_CONFIG.app.version;
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
