const actions = {
  setInit(context) {
    context.commit('SET_INIT');
  },

  setFlags(context, payload) {
    context.commit('SET_FLAGS', payload);
  },

  resolveLogin(context) {
    context.commit('RESOLVE_LOGIN');
  },

  setUpgradeOptions(context) {
    context.commit('SET_UPGRADE_OPTIONS');
  },

  setBilling(context, payload) {
    context.commit('SET_BILLING', payload);
  },

  setNickname(context, payload) {
    context.commit('SET_NICKNAME', payload);
  },

  setVCPoints(context, payload) {
    context.commit('SET_VC_POINTS', payload);
  },

  setSessionId(context, payload) {
    context.commit('SET_SESSION_ID', payload);
  },

  resetState(context) {
    context.commit('RESET_STATE');
  },
};

export default actions;
