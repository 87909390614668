/* eslint no-param-reassign: 0 */

const mutations = {
  SET_WEBSOCKET_URL(state, url) {
    state.wsUrl = url;
  },

  SET_CLIENT_NAME(state, clientName) {
    state.wsClientName = clientName;
  },

  SET_READY_STATE(state, value) {
    state.wsReadyState = value;
  },

  SET_TRACKING_ID(state, value) {
    state.gaTrackingId = value;
  },
};

export default mutations;
