<i18n>
{
  "en": {
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming",
    "hover_added_0": "Added to playlist '",
    "hover_added_1": "'",
    "ntf_add_playlist_0": "Added to playlist '",
    "ntf_add_playlist_1": "' successfully",
    "ntf_remove_playlist_0": "Removed from playlist '",
    "ntf_remove_playlist_1": "' successfully"
  },
  "ja": {
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信",
    "hover_added_0": "「",
    "hover_added_1": "」に追加しました",
    "ntf_add_playlist_0": "「",
    "ntf_add_playlist_1": "」に追加しました",
    "ntf_remove_playlist_0": "「",
    "ntf_remove_playlist_1": "」から削除されました"
  }
}
</i18n>

<template>
<div class="grid-item">
  <!--
    top page: newest movies + category list
    movie list page: movie list
    movie detail: related movies
  -->
  <div class="entry"
    :class="{ 'hover-active': hoverActive(movie.MovieID) && !isMouseOnText }"
    v-if="['home-newest', 'home-category', 'list', 'movies-related'].includes(theme)">
    <div class="entry-media" :class="theme">
      <div v-if="!newXPEligible && movie.isVIP" class="tag-member tag-member--vip">{{ $t('vip_badge') }}</div>
      <div v-if="!newXPEligible && movie.isSVIP" class="tag-member tag-member--svip">{{ $t('svip_badge') }}</div>
      <div v-if="!newXPEligible && movie.isAnnual" class="tag-member tag-member--annual">{{ $t('annual_badge') }}</div>
      <div class="media-thum" :class="[playlistEnabled ? (movie.isWatchLaterChecked ? 'pl-overlay-saved' : 'pl-overlay') : '']">
        <span class="pl-area" :title="[movie.isWatchLaterChecked ? $t('hover_added_0') + watchLaterName + $t('hover_added_1') : watchLaterName]" @click="toggleWatchLater(movie.MetaMovieID, movie.MovieID, movie.isWatchLaterChecked)"></span>
        <router-link
          :to="`/movies/${movie.MovieID}/`"
          :data-category="categoryName"
          :data-movie-id="movie.MovieID"
          :data-movie-title="movie.Title"
          :data-actress-name="movie.Actor">
          <transition name="media" mode="out-in">
            <video
              v-if="(movie.MovieID === hoverId) && !isMouseOnText && videoPreviewEnabled"
              key="hover-video"
              autoplay
              muted
              @mouseleave="hoverOff">
              <source :src="`https://smovie.1pondo.tv/sample/movies/${movie.MovieID}/240p.mp4`" type="video/mp4">
            </video>
            <img
              v-else
              :src="`${movie.thumbnailSrc}`"
              key="default-poster"
              class="media-thum-image"
              :class="{ 'hover-active': hoverActive(movie.MovieID) }"
              :alt="movie.Title"
              @mouseover="debouncedHoverOnImage(movie.MovieID)"
              @mouseleave="hoverOff"
              @error="useFallbackImage()"
              loading="lazy"
              v-sfw />
          </transition>
        </router-link>
      </div>
    </div>
    <!-- add mouse over/leave events on this component so that when user mouseover this section, the above image shows hover effect (opacity change) -->
    <router-link
      :to="`/movies/${movie.MovieID}/`"
      class="entry-meta"
      :data-category="categoryName"
      :data-movie-id="movie.MovieID"
      :data-movie-title="movie.Title"
      :data-actress-name="movie.Actor"
      @mouseover.native="hoverOnText(movie.MovieID)"
      @mouseleave.native="hoverOff">
      <div v-if="movie.IsExpired" class="tag-status tag-status--expired">{{ $t('expired_tag') }}</div>
      <div v-if="movie.IsLimited" class="tag-status tag-status--limited">{{ $t('limited_tag') }}</div>
      <div v-if="movie.IsComingSoon" class="tag-status tag-status--future">{{ $t('future_tag') }}</div>
      <div v-if="movie.isVR" class="tag-status tag-status--vr">{{ $t('vr_tag') }}</div>
      <div class="meta-data">{{ movie.ReleaseDate }}</div>
      <div v-if="locale == 'ja'">
        <div class="meta-title">{{ movie.Title }}</div>
        <div class="meta-data">{{ movie.Actor }}</div>
      </div>
      <div v-if="locale == 'en'">
        <div v-if="movie.TitleEn != null && movie.TitleEn != ''" class="meta-title">{{ movie.TitleEn }}</div>
        <div v-else class="meta-title" v-for="actress in movie.ActressesEn" :key="actress.$index">{{ actress }}</div>
      </div>
      <div class="meta-rating">
        <star-rating v-model="movie.AvgRating" active-color="#FEB507" :increment="0.1" :star-size="14" :inline="true" :show-rating="false" :read-only="true"></star-rating>
      </div>
    </router-link>
  </div>

  <!--
    free sample page
  -->
  <div class="entry" v-else-if="['freesample-newest'].includes(theme)">
    <span class="entry-media is-play" @click="showModalSample(movie.MovieID); clickTrack('Free Sample Page', 'Play Sample Movie', movie.Title)">
      <div v-if="!newXPEligible && movie.isVIP" class="tag-member tag-member--vip">{{ $t('vip_badge') }}</div>
      <div v-if="!newXPEligible && movie.isSVIP" class="tag-member tag-member--svip">{{ $t('svip_badge') }}</div>
      <div v-if="!newXPEligible && movie.isAnnual" class="tag-member tag-member--annual">{{ $t('annual_badge') }}</div>
      <div class="media-thum">
        <img class="media-thum-image"
          :src="`${movie.thumbnailSrc}`"
          :alt="movie.Title"
          @error="useFallbackImage()"
          loading="lazy"
          v-sfw />
      </div>
    </span>
    <router-link :to="`/movies/${movie.MovieID}/`" class="entry-meta">
      <div v-if="movie.IsExpired" class="tag-status tag-status--expired">{{ $t('expired_tag') }}</div>
      <div v-if="movie.IsLimited" class="tag-status tag-status--limited">{{ $t('limited_tag') }}</div>
      <div v-if="movie.IsComingSoon" class="tag-status tag-status--future">{{ $t('future_tag') }}</div>
      <div class="meta-data">{{ movie.ReleaseDate }}</div>
      <div v-if="locale == 'ja'">
        <div class="meta-title" @click="clickTrack('Free Sample Page', movie.Title, movie.Title)">{{ movie.Title }}</div>
        <div class="meta-data" @click="clickTrack('Free Sample Page', movie.Actor, movie.Title)">{{ movie.Actor }}</div>
      </div>
      <div v-if="locale == 'en'">
        <div v-if="movie.TitleEn != null && movie.TitleEn != ''" class="meta-title" @click="clickTrack('Free Sample Page', movie.Title, movie.Title)">{{ movie.TitleEn }}</div>
        <div v-else class="meta-title" v-for="(actress, index) in movie.ActressesEn" :key="index" @click="clickTrack('Free Sample Page', actress, movie.Title)">{{ actress }}</div>
      </div>
      <div class="meta-rating">
        <star-rating v-model="movie.AvgRating" active-color="#FEB507" :increment="0.1" :star-size="14" :inline="true" :show-rating="false" :read-only="true"></star-rating>
      </div>
    </router-link>
  </div>

  <!--
    ranking page (movies)
  -->
  <span v-else-if="['ranking'].includes(theme)">
    <router-link :to="`/movies/${movie.MovieID}/`" class="entry" :class="{ 'hover-active': hoverActive(movie.MovieID) && !isMouseOnText }">
      <div class="entry-ranking">
        <div v-if="index == 0" class="ranking-number ranking-number--gold">{{ index + 1 }}</div>
        <div v-else-if="index == 1" class="ranking-number ranking-number--silver">{{ index + 1 }}</div>
        <div v-else-if="index == 2" class="ranking-number ranking-number--bronze">{{ index + 1 }}</div>
        <div v-else class="ranking-number">{{ index + 1 }}</div>
      </div>
      <div class="entry-media" :class="theme">
        <div v-if="!newXPEligible && movie.isVIP" class="tag-member tag-member--vip">{{ $t('vip_badge') }}</div>
        <div v-if="!newXPEligible && movie.isSVIP" class="tag-member tag-member--svip">{{ $t('svip_badge') }}</div>
        <div v-if="!newXPEligible && movie.isAnnual" class="tag-member tag-member--annual">{{ $t('annual_badge') }}</div>
        <div class="media-thum" :class="[playlistEnabled ? (movie.isWatchLaterChecked ? 'pl-overlay-saved' : 'pl-overlay') : '']">
          <span class="pl-area" :title="[movie.isWatchLaterChecked ? $t('hover_added_0') + watchLaterName + $t('hover_added_1') : watchLaterName]" @click="toggleWatchLater(movie.MetaMovieID, movie.MovieID, movie.isWatchLaterChecked)"></span>
          <router-link :to="`/movies/${movie.MovieID}/`">
            <transition name="media" mode="out-in">
              <video
                v-if="(movie.MovieID === hoverId) && !isMouseOnText && videoPreviewEnabled"
                key="hover-video"
                autoplay
                muted
                @mouseleave="hoverOff">
                <source :src="`https://smovie.1pondo.tv/sample/movies/${movie.MovieID}/240p.mp4`" type="video/mp4">
              </video>
              <img
                v-else
                :src="`${movie.thumbnailSrc}`"
                key="default-poster"
                class="media-thum-image"
                :class="{ 'hover-active': hoverActive(movie.MovieID) }"
                :alt="movie.Title"
                @mouseover="debouncedHoverOnImage(movie.MovieID)"
                @mouseleave="hoverOff"
                @error="useFallbackImage()"
                loading="lazy"
                v-sfw />
            </transition>
          </router-link>
        </div>
      </div>
      <div class="entry-meta">
        <div class="meta-data">{{ movie.ReleaseDate }}</div>
        <div v-if="locale == 'ja'">
          <div class="meta-title">{{ movie.Title }}</div>
          <div class="meta-data">{{ movie.Actor }}</div>
        </div>
        <div v-if="locale == 'en'">
          <div v-if="movie.TitleEn != null && movie.TitleEn != ''" class="meta-title">{{ movie.TitleEn }}</div>
          <div v-else class="meta-title" v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}</div>
        </div>
        <div class="meta-rating">
          <star-rating v-model="movie.AvgRating" active-color="#FEB507" :increment="0.1" :star-size="14" :inline="true" :show-rating="false" :read-only="true"></star-rating>
        </div>
      </div>
    </router-link>
  </span>
</div>
</template>

<script>
/* eslint max-len: 0 */
import debounce from 'lodash/debounce';
import StarRating from 'vue-star-rating';
import EventBus from '@/assets/js/utils/EventBus';
import SiteConfig from '@/assets/js/utils/SiteConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

const WATCH_LATER_PLAYLIST_NAME = 'watch_later';
const DELAY_BEFORE_VIDEO_PREVIEW = 500;

export default {
  props: {
    theme: {
      type: String,
      required: true,
    },
    movie: {
      type: Object,
      required: true,
    },
    categoryName: {
      type: String,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  components: {
    'star-rating': StarRating,
  },
  data() {
    return {
      config: {},
      posterFallbackTried: false,
      hoverId: null,
      isMouseOnText: false,
      videoPreviewEnabled: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVideoPreviewState);
  },
  async created() {
    // lodash: _.debounce(func, [wait=0], [options={}])
    // creates a debounced function that delays invoking func until after wait milliseconds have elapsed
    // since the last time the debounced function was invoked
    this.debouncedHoverOnImage = debounce(this.hoverOnImage, DELAY_BEFORE_VIDEO_PREVIEW);

    // get site config (settings from admin)
    this.config = await SiteConfig.getConfig('global');

    // video hover preview
    this.setVideoPreviewState();
    this.$nextTick(() => {
      window.addEventListener('resize', this.setVideoPreviewState);
    });
  },
  methods: {
    // playlist functions =========================================================================
    toggleWatchLater(movieId, movieDateId, isWatchLaterChecked) {
      if (this.watchLaterPlaylistId) {
        if (isWatchLaterChecked) {
          this.deleteMovieFromPlaylist(this.watchLaterPlaylistId, movieId);
          this.$set(this.movie, 'isWatchLaterChecked', false);
        } else {
          this.addMovieToPlaylist(this.watchLaterPlaylistId, movieId, movieDateId);
          this.$set(this.movie, 'isWatchLaterChecked', true);
        }
      }
    },

    addMovieToPlaylist(playlistId, movieId, movieDateId) {
      const bfAPI = new BifrostAPI();
      bfAPI.addMovieToPlaylist(playlistId, movieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_add_playlist_0') + this.watchLaterName + this.$t('ntf_add_playlist_1'),
            duration: 3000,
          });
          this.$analytics.trackEvent('Playlist', 'Add Movie to "Watch Later"', movieDateId);
        }
      });
    },

    deleteMovieFromPlaylist(playlistId, movieId) {
      const bfAPI = new BifrostAPI();
      bfAPI.deleteMovieFromPlaylist(playlistId, movieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_remove_playlist_0') + this.watchLaterName + this.$t('ntf_remove_playlist_1'),
            duration: 3000,
          });
        }
      });
    },
    // =============================================================================================

    // manage hover state when over movie sections =================================================
    hoverOnImage(mid) {
      this.hoverId = mid;
    },

    hoverOnText(mid) {
      this.hoverId = mid;
      this.isMouseOnText = true;
    },

    hoverOff() {
      this.hoverId = '';
      this.isMouseOnText = false;

      // the debounced function comes with a cancel method to cancel delayed func invocations
      // and a flush method to immediately invoke them
      this.debouncedHoverOnImage.cancel();
    },

    hoverActive(mid) {
      return this.hoverId === mid;
    },
    // =============================================================================================

    // everything else =============================================================================
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },

    showModalSample(movieId) {
      this.$modal.show('ui-modal-video', { movieId });
    },

    setVideoPreviewState() {
      this.videoPreviewEnabled = (window.innerWidth >= 770);
    },

    useFallbackImage() {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTried) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.movie, 'thumbnailSrc', `/assets/sample/${this.movie.MovieID}/str.jpg`);
        this.posterFallbackTried = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.movie, 'thumbnailSrc', '/img/common/now_printing_landscape.png');
      }
    },
    // =============================================================================================
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    playlistEnabled() {
      return this.$store.getters['playlist/isEnabled'];
    },
    translationMap() {
      return this.$store.getters['playlist/translationMap'];
    },
    watchLaterName() {
      return this.translationMap[WATCH_LATER_PLAYLIST_NAME][this.locale];
    },
    defaultPlaylistIds() {
      return this.$store.getters['playlist/defaultPlaylistIDs'];
    },
    watchLaterPlaylistId() {
      return this.defaultPlaylistIds[WATCH_LATER_PLAYLIST_NAME];
    },
    hoverPreviewEnabled() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'movieThumbsHoverPreview') ? this.config.movieThumbsHoverPreview : false);
    },
  },
  watch: {
    isSiteMember(newVal, oldVal) {
      if (oldVal !== newVal) this.setVideoPreviewState();
    },
  },
};
</script>

<style lang="scss" scoped>
.entry-media .media-thum .movie-area {
  content: "";
  display: inline-block;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 90%;
  z-index: 1;
}

.entry-media .media-thum .pl-area {
  content: "";
  display: inline-block;
  position: absolute;
  height: 25px;
  right: 5px;
  bottom: 5px;
  width: 25px;
  z-index: 1;
  cursor: pointer;
}

.entry-media .media-thum.pl-overlay:before, .entry-media .media-thum.pl-overlay-saved:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 25px;
  right: 5px;
  bottom: 5px;
  width: 25px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
  -moz-transition: opacity .5s ease-in-out;
  -webkit-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.entry-media .media-thum.pl-overlay:hover:before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M12.516 6.984v5.25l4.5 2.672-0.75 1.266-5.25-3.188v-6h1.5zM12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z'/%3E%3C/svg%3E") 0 0 no-repeat;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

.entry-media .media-thum.pl-overlay-saved:hover:before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563-1.406 1.406zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z'/%3E%3C/svg%3E") 0 0 no-repeat;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

/* inline sample video playback */

.media-thum-image.hover-active {
  opacity: 0.8;
}

.entry.hover-active {
  .entry-media {
    transform: scale(1.1);
  }
}

.entry {
  .entry-media {
    transition: 0.5s ease-in-out;

    video, img {
      display: block;
    }

    .media-enter-active, .media-leave-active {
      transition: opacity 0.5s ease-in-out;
    }

    .media-enter, .media-leave-to {
      opacity: 0;
    }
  }
  @include mq(tablet) {
    .entry-media.home-newest,
    .entry-media.home-category,
    .entry-media.list,
    .entry-media.ranking {
      video, img {
        width: 224px;
        height: 126px;
      }
    }
    .entry-media.movies-related {
      video, img {
        width: 168px;
      }
    }
  }
  @include mq(desktop) {
    .entry-media.home-newest,
    .entry-media.home-category,
    .entry-media.list {
      video, img {
        width: 304px;
        height: 171px;
      }
    }
    .entry-media.movies-related {
      video, img {
        width: 192px;
        height: 108px;
      }
    }
    .entry-media.ranking {
      video, img {
        width: 224px;
        height: 126px;
      }
    }
  }
}
</style>
