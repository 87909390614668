/* eslint no-param-reassign: 0 */

const mutations = {
  SET_INIT(state) {
    state.initialized = true;
  },

  INCREMENT_PAGE_ID(state) {
    state.pageID += 1;
  },

  INCREMENT_VENUS_REQ_ID(state) {
    state.venusReqID += 1;
  },

  REGISTER_APP(state, payload) {
    // only register if it hasn't been registered previously
    if (!Object.prototype.hasOwnProperty.call(state.registeredApps, payload.appID)) {
      state.registeredApps[payload.appID] = {
        name: payload.appName,
        recv: payload.appCallback,
      };
    }
  },

  ADD_REQUEST(state, payload) {
    state.requests[payload.requestID] = {
      created: new Date(), // To handle timeouts properly
      cb: payload.cb, // this promise gets resolved on recv, which returns the data from the backend
      json: payload.request,
    };

    // increment request ID
    state.requestID += 1;
  },

  DELETE_REQUEST(state, requestID) {
    delete state.requests[requestID];
  },

  INIT_PENDING(state, cacheKey) {
    state.pendingCacheMap[cacheKey] = [];
  },

  ADD_PENDING(state, payload) {
    state.pendingCacheMap[payload.cacheKey].push(payload.requestID);
  },

  DELETE_PENDING(state, cacheKey) {
    delete state.pendingCacheMap[cacheKey];
  },

  ADD_NON_REPLY_REQUEST(state, payload) {
    state.nonReplyRequests[payload.cacheKey] = {
      created: new Date(), // To handle timeouts properly
      cb: payload.cb, // this promise gets resolved on recv, which returns the data from the backend
      json: payload.request,
    };
  },

  DELETE_NON_REPLY_REQUEST(state, cacheKey) {
    delete state.nonReplyRequests[cacheKey];
  },

  ADD_VENUS_REQUEST(state, payload) {
    state.venusRequests[payload.venusReqID] = payload;
  },

  DELETE_VENUS_REQUEST(state, venusReqID) {
    delete state.venusRequests[venusReqID];
  },

  INIT_VENUS_PENDING(state, venusCacheKey) {
    state.venusPendingCacheMap[venusCacheKey] = [];
  },

  ADD_VENUS_PENDING(state, payload) {
    state.venusPendingCacheMap[payload.venusCacheKey].push(payload.venusReqID);
  },

  DELETE_VENUS_PENDING(state, venusCacheKey) {
    delete state.venusPendingCacheMap[venusCacheKey];
  },

  SET_RANDOM_SEED(state, seed) {
    state.randomSeed = seed;
  },

  INCREMENT_WAITING(state) {
    state.waitingCount += 1;
  },

  DECREMENT_WAITING(state) {
    state.waitingCount -= 1;
  },
};

export default mutations;
