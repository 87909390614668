<i18n>
{
  "en": {
    "h_movies": "Movies",
    "h_av": "Pornstars",
    "h_member_services": "Member Services",
    "h_site_guide": "Site Guide",
    "agreement": "Agreement",
    "all_movies": "All Movies",
    "annual_movies": "Annual Movies",
    "categories": "Categories",
    "contact": "Contact",
    "ddl_plans": "Daily Download Limit Plans",
    "dml_plans": "Daily Download Limit Plans",
    "faq": "FAQ",
    "free_sample": "Free Sample Movies",
    "highspeed_plans": "Highspeed Plan",
    "home": "Home",
    "join": "Sign Up",
    "privacy_policy": "Privacy Policy",
    "ranking": "Ranking",
    "search": "Search",
    "series": "Series",
    "site_map": "Site Map",
    "svip_movies": "Super VIP Movies",
    "upgrade_plans": "Upgrade",
    "vip_movies": "VIP Movies",
    "vrack_what_is": "What is V-RACK?",
    "av_actresses": "All Actresses",
    "av_actors": "All Actors",
    "service_ppv": "PPV Movies",
    "service_dx": "Live Chat"
  },
  "ja": {
    "h_movies": "動画",
    "h_av": "AV女優",
    "h_member_services": "会員サービス",
    "h_site_guide": "サイトガイド",
    "agreement": "利用規約",
    "all_movies": "全動画一覧",
    "annual_movies": "年間動画",
    "categories": "カテゴリー",
    "contact": "お問い合わせ",
    "ddl_plans": "ダウンロード増量プラン",
    "dml_plans": "ダウンロード増量プラン",
    "faq": "ヘルプ",
    "free_sample": "無料動画",
    "highspeed_plans": "ハイスピードプラン",
    "home": "ホーム",
    "join": "入会案内",
    "privacy_policy": "プライバシー",
    "ranking": "人気ランキング",
    "search": "検索",
    "series": "シリーズ",
    "site_map": "サイトマップ",
    "svip_movies": "超VIP動画",
    "upgrade_plans": "アップグレード案内",
    "vip_movies": "VIP動画",
    "vrack_what_is": "V-RACKとは？",
    "av_actresses": "AV女優一覧 (50音順)",
    "av_actors": "男優一覧 (50音順)",
    "service_ppv": "単品動画",
    "service_dx": "ライブチャット"
  }
}
</i18n>

<template>
<div class="nav" v-show="menuVisible">
  <nav id="appNav">
    <div class="nav__section">
      <div class="nav__wrap nav__search">
        <!--added to check composing event-->
        <input class="searchbox" type="text" v-model="search.query" :placeholder="$t('search')" @keydown.enter="submitSearch($event)" @compositionstart="composing=true" @compositionend="composing=false">
      </div>
    </div>
    <div class="nav__section nav__service">
      <ul>
        <li class="nav__item" @click="clickTrack('Nav Menu','PPV');"><a class="nav__wrap is-ppv" href="https://bit.ly/3YuE7h7" target="_blank" rel="noopener"><svg class="tm-icon"><use xlink:href="#tm-icon-ppv"/></svg>{{ $t('service_ppv') }}</a></li>
        <li class="nav__item" @click="clickTrack('Nav Menu','DX');"><a class="nav__wrap is-dx" href="https://bit.ly/3Sy9V0U" target="_blank" rel="noopener"><svg class="tm-icon"><use xlink:href="#tm-icon-dx"/></svg>{{ $t('service_dx') }}</a></li>
      </ul>
    </div>
    <div class="nav__section">
      <ul>
        <li class="nav__item" @click="clickTrack('Nav Menu','Home');"><router-link to="/" class="nav__wrap">{{ $t('home') }}</router-link></li>
        <li v-if="vrackEnabled" class="nav__item" @click="clickTrack('Nav Menu','What Is VRack');"><router-link to="/help/?page=whatisvrack" class="nav__wrap">{{ $t('vrack_what_is') }}</router-link></li>
        <li class="nav__item" @click="clickTrack('Nav Menu','Free Sample Movies');"><router-link to="/freesample/" class="nav__wrap">{{ $t('free_sample') }}</router-link></li>
        <li v-if="!isSiteMember" class="nav__item" @click="clickTrack('Nav Menu','Guide Join');"><router-link to="/join/" class="nav__wrap">{{ $t('join') }}</router-link></li>
        <li class="nav__item" @click="clickTrack('Nav Menu','Guide Help');"><router-link to="/faq/" class="nav__wrap">{{ $t('faq') }}</router-link></li>
      </ul>
    </div>
    <div class="nav__section">
      <label for="menu__movie" class="nav__wrap" :class="{ 'nav__dropup': movieMenuVisible, 'nav__dropdown': !movieMenuVisible }" @click="movieMenuVisible = !movieMenuVisible">{{ $t('h_movies') }}</label>
      <transition name="slide">
      <div v-if="movieMenuVisible" class="accordion-contents">
        <ul>
          <li class="nav__item" @click="clickTrack('Nav Menu','All Movies');"><router-link to="/list/?page=1&o=newest" class="nav__wrap">{{ $t('all_movies') }}</router-link></li>
          <li class="nav__item" @click="clickTrack('Nav Menu','Ranking');"><router-link to="/ranking/" class="nav__wrap">{{ $t('ranking') }}</router-link></li>
          <li class="nav__item" @click="clickTrack('Nav Menu','Category');"><router-link to="/category/" class="nav__wrap">{{ $t('categories') }}</router-link></li>
          <li class="nav__item" @click="clickTrack('Nav Menu','Series');"><router-link to="/series/" class="nav__wrap">{{ $t('series') }}</router-link></li>
          <li v-if="!newXPEligible" class="nav__item" @click="clickTrack('Nav Menu','VIP Movies');"><router-link to="/search/?mt=1" class="nav__wrap">{{ $t('vip_movies') }}</router-link></li>
          <li v-if="!newXPEligible" class="nav__item" @click="clickTrack('Nav Menu','SVIP Movies');"><router-link to="/search/?mt=2" class="nav__wrap">{{ $t('svip_movies') }}</router-link></li>
          <li v-if="!newXPEligible" class="nav__item" @click="clickTrack('Nav Menu','Annual Movies');"><router-link to="/search/?mc=5" class="nav__wrap">{{ $t('annual_movies') }}</router-link></li>
        </ul>
      </div>
      </transition>
    </div>
    <div class="nav__section">
      <label for="menu__av" class="nav__wrap" :class="{ 'nav__dropup': avMenuVisible, 'nav__dropdown': !avMenuVisible }" @click="avMenuVisible = !avMenuVisible">{{ $t('h_av') }}</label>
      <transition name="slide">
      <div v-if="avMenuVisible" class="accordion-contents">
        <ul>
          <li class="nav__item" @click="clickTrack('Nav Menu','All Actresses');"><router-link to="/actress/" class="nav__wrap">{{ $t('av_actresses') }}</router-link></li>
          <li v-if="locale == 'ja'" class="nav__item" @click="clickTrack('Nav Menu','All Actors');"><router-link to="/actor/" class="nav__wrap">{{ $t('av_actors') }}</router-link></li>
        </ul>
      </div>
      </transition>
    </div>
    <div class="nav__section" v-if="!newXPEligible">
      <label for="menu__member-service" class="nav__wrap" :class="{ 'nav__dropup': memberMenuVisible, 'nav__dropdown': !memberMenuVisible }" @click="memberMenuVisible = !memberMenuVisible">{{ $t('h_member_services') }}</label>
      <transition name="slide">
      <div v-if="memberMenuVisible" class="accordion-contents">
        <ul>
          <li class="nav__item" @click="clickTrack('Nav Menu','Guide Upgrade');"><router-link to="/upgrade/" class="nav__wrap">{{ $t('upgrade_plans') }}</router-link></li>
          <li class="nav__item" @click="clickTrack('Nav Menu','Guide High Speed')"><router-link to="/highspeed/" class="nav__wrap">{{ $t('highspeed_plans') }}</router-link></li>
          <li v-if="!dmlEnabled" class="nav__item" @click="clickTrack('Nav Menu','Guide DDL');"><router-link to="/ddl/" class="nav__wrap">{{ $t('ddl_plans') }}</router-link></li>
          <li v-if="dmlEnabled" class="nav__item" @click="clickTrack('Nav Menu','Guide DML');"><router-link to="/dml/" class="nav__wrap">{{ $t('dml_plans') }}</router-link></li>
        </ul>
      </div>
      </transition>
    </div>
    <div class="nav__section">
      <label for="menu__guide" class="nav__wrap" :class="{ 'nav__dropup': siteGuideMenuVisible, 'nav__dropdown': !siteGuideMenuVisible }" @click="siteGuideMenuVisible = !siteGuideMenuVisible">{{ $t('h_site_guide') }}</label>
      <transition name="slide">
      <div v-if="siteGuideMenuVisible" class="accordion-contents">
        <ul>
          <li class="nav__item" @click="clickTrack('Nav Menu','Contact');"><router-link to="/contact/" class="nav__wrap">{{ $t('contact') }}</router-link></li>
          <li class="nav__item" @click="clickTrack('Nav Menu','Sitemap');"><router-link to="/sitemap/" class="nav__wrap">{{ $t('site_map') }}</router-link></li>
          <li class="nav__item" @click="clickTrack('Nav Menu','Agreement');"><router-link to="/agreement/" class="nav__wrap">{{ $t('agreement') }}</router-link></li>
          <li class="nav__item" @click="clickTrack('Nav Menu','Privacy');"><router-link to="/privacy/" class="nav__wrap">{{ $t('privacy_policy') }}</router-link></li>
          <li v-if="locale == 'ja'" class="nav__item" @click="clickTrack('Nav Menu','Access Ranking');"><a class="nav__wrap" href="http://www.adultmango.com/cgi-bin/ranklink.cgi?id=1pondo" target="_blank" rel="noopener">アクセスランキング</a></li>
          <li v-if="locale == 'ja'" class="nav__item" @click="clickTrack('Nav Menu','English');"><a class="nav__wrap" href="https://en.1pondo.tv">English</a></li>
          <li v-if="locale == 'en'" class="nav__item" @click="clickTrack('Nav Menu','Japanese');"><a class="nav__wrap" href="https://www.1pondo.tv">日本語</a></li>
        </ul>
      </div>
      </transition>
    </div>
    <div class="nav__section nav__wrap">
      <sidebar-pr />
    </div>
  </nav>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-console: 0 */

import APP_CONFIG from '@/appConfig';
import SidebarPR from './PR.vue';
import EventBus from '@/assets/js/utils/EventBus';

// minimum width required for nav menu to be visible by default
const MENU_MIN_WIDTH = 1024;

export default {
  components: {
    'sidebar-pr': SidebarPR,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      menuVisible: false,
      movieMenuVisible: true,
      avMenuVisible: false,
      memberMenuVisible: false,
      siteGuideMenuVisible: false,
      composing: false,
      search: {
        query: '',
      },
    };
  },
  mounted() {
    // set nav menu top style
    this.calcNavTopStyle();
  },
  beforeMount() {
    window.addEventListener('scroll', this.calcNavTopStyle);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.calcNavTopStyle);
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    // non-reactive consts
    this.dmlEnabled = APP_CONFIG.dml.enabled;

    // set menu initial state
    this.menuVisible = (window.innerWidth >= MENU_MIN_WIDTH);
    this.$store.dispatch('prefs/setMenuAlwaysVisible', this.menuVisible);

    // update windowWidth property
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    // set listener for menu toggle (from header component)
    EventBus.$on('navMenu:toggle', () => {
      this.menuVisible = !this.menuVisible;
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    menuAlwaysVisible() {
      return this.$store.getters['prefs/menuAlwaysVisible'];
    },
    vrackEnabled() {
      return this.$store.getters['vrack/enabled'];
    },
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
    calcNavTopStyle() {
      const d2HeaderHeight = this.$store.getters['d2ptoolbar/headerHeight'];
      const appHeaderHeight = APP_CONFIG.site.headerHeight;
      const elemCss = document.getElementById('appNav').style;
      if (window.pageYOffset < d2HeaderHeight) {
        // d2 bar is visible: pad nav
        elemCss.top = `${(d2HeaderHeight + appHeaderHeight) - window.pageYOffset}px`;
      } else {
        // d2 bar is out of view: lock nav at app header height
        elemCss.top = `${appHeaderHeight}px`;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    submitSearch(event) {
      // for Japanese IME if keycode is 229 it means "composing"
      if (event.keyCode === 229) {
        this.composing = true;
      }
      // only fire when NOT composing
      if (this.search.query !== '' || !this.composing || event.keyCode !== 229) {
        // reset state
        this.search.showInput = false;
        const searchQuery = this.search.query; // copy before we clear it
        this.search.query = ''; // now clear it

        // now go
        this.$router.push({ name: 'movie-search', query: { s: searchQuery } });
      }
    },
  },
  watch: {
    // we want the menu to always be visible when the display is wide enough
    windowWidth(newVal) {
      this.menuVisible = (newVal >= MENU_MIN_WIDTH);
      this.$store.dispatch('prefs/setMenuAlwaysVisible', this.menuVisible);
    },

    // watch route change and close the menu when it changes (only if the menu was set as 'always
    // visible' in the first place). note: not using arrow functions here because I need to bind
    // to 'this' and it's just easier to just name the function to get around this limitation
    '$route.path': function watchRoutePath() {
      if (!this.menuAlwaysVisible) this.menuVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav__dropdown::before {
  background: url('/img/common/icon-dropdown.png') 0 0 no-repeat;
}
.nav__dropup::before {
  background: url('/img/common/icon-dropup.png') 0 0 no-repeat;
}
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 400px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
