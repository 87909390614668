/* eslint no-console: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint max-len: 0 */
/* eslint wrap-iife: 0 */
/* eslint no-loop-func: 0 */
/* eslint no-shadow: 0 */

import includes from 'lodash/includes';
import clone from 'lodash/clone';
import md5 from 'md5';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import Cache from '../../utils/Cache';
import Deferred from '../../utils/Deferred';
import EventBus from '../../utils/EventBus';

export default class Bifrost {
  constructor() {
    this.startReplyListener();

    this.registeredApps = store.getters['bifrost/registeredApps'];
    this.requests = {};
    this.pendingCacheMap = {};
    this.waitingCount = store.getters['bifrost/waitingCount'];

    this.cache = new Cache('bifrost');
  }

  startReplyListener() {
    // check if listener has been previously initialized. if so, then don't do this
    if (store.getters['bifrost/isInitialized']) return;

    console.log('%cInitializing Bifrost service', 'background: #222; color: #ff0');

    // set as initialized in store
    store.dispatch('bifrost/setInit', true);

    // message listener
    EventBus.$on('websocket:onMessage', (msg) => {
      if (msg.Reply) {
        // message is a reply to a request, so we resolve it with pending and move it forward
        this.recv(msg);
      } else if ((Object.prototype.hasOwnProperty.call(this.registeredApps, msg.AppID) && typeof (this.registeredApps[msg.AppID].recv) === 'function')) {
        // message is a non-reply. do we have an AppID handler for it?
        // we do, send the send promise and message to the handler
        this.registeredApps[msg.AppID].recv(msg);
        console.log(`%crecv non-reply ${this.registeredApps[msg.AppID].name}`, 'background: #222; color: #66FF33', msg);
      } else {
        console.log('%cUnknown non-reply message', 'background: #222; color: #66FF33', msg);
      }
    });

    EventBus.$on('websocket:opened', () => {
      // Send any pending non-reply quests
      console.log('pending non-reply requests to send', store.getters['bifrost/nonReplyRequests']);
      for (const cacheKey in store.getters['bifrost/nonReplyRequests']) {
        if (Object.prototype.hasOwnProperty.call(store.getters['bifrost/nonReplyRequests'], cacheKey)) {
          console.log(`%csend pending non-reply request ${this.registeredApps[store.getters['bifrost/nonReplyRequests'][cacheKey].json.AppID].name}`, 'background: #222; color: #66FF33', store.getters['bifrost/nonReplyRequests'][cacheKey]);
          EventBus.$emit('websocket:sendMessage', store.getters['bifrost/nonReplyRequests'][cacheKey].json);
          store.dispatch('bifrost/deleteNonReplyRequest', cacheKey);
        }
      }

      // Send any pending unique requests (duplicate pending requests don't have a Request ID)
      console.log('pending requests to send', store.getters['bifrost/requests']);
      for (const request in store.getters['bifrost/requests']) {
        if (Object.prototype.hasOwnProperty.call(store.getters['bifrost/requests'], request) && store.getters['bifrost/requests'][request].json.RequestID) {
          console.log(`%csend pending request ${this.registeredApps[store.getters['bifrost/requests'][request].json.AppID].name}`, 'background: #222; color: #66FF33', store.getters['bifrost/requests'][request]);
          EventBus.$emit('websocket:sendMessage', store.getters['bifrost/requests'][request].json);
        }
      }
    });

    EventBus.$on('sessionID:set', (sessionID) => {
      // walk thru pending venus request cache keys
      const venusRequests = store.getters['bifrost/venusRequests'];
      const venusPendingMap = store.getters['bifrost/venusPendingCacheMap'];
      console.log(`%c session ${sessionID} set, pending venus cache keys`, 'color:#fff', venusPendingMap);
      Object.entries(venusPendingMap).forEach(async ([venusCacheKey, venusReqIDs]) => {
        console.log('%cvenusCacheKey', 'color:#fff', venusCacheKey);
        console.log('%cvenusReqIDs', 'color:#fff', venusReqIDs);
        if (venusReqIDs.length > 0) {
          // request 1st item from each array
          const pendingReq = venusRequests[venusReqIDs[0]];
          const firstResponse = await this.venusRequest(
            pendingReq.url,
            pendingReq.method,
            pendingReq.doCache,
            pendingReq.data,
            pendingReq.venusReqID,
            pendingReq.venusCacheKey,
            pendingReq.cb,
          );

          // after response has been returned, resolve the remaining IDs in the list using the same
          // response data. note we're starting the increment at 1 instead of 0 since we just made
          // the request for the 1st item
          for (let i = 1; i < venusReqIDs.length; i += 1) {
            const pendingReq = venusRequests[venusReqIDs[i]];
            pendingReq.cb.resolve(firstResponse);

            store.dispatch('bifrost/deleteVenusRequest', venusReqIDs[i]); // delete pending request from queue
            store.dispatch('bifrost/decrementWaiting'); // clear from loading bar

            console.log(`%cvenus dupe request resolved (venusReqID ${venusReqIDs[i]})`, 'background: #222; color: #66FF33', firstResponse);
          }

          // all done, clear cache map for the key
          store.dispatch('bifrost/deleteVenusPending', venusCacheKey);
        }
      });
    });

    router.beforeEach((to, from, next) => {
      // adding short delay before route changes so that GTM has time to catch up and get the
      // correct page info (URL + title) on the "Link Click" event
      setTimeout(() => {
        // increment page id on route changes
        if (to !== from) store.dispatch('bifrost/incrementPageID');
        next();
      }, 100);
    });

    // directly referencing the store here instead of this.registeredApps since the apps are
    // registered asyncronously and will display null in console if we try logging it during init
    console.log('%cRegistered Apps', 'background: #222; color: #ff0', store.getters['bifrost/registeredApps']);
  }

  registerAppListener(appInfo, cb) {
    if (!Object.prototype.hasOwnProperty.call(store.getters['bifrost/registeredApps'], appInfo.id)) {
      store.dispatch('bifrost/registerApp', {
        type: 'registerApp',
        appID: appInfo.id,
        appName: appInfo.name,
        appCallback: cb,
      });
      this.registeredApps = store.getters['bifrost/registeredApps'];
      console.log(`%cRegistered AppID ${appInfo.id} (${appInfo.name})`, 'background: #222; color: #ff0');
    }
  }

  recv(msg) {
    console.log(`%crecv reply ${this.registeredApps[msg.AppID].name}`, 'background: #222; color: #ffff00', msg);

    // If an object exists with request id in our callbacks object, resolve it
    if (store.getters['bifrost/requests'][msg.RequestID]) {
      // reply message received, so decrement spinner count
      store.dispatch('bifrost/decrementWaiting');

      // resolve promise with received data
      store.getters['bifrost/requests'][msg.RequestID].cb.resolve(msg.Data);

      // walk through all pending requests' cache keys
      for (const key in store.getters['bifrost/pendingCacheMap']) {
        if (Object.prototype.hasOwnProperty.call(store.getters['bifrost/pendingCacheMap'], key) && includes(store.getters['bifrost/pendingCacheMap'][key], msg.RequestID)) {
          // matched the incoming message's request ID with a cache key

          if (store.getters['bifrost/pendingCacheMap'][key].length > 1) {
            // the cache key map has more than 1 reqID attached to it (it has dupe requests)
            console.log(`%cresolving ${this.registeredApps[msg.AppID].name} dupe reqs`, 'background: #222; color: #66CCFF', msg.RequestID, store.getters['bifrost/pendingCacheMap'][key]);
            for (let i = 1; i < store.getters['bifrost/pendingCacheMap'][key].length; i += 1) {
              // start from the 2nd item in the list (the 1st/original request id will be deleted
              // later in this flow, after the data is cached)
              const dupeRequestID = store.getters['bifrost/pendingCacheMap'][key][i];
              if (dupeRequestID !== msg.RequestID) {
                // resolve the dupe request
                store.getters['bifrost/requests'][dupeRequestID].cb.resolve(msg.Data);

                // remove dupe request from request queue
                console.log(`deleting dupe requestID ${dupeRequestID}`);
                store.dispatch('bifrost/deleteRequest', dupeRequestID);
              }
            }
          }

          // dupe requests resolved, remove the cache key map
          store.dispatch('bifrost/deletePending', key);

          // save to cache if caching is enabled for this request
          if (store.getters['bifrost/requests'][msg.RequestID].json.Cache) {
            // use default duration (set in caching service) or is it passed in?
            const duration = store.getters['bifrost/requests'][msg.RequestID].json.CacheDuration;

            // delete keyvals that we don't need to cache
            const toCache = clone(msg);
            if (Object.prototype.hasOwnProperty.call(toCache, 'RequestID')) delete toCache.RequestID;
            if (Object.prototype.hasOwnProperty.call(toCache, 'Reply')) delete toCache.Reply;

            this.cache.set(key, toCache, duration);
          }

          // done processing the incoming message and its dupes -- exit the loop
          break;
        }
      }

      // remove request from request queue
      console.log(`deleting requestID ${msg.RequestID}`);
      store.dispatch('bifrost/deleteRequest', msg.RequestID);
    } else {
      console.log('%cUnknown reply message', 'background: #222; color: #66FF33', msg);
    }
  }

  send(req, isTest = false) {
    const defer = new Deferred();
    const thisRequest = req;

    if (isTest) {
      console.log('test send', thisRequest);
      return (thisRequest.noReply) ? 0 : defer.promise;
    }

    // if the request doesn't expect a reply back, send RequestID 0 so
    // that the backend can log times for requests that do get a reply
    const requestID = (thisRequest.noReply) ? 0 : store.getters['bifrost/requestID'];

    // md5 the stringified json data so we can cache requests
    // we stringify User object variables that should be unique per user so we can save cached info
    // when the user is logged in and a guest (and also covers cases when they upgrade during the
    // same session)
    const reqStringified = JSON.stringify(thisRequest);
    const userUniquesStringified = JSON.stringify([store.getters['user/UID'], store.getters['user/typeID']]);
    const cacheKey = md5(reqStringified + userUniquesStringified);

    // update request stores
    if (!requestID) {
      // non-reply messages go in their own store since we can't key by request id
      console.log(`adding non-reply request ${cacheKey}`, thisRequest);
      store.dispatch('bifrost/addNonReplyRequest', {
        cacheKey,
        cb: defer,
        request: thisRequest,
      });
    } else {
      // messages that get a reply back
      console.log(`adding requestID ${requestID}`, thisRequest);
      store.dispatch('bifrost/addRequest', {
        requestID,
        cb: defer,
        request: thisRequest,
      });
    }

    // get cache
    let cached = null;
    this.cache.get(cacheKey).then((result) => {
      cached = result;
    });

    if (cached) {
      // we have cached data, so resolve it and remove from pending
      console.log(`%cusing cached ${this.registeredApps[cached.AppID].name} reply`, 'background: #222; color: #66CCFF', requestID, cached);
      store.getters['bifrost/requests'][requestID].promiseResolve(cached.Data);
      store.dispatch('bifrost/deleteRequest', requestID);
    } else if (!thisRequest.noReply && Object.prototype.hasOwnProperty.call(store.getters['bifrost/pendingCacheMap'], cacheKey)) {
      // not cached

      // we have similar request(s) pending -- just add the requestID to the cache key map
      // no call to backend; we will resolve this request when the original request gets a reply
      // from the backend
      store.dispatch('bifrost/addPending', { cacheKey, requestID });
      console.log(`%cqueued ${this.registeredApps[thisRequest.AppID].name} request`, 'background: #222; color: #66CCFF', requestID, store.getters['bifrost/pendingCacheMap'][cacheKey]);
    } else {
      // this is a unique request, so send to backend
      // add some properties to the request
      thisRequest.RequestID = requestID;
      thisRequest.PageID = store.getters['bifrost/pageID'];

      // save md5 hash : ReqID, so we can compare and clear on recv
      if (!thisRequest.noReply) {
        if (!Object.prototype.hasOwnProperty.call(store.getters['bifrost/pendingCacheMap'], cacheKey)) store.dispatch('bifrost/initPending', cacheKey);
        store.dispatch('bifrost/addPending', { cacheKey, requestID });
      }

      // only send to websocket if it's open (otherwise keep it queued up in Requests)
      if (store.getters['vws/wsClientName'] != null && store.getters['vws/wsReadyState'] === 1) {
        console.log(`%csend ${this.registeredApps[thisRequest.AppID].name}`, 'background: #222; color: #ffff00', thisRequest);
        EventBus.$emit('websocket:sendMessage', thisRequest);

        // if this is a non-reply message, remove from pending requests since there's no promise to resolve
        if (thisRequest.noReply) store.dispatch('bifrost/deleteNonReplyRequest', cacheKey);
      }

      // spinner header image if we expect a reply back (spinner will be cleared when promise resolved)
      if (!thisRequest.noReply) store.dispatch('bifrost/incrementWaiting');
    }

    // only return a promise if we are sending something that expects a reply back
    return (thisRequest.noReply) ? 0 : defer.promise;
  }

  httpGet(url) {
    // Bifrost wrapper for Bifrost/API.js -- gets data via http from a single JSON file
    // 2021/04/01: up to now, we've just only needed to do basic HTTP GET requests when fetching
    // data from static JSON files. now with the Venus API, we will need to make requests with
    // various HTTP methods as well as adding headers, which is now handled in the httpRequest()
    // method below. so, instead of having httpGet() and httpRequest() have almost duplicate code,
    // i'm changing httpGet() to be a wrapper function that will call httpRequest(), in order to
    // keep it clean, and minimize breaking things that currently use httpGet(). in addition, we
    // will now be checking status codes returned from venus calls so httpRequest will be sending
    // back the entire response, instead of just the 'data' object. but here in httpGet() we will
    // be resolving `response.data` to mimic the previous behavior, and also to minimize breaking
    // existing flows that use httpGet()
    const defer = new Deferred();
    this.httpRequest(url, 'GET').then(response => defer.resolve(response.data));
    return defer.promise;
  }

  httpRequest(url = '', method = '', headers = {}, data = {}) {
    const defer = new Deferred();
    const options = {
      url,
      method,
      headers,
      data,
    };

    store.dispatch('bifrost/incrementWaiting');

    axios(options)
      .then((response) => {
        store.dispatch('bifrost/decrementWaiting');
        this.waitingCount = store.getters['bifrost/waitingCount'];
        defer.resolve(response);
        console.log('%cfetched URL', 'background: #222; color: #69f', url, response);
      })
      .catch((error) => {
        store.dispatch('bifrost/decrementWaiting');
        this.waitingCount = store.getters['bifrost/waitingCount'];
        if (Object.prototype.hasOwnProperty.call(error, 'response')) {
          defer.resolve(error.response);
          console.log('%cerror fetching URL', 'background: #222; color: #f00', error.response);
        } else {
          // this shouldn't happen but catching in case something is really broken ¯\_(ツ)_/¯
          defer.resolve(error);
          console.log('%cerror fetching URL', 'background: #222; color: #f00', error);
        }
      });

    return defer.promise;
  }

  loadFromBackend(req, isTest) {
    // Bifrost send() wrapper for Bifrost/API.js -- gets data via Websocket send/recv
    return this.send(req, isTest);
  }

  loadFromJsonMulti(url, offset, limit, useCache) {
    // Bifrost wrapper for Bifrost/API.js -- gets data via http get on several JSON files
    const defer = new Deferred();

    // object keyed by index containing each of the json file data
    const dataSplit = {};

    // single array that's built once the promises from each of the json file requests have
    // been fulfilled. afterwards, this is sliced and the promise is resolved
    let dataCombined = [];

    // populated when we get the 1st json file
    let totalRows = 0;

    // sane default; we get the real value from the json file when we get total rows
    let splitSize = 100;

    // workaround for scoping issue in the closure below
    const that = this;

    // When fetching from JSON files, this wraps the data in the same format returned by the backend,
    // so none of the frontend code has to change when switching from backend to JSON sources
    function fmtBackendList(listData) {
      const rows = (listData && listData.length === 0) ? null : listData;

      return {
        NoResult: !(rows),
        TotalRows: totalRows,
        Rows: rows,
      };
    }

    // fetch 1st json to get the total rows, so we don't loop through
    // unnecessary non-existent json files. we always get the 1st json in
    // case the request is for an out of range offset -- we know that the
    // 1st json file is always there (previous commit tried to be smart and
    // start with the proper json file but broke for out of range requests
    this.httpGet(url.replace(/\{SplitSize\}/g, 0), useCache).then((result) => {
      if (result && Object.prototype.hasOwnProperty.call(result, 'TotalRows')) totalRows = result.TotalRows;
      if (result && Object.prototype.hasOwnProperty.call(result, 'SplitSize')) splitSize = result.SplitSize;

      if (offset > totalRows) {
        // the requested offset is larger than the total rows, so
        // we've reached the end. send back a NoResult
        defer.resolve(fmtBackendList([]));
      } else {
        // get the number of the first json file with data that we need
        let splitNumStart = 0;
        while (offset >= splitNumStart + splitSize) {
          splitNumStart += splitSize;
        }

        // looping through promised data is tricky, so we need to get a count of
        // how many json files to fetch so we can do a for loop
        let numFilesToFetch = 0;
        let splitNumEnd = splitNumStart;
        while (splitNumEnd < offset + limit && splitNumEnd < totalRows) {
          splitNumEnd += splitSize;
          numFilesToFetch += 1;
        }

        let fetchCount = 0;

        // capture j in a closure so we can properly loop through promised data
        for (let j = 0; j < numFilesToFetch; j += 1) {
          const splitNumNext = splitNumStart + (splitSize * j);

          ((j) => {
            that.httpGet(url.replace(/\{SplitSize\}/g, splitNumNext), useCache).then((lResult) => {
              fetchCount += 1;
              if (lResult && Object.prototype.hasOwnProperty.call(lResult, 'Rows')) dataSplit[j] = lResult.Rows;

              if (fetchCount === numFilesToFetch) {
                // we have all the json data, so combine them
                for (const item in dataSplit) {
                  if (Object.prototype.hasOwnProperty.call(dataSplit, item)) dataCombined = dataCombined.concat(dataSplit[item]);
                }

                // slice by the limit and resolve the promise
                const dataLimited = dataCombined.slice((offset - splitNumStart), (offset - splitNumStart) + limit);
                defer.resolve(fmtBackendList(dataLimited));
              }
            });
          })(j);
        }
      }
    });

    return defer.promise;
  }

  loadFromJsonByID(request) {
    let requestURL = request.URL;
    if (request.Data.ID) requestURL = request.URL.replace(/\{Id\}/g, request.Data.ID);

    return this.httpGet(requestURL, request.Cache);
  }

  async venusRequest(url, method, doCache = false, data = {}, venusReqID = store.getters['bifrost/venusReqID'], signature, defer = new Deferred()) {
    console.log(`%cvenus request ${(venusReqID !== store.getters['bifrost/venusReqID']) ? 'resumed' : 'started'} (venusReqID ${venusReqID})`, 'background: #222; color: #66FF33', url, method, data, venusReqID);
    store.dispatch('bifrost/incrementWaiting'); // add to loading bar

    // only increment request ID if venusReqID isn't passed in (not a queued request)
    if (venusReqID === store.getters['bifrost/venusReqID']) store.dispatch('bifrost/incrementVenusReqID');

    // add headers that venus looks for
    const headers = {};
    const sessionID = store.getters['user/sessionId'];

    // create unique key for the request for 1) caching and 2) identifying duplicate requests
    const userUniquesStringified = JSON.stringify([store.getters['user/UID'], store.getters['user/typeID']]);
    const venusCacheKey = signature || md5(url + userUniquesStringified);

    if (sessionID) {
      // if session exists in store, send request
      headers['X-Session-ID'] = sessionID;

      const completeRequest = async () => {
        // check if we have similar requests pending

        // if no similar requests, make the call
        const response = await this.httpRequest(url, method, headers, data);
        defer.resolve(response);
        store.dispatch('bifrost/decrementWaiting'); // clear from loading bar

        if (venusReqID !== store.getters['bifrost/venusReqID']) {
          // delete pending request from queue
          store.dispatch('bifrost/deleteVenusRequest', venusReqID);
        }

        if (method === 'GET' && response.status === 200 && doCache) {
          // localforage freaks out if we save `response` so we're only saving what we need (data + status)
          this.cache.set(venusCacheKey, { data: response.data, status: response.status });
        }
        console.log(`%cvenus request resolved (venusReqID ${venusReqID})`, 'background: #222; color: #66FF33', response);
      };

      if (url && method === 'GET' && doCache) {
        // check cache if caching enabled and a GET request
        this.cache.get(venusCacheKey).then(async (cached) => {
          if (cached) {
            // found cached response
            console.log(`%cvenus cache hit (venusReqID ${venusReqID})`, 'background: #222; color: #66FF33', cached);
            defer.resolve(cached);
            store.dispatch('bifrost/decrementWaiting'); // clear from loading bar

            if (venusReqID !== store.getters['bifrost/venusReqID']) {
              // delete pending request from queue
              store.dispatch('bifrost/deleteVenusRequest', venusReqID);
            }
          } else {
            // no cache found make the request
            await completeRequest();
          }
        });
      } else {
        // caching disabled and/or not a GET request
        await completeRequest();
      }
    } else {
      // save cache key : request id, so we can compare and clear after the http request has been made
      if (!Object.prototype.hasOwnProperty.call(store.getters['bifrost/venusPendingCacheMap'], venusCacheKey)) store.dispatch('bifrost/initVenusPending', venusCacheKey);
      store.dispatch('bifrost/addVenusPending', { venusCacheKey, venusReqID });

      // otherwise, add request and promise to queue
      const dataQueue = {
        url,
        method,
        doCache,
        data,
        venusReqID,
        venusCacheKey,
        cb: defer,
      };
      store.dispatch('bifrost/addVenusRequest', dataQueue);
      console.log(`%cvenus request queued (venusReqID ${venusReqID})`, 'background: #222; color: #66FF33', dataQueue);
    }

    return defer.promise;
  }
}
