/* eslint no-param-reassign: 0 */

const mutations = {
  SET_ENABLED(state, bool) {
    state.enabled = bool;
  },
  SET_STARTED(state, bool) {
    state.started = bool;
  },
  SET_INIT(state, bool) {
    state.initialized = bool;
  },
  SET_READY(state, bool) {
    state.isReady = bool;
  },
  SET_LEFT_COUNT(state, val) {
    state.leftCount = val;
  },
  SET_MAX_COUNT(state, val) {
    state.maxCount = val;
  },
  SET_TOKEN(state, val) {
    state.token = val;
  },
  SET_DIRTY_CACHE(state, bool) {
    state.dirtyCache = bool;
  },
};

export default mutations;
