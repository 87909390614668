/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint max-len: 0 */

import axios from 'axios';
import Cache from '@/assets/js/utils/Cache';
import Deferred from '@/assets/js/utils/Deferred';

const queue = [];
let requestCounter = 0;

const getConfig = (location) => {
  const defer = new Deferred();
  const cache = new Cache('siteConfig');

  // add queuing so we don't make multiple network requests when multiple components need the same config. for
  // example, the top page makes 13 requests if there's no queueing in place. caching won't fix this for the first page
  // load since this happens so fast that when the cache is checked at that moment in time, it's not set. so we queue up
  // while the cache hasn't been set yet

  if (requestCounter === 0) {
    requestCounter += 1;
    axios.get('/dyn/dla/json/site_config.json').then(async (response) => {
      if (Object.prototype.hasOwnProperty.call(response, 'data')) {
        Object.keys(response.data).forEach((locationConfig) => {
          cache.set(locationConfig, response.data[locationConfig]);
        });
      }
      defer.resolve((Object.prototype.hasOwnProperty.call(response.data, location)) ? response.data[location] : {});
      // resolve any requests in queue
      if (queue.length > 0) {
        queue.forEach((queuedItem) => {
          queuedItem.defer.resolve((Object.prototype.hasOwnProperty.call(response.data, location)) ? response.data[location] : {});
        });
      }
    });
  } else {
    requestCounter += 1;
    // get from cache
    cache.get(location).then((result) => {
      if (result) {
        defer.resolve(result);
      } else {
        // not in cache yet, add to queue
        const queuedItem = { defer };
        queue.push(queuedItem);
      }
    });
  }

  return defer.promise;
};

export default { getConfig };
