const actions = {
  setS2S(context, val) {
    context.commit('SET_S2S', val);
  },

  setUTMSource(context, val) {
    context.commit('SET_UTM_SOURCE', val);
  },

  setUTMMedium(context, val) {
    context.commit('SET_UTM_MEDIUM', val);
  },

  setAffiPromo(context, val) {
    context.commit('SET_AFFI_PROMO', val);
  },
};

export default actions;
