/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  // websocket URL
  wsUrl: null,

  // websocket client name (ex 'WebSocket', 'SockJS')
  wsClientName: null,

  // websocket readyState
  wsReadyState: null,

  // tracking ID (we use GA's ID)
  gaTrackingId: null,
};

const getters = {
  wsUrl: state => state.wsUrl,
  wsClientName: state => state.wsClientName,
  wsReadyState: state => state.wsReadyState,
  gaTrackingId: state => state.gaTrackingId,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
