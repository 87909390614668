/* eslint max-len: 0 */
/* eslint quote-props: 0 */

module.exports = {
  // resume playback at offset?
  resumePlayback: true,

  // for players that need to reinit when selecting a different quality (HTML5 <video>), set this to true
  initOnQualityChange: true,

  // default video quality to load
  defaultQuality: '720p',

  // bitrate map (quality keys for Merc data)
  resolutionBitrateMap: {
    'HLS': 1,
    '240p': 2,
    '360p': 2,
    '480p': 3,
    '720p': 4,
    '1080p': 4,
  },

  // use nginx video delivery? (uses Mercury movie key instead of DL's)
  useMercKey: false,

  // if 'streamTypeUA' exists, then we are specifying stream types based on the UA (mobile devices)
  streamTypeUA: {
    iOS: 'hls-default',
    Android: 'hls-default',
  },

  // specify stream rules based on user (for the p2p cdn thing)
  streamTypeUsers: {
    // 'derp@d2ptests.d2p': 'hls-p2pcdn'
  },

  // benchmark video load/seek times? (and send to FEL)
  benchmark: true,

  moviePoster: {
    ja: {
      imgUrl: '/assets/sample/{MOVIE_ID}/str.jpg',
      movieIdKey: 'MovieID',
    },
    en: {
      imgUrl: '/assets/sample/{MOVIE_ID}/poster_en.jpg',
      movieIdKey: 'MovieID',
    },
  },

  memberVideo: {
    'hls-default': {
      url: 'https://fms.1pondo.tv/member/{MOVIE_ID}/mb.m3u8',
      deliveryType: 'hls',
      mimeType: 'application/vnd.apple.mpegurl',
      movieKeyParam: 'u',
      movieIdKey: 'MovieID',
      // future use (when reup serves movies)
      // url: 'https://dev.10musume.com/m3u8/member/{MOVIE_ID}.m3u8',
      // movieKeyParam: 'm',
      // movieIdKey: 'MovieSeq'
    },

    'default': {
      url: 'https://fms.1pondo.tv/member/{MOVIE_ID}/mb.m3u8',
      deliveryType: 'hls',
      mimeType: 'application/vnd.apple.mpegurl',
      movieKeyParam: 'u',
      movieIdKey: 'MovieID',
    },
  },

  sampleVideo: {
    'hls-default': {
      url: 'https://fms.1pondo.tv/sample/{MOVIE_ID}/mb.m3u8',
      deliveryType: 'hls',
      mimeType: 'application/vnd.apple.mpegurl',
      movieIdKey: 'MovieID',
    },

    'default': {
      url: 'https://fms.1pondo.tv/sample/{MOVIE_ID}/mb.m3u8',
      deliveryType: 'hls',
      mimeType: 'application/vnd.apple.mpegurl',
      movieIdKey: 'MovieID',
    },
  },

  // send movie playback info to pythia (offset, movie actions, errors, etc)
  pythia: {
    enable: true,
    updateFreq: 30,
  },

  // movies listed here will just appear as sample only for trial users
  MovieBlocks: {
    trial: ['000000-000'],
  },

  // jwplayer config
  jwPlayer: {
    skin: {
      name: 'glow',
      active: '#FF4B87',
      inactive: 'white',
      background: 'black',
    },
    primary: 'html5',

    // enable/disable timeline preview images
    timelinePreview: {
      enable: true,
      vttUrl: '/assets/ts/{MOVIE_ID}/thumbnail.vtt',
      movieIdKey: 'MovieID',
    },
  },

  // videoJS config
  videoJs: {
  },
};
