/* eslint no-console: 0 */

import SockJS from 'sockjs-client';
import EventBus from '../utils/EventBus';
import store from '../../../store';

const VueWebsocket = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue, options) {
    // set websocket URL
    store.dispatch('vws/setWebsocketUrl', options.url);

    // reference to the websocket instance, will be set on connect
    let wsInstance = null;

    // number of reconnect attempts before we stop trying
    const maxReconnects = 20;

    // number of reconnect attempts before we warn the user the connection is "slow"
    const warningReconnects = 2;

    // number of seconds between reconnect attempts
    let autoReconnectInterval = 3 * 1000;

    let reconnectCount = 0;
    let intervalTimer = null;
    let startTimer = null;
    const stopTimer = () => { if (intervalTimer) clearInterval(intervalTimer); };

    function connectWebsocket(url) {
      // ws:// and wss:// will use native websockets; http:// and https:// will use SockJS
      const wsProtocol = () => url.split('//')[0].slice(0, -1);
      store.dispatch('vws/setClientName', (wsProtocol() === 'ws' || wsProtocol() === 'wss') ? 'WebSocket' : 'SockJS');

      // create websocket connection instance
      if (!reconnectCount) console.log(`%cattempting to create websocket (${store.getters['vws/wsClientName']} lib)`, 'background:#C41E3A; color:#fff', store.getters['vws/wsUrl']);
      wsInstance = (store.getters['vws/wsClientName'] === 'WebSocket') ? new WebSocket(store.getters['vws/wsUrl']) : new SockJS(store.getters['vws/wsUrl']);

      // reconnect. gets called on the websocket onclose event
      function reconnectWebsocket(event) {
        if (typeof (wsInstance.removeAllListeners) === 'function') wsInstance.removeAllListeners();

        if (reconnectCount < maxReconnects) {
          // reconnect limit not reached, attempt to reconnect
          reconnectCount += 1;
          startTimer = () => {
            if (intervalTimer) return;
            intervalTimer = setInterval(() => {
              console.log(`%cwebsocket reconnecting. attempt #${reconnectCount}`, 'background:#C41E3A; color:#fff', event);
              if (reconnectCount >= warningReconnects) EventBus.$emit('websocket:reconnecting', reconnectCount);
              connectWebsocket(store.getters['vws/wsUrl']);
            }, autoReconnectInterval += 3000);
          }; startTimer();
        } else {
          // reconnect limit reached. stop.
          console.log(`%cwebsocket reconnect (${store.getters['vws/wsClientName']} lib) giving up :(`, 'background:#C41E3A; color:#fff');
          stopTimer();
          EventBus.$emit('websocket:connectionTimeout');
        }
      }

      // websocket event handlers
      wsInstance.onopen = () => {
        console.log(`%cwebsocket opened (${store.getters['vws/wsClientName']} lib)`, 'background:#C41E3A; color:#fff', store.getters['vws/wsUrl']);

        store.dispatch('vws/setReadyState', wsInstance.readyState);

        if (store.getters['vws/wsReadyState'] === 1) {
          EventBus.$emit('websocket:opened');

          // reset reconnect count
          reconnectCount = 0;

          // reset connection retry timer
          stopTimer();
        }
      };

      wsInstance.onclose = (event) => {
        switch (event) {
          case 1000: // CLOSE_NORMAL
            console.log('WebSocket: closed');
            break;
          default: // Abnormal closure
            reconnectWebsocket(event);
            break;
        }
        store.dispatch('vws/setReadyState', wsInstance.readyState);

        EventBus.$emit('websocket:closed', event.reason);
      };

      wsInstance.onerror = (event) => {
        store.dispatch('vws/setReadyState', wsInstance.readyState);
        EventBus.$emit('websocket:error', event);
      };

      wsInstance.onmessage = (event) => {
        // format message depending on the websocket library being used
        const msg = (store.getters['vws/wsClientName'] === 'WebSocket') ? JSON.parse(event.data) : event.data;
        EventBus.$emit('websocket:onMessage', msg);
      };
    }

    // start websocket connection
    connectWebsocket(store.getters['vws/wsUrl']);

    // listener for messages from Bifrost
    EventBus.$on('websocket:sendMessage', (msg) => {
      if (store.getters['vws/wsClientName'] != null && store.getters['vws/wsReadyState'] === 1) wsInstance.send(JSON.stringify(msg));
    });

    // We call Vue.mixin() here to inject functionality into all components.
    Vue.mixin({
      // Anything added to a mixin will be injected into all components.
      // In this case, the mounted() method runs when the component is added to the DOM.
      mounted() {
        // console.log('ReupWebsocket Mounted!');
      },
    });
  },
};

export default VueWebsocket;
