const actions = {
  setInit(context) {
    context.commit('SET_INIT');
  },

  incrementPageID(context) {
    context.commit('INCREMENT_PAGE_ID');
  },

  incrementVenusReqID(context) {
    context.commit('INCREMENT_VENUS_REQ_ID');
  },

  registerApp(context, payload) {
    context.commit('REGISTER_APP', payload);
  },

  addRequest(context, payload) {
    context.commit('ADD_REQUEST', payload);
  },

  deleteRequest(context, requestID) {
    context.commit('DELETE_REQUEST', requestID);
  },

  initPending(context, cacheKey) {
    context.commit('INIT_PENDING', cacheKey);
  },

  addPending(context, payload) {
    context.commit('ADD_PENDING', payload);
  },

  deletePending(context, cacheKey) {
    context.commit('DELETE_PENDING', cacheKey);
  },

  addNonReplyRequest(context, payload) {
    context.commit('ADD_NON_REPLY_REQUEST', payload);
  },

  deleteNonReplyRequest(context, cacheKey) {
    context.commit('DELETE_NON_REPLY_REQUEST', cacheKey);
  },

  addVenusRequest(context, payload) {
    context.commit('ADD_VENUS_REQUEST', payload);
  },

  deleteVenusRequest(context, venusReqID) {
    context.commit('DELETE_VENUS_REQUEST', venusReqID);
  },

  initVenusPending(context, venusCacheKey) {
    context.commit('INIT_VENUS_PENDING', venusCacheKey);
  },

  addVenusPending(context, payload) {
    context.commit('ADD_VENUS_PENDING', payload);
  },

  deleteVenusPending(context, venusCacheKey) {
    context.commit('DELETE_VENUS_PENDING', venusCacheKey);
  },

  setRandomSeed(context, seed) {
    context.commit('SET_RANDOM_SEED', seed);
  },

  incrementWaiting(context) {
    context.commit('INCREMENT_WAITING');
  },

  decrementWaiting(context) {
    context.commit('DECREMENT_WAITING');
  },
};

export default actions;
