<i18n>
{
  "en": {
    "new_movies": "New Movies",
    "more_movies": "View More"
  },
  "ja": {
    "new_movies": "新着動画",
    "more_movies": "もっと見る"
  }
}
</i18n>

<template>
<div>
  <div class="heading">
    <h2>{{ $t('new_movies') }}</h2>
  </div>

  <div class="list list--default top-list">
    <div class="flex-grid">
      <!-- movie item -->
      <movie-thumbnail
        v-for="movie in movieList"
        :key="movie.MovieID"
        :movie="movie"
        theme="home-newest" />
      <!-- /movie item -->
    </div>
  </div>

  <div class="button-box button-box--center">
    <router-link to="/list/?o=newest"><button class="button-ghost button-default button-large button-icon--right">{{ $t('more_movies') }}<svg class="tm-icon"><use xlink:href="#tm-icon-arrow-right"/></svg></button></router-link>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import { prefetch } from 'quicklink';
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import movieThumbnail from '@/components/_common/movieThumbnail.vue';

export default {
  props: {
    numMovies: Number,
  },
  components: {
    'movie-thumbnail': movieThumbnail,
  },
  data() {
    return {
      movieList: [],
    };
  },
  created() {
    this.getMovies(this.userTypeID);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    async getMovies(userTypeID) {
      // fetch newest movies
      const bfAPI = new BifrostAPI();
      const result = await bfAPI.getMovieListNewest(0, parseInt(this.numMovies, 10));
      const movieList = MovieHelper.expandDetailsList(result.Rows, this.locale, userTypeID);
      this.movieList = MovieHelper.filterExcludedMovieList(movieList, userTypeID);

      // prefetch movie detail jsons and posters
      this.prefetchMovies();
    },
    prefetchMovies() {
      // using vue-mobile-detection lib, this.$isMobile() returns a Boolean value whether or not the user is using a mobile
      const prefetchedMovieList = (this.$isMobile()) ? this.movieList.slice(0, 1) : this.movieList.slice(0, 3);
      for (let i = 0; i < prefetchedMovieList.length; i += 1) {
        // movie detail jsons
        prefetch(`/dyn/phpauto/movie_details/movie_id/${prefetchedMovieList[i].MovieID}.json`);
        // movie posters
        prefetch(`/assets/sample/${prefetchedMovieList[i].MovieID}/str.jpg`);
      }
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovies(newVal);
    },
    numMovies(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovies(this.userTypeID);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
