/* eslint no-param-reassign: 0 */

const mutations = {
  SET_EVENTS_INIT(state, bool) {
    state.eventsInitialized = bool;
  },

  SET_VOLUME(state, volume) {
    state.volume = volume;
  },
};

export default mutations;
