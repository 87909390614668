/* eslint no-param-reassign: 0 */

import axios from 'axios';
import Limiter from '../../assets/js/utils/limiters';

// https://dml.muramura.tv/json/simple

const mutations = {
  SET_INIT(state) {
    state.initialized = true;

    state.canDownload = true;
    state.slotsUsed = 0;
    state.slotsFree = 3;

    state.failsafe = false;
  },

  REFRESH_STATUS(state, movieID) {
    // call DML API and update state values
    Limiter.throttle(axios.get(`${process.env.VUE_APP_DML_API}/json/detail`, { withCredentials: true }).then((response) => {
      if (response.status === 200 && response.data === '') {
        // 2020/02: observed this case can happen if DML is misconfigured or buggy and responds
        // successfully (200) but with no content. so adding a new "failsafe" mode so that the
        // download buttons are enabled on the frontend, and leaving it up to DML on the fileservers
        // to either allow the download, or redirect to an error page. without the failsafe mode,
        // the frontend treated it as if the user reached their download limit and disabled them
        state.failsafe = true;
      } else if (response.status === 200 && response.data !== null) {
        state.slotsUsed = response.data.Used;
        state.slotsFree = response.data.Free;

        // 2018-11-16 1: r1872: CanDownload value from DML not reliable. Users reporting not being
        // able to download due to DML saying CanDownload = false. Heyzo was the only site that was
        // not affected by this issue and it turns out they are not using CanDownload, but instead
        // checking download history + slots Free instead so we're gonna use the same logic here
        // 2018-11-16 2: seems this can be reproduced by using Firefox in Private mode
        if (movieID) {
          let isDownloaded = false;
          if (Object.prototype.hasOwnProperty.call(response.data, 'Downloaded')) {
            // loop thru download history and check if it's been downloaded
            for (let i = 0; response.data.Downloaded.length > i; i += 1) {
              if (response.data.Downloaded[i].MovieID === movieID) {
                isDownloaded = true;
                break;
              }
            }
          }

          // movie is downloadable if they have free slots or the movie has been previously
          // downloaded. we use this logic to override the CanDownload value returned from DML
          state.canDownload = (response.data.Free > 0 || isDownloaded);
        }
      }
    }).catch((error) => {
      console.log('%cDML API error', 'background: #222; color: #f00', error);
      state.failsafe = true;
    }), 5000);
  },
};

export default mutations;
