const actions = {
  setSfwMode(context, paranoiaLevel) {
    context.commit('SET_SFW_MODE', Number(paranoiaLevel));
  },

  setMovieListFilterVisibility(context, bool) {
    context.commit('SET_MOVIE_LIST_FILTER_VISIBILITY', bool);
  },

  setMenuAlwaysVisible(context, bool) {
    context.commit('SET_MENU_ALWAYS_VISIBLE', bool);
  },

  setPlayedSamples(context, payload) {
    context.commit('SET_PLAYED_SAMPLES', payload);
  },

  resetPlayedSamples(context) {
    context.commit('RESET_PLAYED_SAMPLES');
  },
};

export default actions;
