/* eslint no-shadow: 0 */

import actions from './actions';
import mutations from './mutations';

const state = {
  enabled: false,
  started: false,
  initialized: false,
  isReady: false,
  leftCount: null,
  maxCount: null,
  token: null,
  dirtyCache: true,
};

const getters = {
  enabled: state => state.enabled,
  started: state => state.started,
  initialized: state => state.initialized,
  isReady: state => state.isReady,
  leftCount: state => state.leftCount,
  maxCount: state => state.maxCount,
  token: state => state.token,
  dirtyCache: state => state.dirtyCache,
};

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;
